import { all, takeLatest } from "redux-saga/effects";
import { GET_ACCESS_TOKEN } from "../modules/auth";
import {
  GET_UNITS_DATA,
  GET_FILTER_OPTIONS,
  GET_CIC_AND_UPC_DETAILS,
} from "../modules/dashboard";
import {
  GET_DC_STORE_DATA,
  GET_STORE_ORDER_DATA,
  GET_ORDER_HISTORY_TOTAL_QUANTITY,
  GET_BUYER_COMMENTS_DATA,
} from "../modules/orderHistory";
import {
  GET_ORDER_HISTORY_DSD_DATA,
  GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY,
} from "../modules/dsdOrderHistory";
import { GET_DC_RESEARCH_DATA } from "../modules/dcResearch";
import { GET_INVOICE_RECEIPTS_RESEARCH_DATA } from "../modules/invoiceReceipts";
import {
  GET_MONTHLY_USAGE_COUNT,
  GET_UNIQUE_SCAN_USER_COUNT,
  GET_UNIQUE_USER_COUNT,
  GET_USAGE_DETAILS,
  MONITOR_BARCODE_USAGE,
  MONITOR_VIEW_REPORT,
} from "../modules/monitor";
import { getAccessTokenSaga } from "./auth";
import {
  getWarehouseKPIDataSaga,
  getStoreKPIDataSaga,
  getFilterOptionsSaga,
  getCICAndUPCDetailsSaga,
} from "./dashboard";
import {
  getDcStoreDataSaga,
  getStoreOrderDataSaga,
  getOrderHistoryTotalQuantitySaga,
  getBuyerCommentsDataSaga,
} from "./orderHistory";
import {
  getOrderHistoryDsdDataSaga,
  getOrderHistoryDsdTotalQuantitySaga,
} from "./dsdOrderHistory";
import {
  getDcResearchData1Saga,
  getDcResearchData2Saga,
  getDcResearchData3Saga,
  getDcResearchData4Saga,
} from "./dcResearch";
import { getInvoiceReceiptsResearchDataSaga } from "./invoiceReceipts";
import {
  getMonthlyUsageCountSaga,
  getUniqueUserCountSaga,
  getUsageDetailsSaga,
  monitorViewReportSaga,
  monitorBarcodeUsageSaga,
  getUniqueScanUserCountSaga,
} from "./monitor";

export function* rootSaga() {
  yield all([takeLatest(GET_ACCESS_TOKEN, getAccessTokenSaga)]);
  yield all([takeLatest(GET_FILTER_OPTIONS, getFilterOptionsSaga)]);
  yield all([takeLatest(GET_CIC_AND_UPC_DETAILS, getCICAndUPCDetailsSaga)]);
  yield all([takeLatest(GET_UNITS_DATA, getWarehouseKPIDataSaga)]);
  yield all([takeLatest(GET_UNITS_DATA, getStoreKPIDataSaga)]);
  yield all([takeLatest(GET_DC_STORE_DATA, getDcStoreDataSaga)]);
  yield all([takeLatest(GET_STORE_ORDER_DATA, getStoreOrderDataSaga)]);
  yield all([
    takeLatest(GET_ORDER_HISTORY_DSD_DATA, getOrderHistoryDsdDataSaga),
  ]);
  yield all([
    takeLatest(
      GET_ORDER_HISTORY_TOTAL_QUANTITY,
      getOrderHistoryTotalQuantitySaga
    ),
  ]);
  yield all([
    takeLatest(
      GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY,
      getOrderHistoryDsdTotalQuantitySaga
    ),
  ]);
  yield all([takeLatest(GET_BUYER_COMMENTS_DATA, getBuyerCommentsDataSaga)]);
  yield all([takeLatest(GET_DC_RESEARCH_DATA, getDcResearchData1Saga)]);
  yield all([takeLatest(GET_DC_RESEARCH_DATA, getDcResearchData2Saga)]);
  yield all([takeLatest(GET_DC_RESEARCH_DATA, getDcResearchData3Saga)]);
  yield all([takeLatest(GET_DC_RESEARCH_DATA, getDcResearchData4Saga)]);
  yield all([
    takeLatest(
      GET_INVOICE_RECEIPTS_RESEARCH_DATA,
      getInvoiceReceiptsResearchDataSaga
    ),
  ]);
  yield all([takeLatest(GET_USAGE_DETAILS, getUsageDetailsSaga)]);
  yield all([takeLatest(GET_MONTHLY_USAGE_COUNT, getMonthlyUsageCountSaga)]);
  yield all([takeLatest(GET_UNIQUE_USER_COUNT, getUniqueUserCountSaga)]);
  yield all([
    takeLatest(GET_UNIQUE_SCAN_USER_COUNT, getUniqueScanUserCountSaga),
  ]);
  yield all([takeLatest(MONITOR_VIEW_REPORT, monitorViewReportSaga)]);
  yield all([takeLatest(MONITOR_BARCODE_USAGE, monitorBarcodeUsageSaga)]);
}

import axios from "axios";

const headers = {
  tenant_id: process.env.REACT_APP_TENANT_ID,
  client_id: process.env.REACT_APP_API_CLIENT_ID,
  client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
};

const axiosInstance = axios.create({
  baseURL: "/",
  timeout: 90000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return axiosInstance.get("/api/login", { headers }).then((response) => {
        const { access_token } = response.data;
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        error.config.headers.Authorization = `Bearer ${access_token}`;
        sessionStorage.setItem("access_token", access_token);
        return axios
          .request(error.config)
          .then((data) => Promise.resolve(data));
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

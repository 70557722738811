// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-dropdown div[data-testid="dropdownItem-wrapper"] {
    padding: 0;
}

.nav-dropdown div[data-headlessui-state] {
    padding: 8px;
}

.nav-dropdown,
.nav-dropdown div[data-headlessui-state] {
    background-color: #004b8b;
}

.nav-dropdown div[data-headlessui-state="active"] {
    background-color: #013c77;
    cursor: pointer;
}

.popper-box {
    left: 23px !important;
    top: -35px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Report/DesktopToolbar/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,qBAAqB;AACzB","sourcesContent":[".nav-dropdown div[data-testid=\"dropdownItem-wrapper\"] {\n    padding: 0;\n}\n\n.nav-dropdown div[data-headlessui-state] {\n    padding: 8px;\n}\n\n.nav-dropdown,\n.nav-dropdown div[data-headlessui-state] {\n    background-color: #004b8b;\n}\n\n.nav-dropdown div[data-headlessui-state=\"active\"] {\n    background-color: #013c77;\n    cursor: pointer;\n}\n\n.popper-box {\n    left: 23px !important;\n    top: -35px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

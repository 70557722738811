import { FC, useEffect, useRef, useState } from "react";
import * as SDCCore from "scandit-web-datacapture-core";
import * as SDCBarcode from "scandit-web-datacapture-barcode";
import clsx from "clsx";
import Modal from "@albertsons/uds/molecule/Modal";
import Button from "@albertsons/uds/molecule/Button";
import { CameraPermissionDenied } from "./components";
import { X as CloseIcon } from "lucide-react";
import { checkIfHasCameraPermission } from "../../../utils/permissions";
import { PermissionState } from "../../../types/permission.types";
import { BarcodeValue } from "../../../types/barcode.types";

const licenseKey = process.env.REACT_APP_SCANDIT_LICENSE_KEY || "";

interface BarcodeScannerProps {
  isOn: boolean;
  updateBarcodeValue: (barcode: BarcodeValue) => void;
  closeBarcodeModal: () => void;
}

const BarcodeScanner: FC<BarcodeScannerProps> = (props) => {
  const { isOn, updateBarcodeValue, closeBarcodeModal } = props;

  const [readyState, setReadyState] = useState(false);
  const [isCameraReady, setIsCameraReady] = useState(true);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [cameraPermissionState, setCameraPermissionState] =
    useState<PermissionState>("granted");

  const isCameraInitialized = useRef(false);
  const cameraRef = useRef<SDCCore.Camera | null>(null);

  const onBarcodeScanerCloseHandler = () => {
    setShowConfirmationPopup(true);
  };

  const closeConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  const watchCameraPermission = async () => {
    if (cameraPermissionState === "denied") {
      setCameraPermissionState("granted");
    }
    const permissionState = await checkIfHasCameraPermission();
    setCameraPermissionState(permissionState);
    if (permissionState === "prompt") {
      setTimeout(() => {
        watchCameraPermission();
      }, 1000);
    }
  };

  /* istanbul ignore next */
  const initializeCamera = async () => {
    if (!isCameraInitialized.current) {
      isCameraInitialized.current = true;
      await SDCCore.configure({
        licenseKey,
        libraryLocation: new URL(
          "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/",
          document.baseURI
        ).toString(),
        moduleLoaders: [
          SDCBarcode.barcodeCaptureLoader({ highEndBlurryRecognition: true }),
        ],
      });
    }
  };

  /* istanbul ignore next */
  const turnOnCamera = async () => {
    cameraRef.current = null;
    try {
      const context = await SDCCore.DataCaptureContext.create();

      const camera = SDCCore.Camera.default;

      const cameraSettings =
        SDCBarcode.BarcodeCapture.recommendedCameraSettings;
      cameraSettings.preferredResolution = SDCCore.VideoResolution.FullHD;
      camera.applySettings(cameraSettings);
      await context.setFrameSource(camera);

      const settings = new SDCBarcode.BarcodeCaptureSettings();
      settings.enableSymbologies([
        SDCBarcode.Symbology.Code39,
        SDCBarcode.Symbology.EAN13UPCA,
      ]);
      settings.codeDuplicateFilter = 1000;

      const symbologySetting = settings.settingsForSymbology(
        SDCBarcode.Symbology.Code39
      );
      symbologySetting.activeSymbolCounts = [
        7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];

      const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
        context,
        settings
      );

      barcodeCapture.addListener({
        didScan: async (capture, session) => {
          const barcode = session.newlyRecognizedBarcodes[0];
          if (barcode.data) {
            capture.setEnabled(false);
            if (barcode.symbology === SDCBarcode.Symbology.EAN13UPCA) {
              updateBarcodeValue({
                data: barcode.data.endsWith("4")
                  ? `0${barcode.data.slice(0, -1)}`
                  : barcode.data,
                type: "upc",
              });
            } else {
              updateBarcodeValue({
                data: barcode.data,
                type: "cic",
              });
            }
          }
        },
      });

      const view = await SDCCore.DataCaptureView.forContext(context);
      view.connectToElement(document.getElementById("barcode-scanner")!);
      view.addControl(new SDCCore.CameraSwitchControl());
      view.addControl(new SDCCore.TorchSwitchControl());
      const focus = new SDCCore.TapToFocus();
      const zoom = new SDCCore.SwipeToZoom();
      view.focusGesture = focus;
      view.zoomGesture = zoom;

      const barcodeCaptureOverlay =
        await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
          barcodeCapture,
          view,
          SDCBarcode.BarcodeCaptureOverlayStyle.Frame
        );

      const viewfinder = new SDCCore.RectangularViewfinder(
        SDCCore.RectangularViewfinderStyle.Square,
        SDCCore.RectangularViewfinderLineStyle.Light
      );

      await barcodeCaptureOverlay.setViewfinder(viewfinder);

      await camera.switchToDesiredState(SDCCore.FrameSourceState.On);

      await barcodeCapture.setEnabled(true);

      cameraRef.current = camera;

      setReadyState(true);
    } catch (error) {
      console.log("error", error);
      cameraRef.current = null;
    }
  };

  useEffect(() => {
    if (isOn && isCameraReady) {
      watchCameraPermission();
      turnOnCamera();
    }
    if (!isOn && isCameraInitialized.current) {
      setReadyState(false);
      if (cameraRef.current) {
        setIsCameraReady(false);
        cameraRef.current
          .switchToDesiredState(SDCCore.FrameSourceState.Off)
          .finally(() => {
            setTimeout(() => {
              setIsCameraReady(true);
            }, 500);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOn, isCameraReady]);

  useEffect(() => {
    initializeCamera();
  }, []);

  return (
    <>
      <Modal
        isOpen={isOn}
        onClose={onBarcodeScanerCloseHandler}
        minHeight={20}
        minWidth={20}
      >
        {() => (
          <div className="flex flex-col justify-between bg-white rounded-lg border border-gray-204 p-6">
            <span className="flex justify-center relative">
              <div
                id="barcode-scanner"
                className={clsx(
                  "h-[58vh] flex align-center",
                  !readyState && "hidden"
                )}
                style={{ width: "calc(100vw - 90px)" }}
              />
              <div
                className={clsx(
                  "absolute right-0 top-0.5 cursor-pointer",
                  cameraPermissionState !== "denied" && !readyState && "hidden"
                )}
                onClick={onBarcodeScanerCloseHandler}
                data-testid="close-button"
              >
                <CloseIcon size={24} />
              </div>
              {!readyState && (
                <div>
                  {cameraPermissionState === "granted" && (
                    <span>Please Wait...</span>
                  )}
                  {cameraPermissionState === "prompt" && (
                    <span>Please Allow Camera Permission</span>
                  )}
                  {cameraPermissionState === "denied" && (
                    <CameraPermissionDenied
                      onRetry={() => {
                        watchCameraPermission();
                        turnOnCamera();
                      }}
                    />
                  )}
                </div>
              )}
            </span>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={showConfirmationPopup}
        onClose={closeConfirmationPopup}
        minHeight={20}
        minWidth={20}
      >
        {({ close }) => (
          <div className="flex flex-col justify-between bg-white rounded-lg border border-gray-204 p-5">
            <span>
              <p className="font-semibold text-base mb-1">
                Are you sure you want to close?
              </p>
            </span>
            <span className="flex justify-end pt-2.5">
              <span>
                <Button
                  fixed
                  variant="tertiary"
                  onClick={close}
                  className="!w-20"
                  data-testid="cancelButton"
                >
                  CANCEL
                </Button>
              </span>
              <span>
                <Button
                  fixed
                  variant="tertiary"
                  className="!w-20"
                  onClick={() => {
                    close();
                    setTimeout(() => closeBarcodeModal(), 0);
                  }}
                  data-testid="warn-popup-close-confirm-button"
                >
                  LEAVE
                </Button>
              </span>
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};

export default BarcodeScanner;

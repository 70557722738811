import { FC, ReactNode, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import {
  Scan,
  RotateCcw,
  Pencil,
  Layers,
  ChevronUp,
  Bookmark,
  ChevronDown,
  Check,
  ListFilter,
} from "lucide-react";
import { AppDispatch, RootState } from "../../../store";
import {
  floatingResetAllAction,
  addBookmark,
  onSelectBookmark,
  filterAccordianOpenAction,
} from "../../../store/modules/dashboard";
import {
  resetExpandModeDCStoreFilter,
  resetExpandModeStoreOrderFilter,
} from "../../../store/modules/orderHistory";
import { Backdrop } from "../../../components";
import ResetModal from "../ResetModal";
import { isMobile } from "../../../utils/isMobile";
import {
  blockBodyScroll,
  releaseBodyScroll,
} from "../../../utils/blockScrolling";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { IFilterOptions } from "../../../types/filters.types";
import { IBookmark } from "../../../types/bookmark.types";
import "./styles.css";

interface IMenuItemProps {
  icon: ReactNode;
  label: string;
  dataTestId?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const MenuItem: FC<IMenuItemProps> = ({
  icon,
  label,
  dataTestId,
  disabled = false,
  className = "",
  onClick,
}) => {
  const { screen } = useWindowBreakpoints();

  return (
    <div
      className={clsx(
        "h-12 flex flex-1 items-center justify-center",
        screen.isMobile ? "flex-col" : "gap-1",
        disabled ? "opacity-40" : "cursor-pointer"
      )}
      onClick={disabled ? undefined : onClick}
      data-testid={dataTestId}
    >
      <div className={className}>{icon}</div>
      <div
        className={clsx(
          "text-center",
          screen.extraSmall ? "text-xs" : "text-sm"
        )}
      >
        {label}
      </div>
    </div>
  );
};

interface IFloatingMenuProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  canScanBarcode?: boolean;
  isAnnotating?: boolean;
  openAnnotateModal: () => void;
  openBarcodeModal: () => void;
  floatingResetAll: () => void;
  filters: IFilterOptions;
  bookmarks: IBookmark[];
  addBookmark: () => void;
  onSelectBookmark: (index: number) => void;
  selectedBookmarkIdx: number;
  isBookmarkFilled: boolean;
  floatingMenuChange: boolean;
  filterAccordianOpen: boolean;
  filterAccordianOpenAction: (value: boolean) => void;
  resetExpandModeDCStoreFilters: () => void;
  resetExpandModeStoreOrderFilters: () => void;
}

const FloatingMenu: FC<IFloatingMenuProps> = (props) => {
  const {
    forceScreen,
    canScanBarcode = true,
    isAnnotating = false,
    openAnnotateModal,
    openBarcodeModal,
    floatingResetAll,
    filters,
    bookmarks,
    addBookmark,
    onSelectBookmark,
    selectedBookmarkIdx,
    isBookmarkFilled,
    floatingMenuChange,
    filterAccordianOpen,
    filterAccordianOpenAction,
    resetExpandModeDCStoreFilters,
    resetExpandModeStoreOrderFilters,
  } = props;

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [showBookmarksExpanded, setShowBookmarksExpanded] = useState(false);

  const isFiltersApplied =
    Object.values(filters).filter((v) => v.length > 0).length > 0;

  const isActualMobile = forceScreen === "mobile" || isMobile();

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const openResetModal = () => {
    setShowResetModal(true);
  };

  const closeResetModal = () => {
    setShowResetModal(false);
  };

  const onReset = () => {
    floatingResetAll();
    closeResetModal();
    resetExpandModeDCStoreFilters();
    resetExpandModeStoreOrderFilters();
  };

  const openExpandedMenu = () => {
    setExpandedMenu(true);
    blockBodyScroll();
  };

  const closeExpandedMenu = () => {
    setExpandedMenu(false);
    releaseBodyScroll();
  };

  const onAnnotateAction = () => {
    closeExpandedMenu();
    openAnnotateModal();
  };

  const onBookmarkAction = () => {
    setShowBookmarks(true);
  };

  const closeBookmarkAction = () => {
    setShowBookmarks(false);
    setShowBookmarksExpanded(false);
  };

  const onAddBookmark = () => {
    addBookmark();
    closeBookmarkAction();
  };

  const onToggleBookmark = (index: number) => {
    onSelectBookmark(index);
    closeBookmarkAction();
  };

  const filterAccordian = () => {
    filterAccordianOpenAction(true);
  };

  if (isAnnotating || filterAccordianOpen) {
    return null;
  }

  return (
    <>
      <Backdrop enabled isOpen={showBookmarks} onClose={closeBookmarkAction}>
        <div
          className={clsx(
            "fixed bottom-0 left-0 right-0 bg-zinc-50 rounded-lg shadow-2xl mt-2 mx-2 mb-4 floating-menu-wrapper z-10",
            isActualMobile && "mb-[72px]"
          )}
        >
          <div className="m-2 px-2">
            <div
              className="flex items-center text-base font-medium py-4"
              onClick={closeBookmarkAction}
            >
              <div className="pr-3">
                <ChevronDown />
              </div>
              <span>Bookmarks</span>
            </div>
            {/* Added "Add Bookmark button temporarily" */}
            {isFiltersApplied && !isBookmarkFilled && (
              <div
                className="flex items-center text-base py-4"
                onClick={onAddBookmark}
                data-testid="addBookmark"
              >
                <div className="pr-2.5">
                  <Bookmark />
                </div>
                Add Bookmark
              </div>
            )}
            <div
              className="flex justify-between items-center text-sm py-4"
              onClick={() => setShowBookmarksExpanded((prev) => !prev)}
              data-testid="view-bookmarks"
            >
              <span className="font-bold">Report Bookmarks</span>
              {showBookmarksExpanded ? <ChevronUp /> : <ChevronDown />}
            </div>
            {showBookmarksExpanded &&
              bookmarks.map((bookmark, index) => (
                <div
                  key={bookmark.id}
                  className="flex justify-between items-center text-sm py-2 pl-4"
                  onClick={() => onToggleBookmark(index)}
                  data-testid={`toogleBookmark${index}`}
                >
                  <div className="flex items-center">
                    <div
                      className={clsx(
                        "pr-2",
                        selectedBookmarkIdx === index && "fill-bookmark"
                      )}
                    >
                      <Bookmark className="pr-1" />
                    </div>
                    {bookmark.name}
                  </div>
                  {selectedBookmarkIdx === index && <Check className="pl-1" />}
                </div>
              ))}
          </div>
        </div>
      </Backdrop>
      {!showBookmarks && (
        <Backdrop
          enabled={expandedMenu}
          isOpen={expandedMenu}
          onClose={closeExpandedMenu}
        >
          <div
            className={clsx(
              "fixed bottom-0 left-0 right-0 bg-zinc-50 rounded-lg shadow-2xl mt-2 mx-2 mb-4 floating-menu-wrapper z-10",
              isActualMobile && expandedMenu && "mb-[72px]"
            )}
          >
            {!floatingMenuChange ? (
              <div className="flex justify-between items-center m-2">
                <div className="w-full max-w-screen-lg">
                  <div
                    className={clsx(
                      "h-12 flex flex-1 items-center justify-center",
                      screen.isMobile && "mt-2"
                    )}
                  >
                    <MenuItem
                      disabled={!canScanBarcode}
                      icon={<Scan size={screen.extraSmall ? 18 : 24} />}
                      label="Barcode"
                      dataTestId="scan-button"
                      onClick={openBarcodeModal}
                    />
                    <MenuItem
                      icon={<RotateCcw size={screen.extraSmall ? 18 : 24} />}
                      label="Reset"
                      dataTestId="resetIcon"
                      onClick={openResetModal}
                    />
                    <MenuItem
                      icon={<Pencil size={screen.extraSmall ? 18 : 24} />}
                      label="Annotate"
                      dataTestId="annotate-button"
                      onClick={onAnnotateAction}
                    />
                    <MenuItem
                      disabled
                      icon={<Layers size={screen.extraSmall ? 18 : 24} />}
                      label="Pages"
                    />
                    {!expandedMenu && (
                      <MenuItem
                        icon={<ChevronUp size={screen.extraSmall ? 18 : 24} />}
                        label="More"
                        dataTestId="more-button"
                        onClick={openExpandedMenu}
                      />
                    )}
                  </div>
                  {expandedMenu && (
                    <div className="w-full max-w-screen-lg">
                      <div className="flex-1 h-12 flex items-center justify-center mt-6">
                        <MenuItem
                          icon={<Bookmark size={screen.extraSmall ? 18 : 24} />}
                          label="Bookmarks"
                          dataTestId="bookmark"
                          className={isBookmarkFilled ? "fill-bookmark" : ""}
                          onClick={onBookmarkAction}
                        />
                        <MenuItem icon="" label="" />
                        <MenuItem icon="" label="" />
                        <MenuItem icon="" label="" />
                      </div>
                      <div className="flex items-center justify-center cursor-pointer ">
                        <div onClick={closeExpandedMenu}>
                          <MenuItem
                            icon={<ChevronDown />}
                            label=""
                            dataTestId="close-expand"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex justify-between items-center m-2">
                <MenuItem
                  icon={<Pencil size={screen.extraSmall ? 18 : 24} />}
                  label="Annotate"
                  dataTestId="annotate-button"
                  onClick={onAnnotateAction}
                />
                <MenuItem icon="" label="" />
                <MenuItem icon="" label="" />
                <MenuItem
                  icon={<RotateCcw size={screen.extraSmall ? 18 : 24} />}
                  label="Reset"
                  onClick={openResetModal}
                />
                <MenuItem
                  icon={<ListFilter size={screen.extraSmall ? 18 : 24} />}
                  label="Filters"
                  onClick={filterAccordian}
                  dataTestId="filter-accordian"
                />
              </div>
            )}
          </div>
        </Backdrop>
      )}
      <ResetModal
        showResetModal={showResetModal}
        closeResetModal={closeResetModal}
        onReset={onReset}
        content="Filters, slicers, and other data view changes you've made will be cleared. Are you sure you want to continue?"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  floatingResetAll: () => dispatch(floatingResetAllAction()),
  addBookmark: () => dispatch(addBookmark()),
  onSelectBookmark: (index: number) => dispatch(onSelectBookmark(index)),
  filterAccordianOpenAction: (value: boolean) =>
    dispatch(filterAccordianOpenAction(value)),
  resetExpandModeDCStoreFilters: () => dispatch(resetExpandModeDCStoreFilter()),
  resetExpandModeStoreOrderFilters: () =>
    dispatch(resetExpandModeStoreOrderFilter()),
});

const mapStateToProps = (state: RootState) => ({
  filters: state.dashboard.selectedFilterOptions,
  bookmarks: state.dashboard.bookmarks,
  selectedBookmarkIdx: state.dashboard.selectedBookmarkIdx,
  isBookmarkFilled: state.dashboard.isBookmarkFilled,
  floatingMenuChange: state.dashboard.floatingMenuChange,
  filterAccordianOpen: state.dashboard.filterAccordianOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(FloatingMenu);

export const getOS = () => {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];

  let os = null;
  let transformedOs = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "MacOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  if (
    /iPhone/.test(userAgent) ||
    /iPad/.test(userAgent) ||
    /iPod/.test(userAgent)
  ) {
    transformedOs = "iOS";
  } else if (/Android/.test(userAgent)) {
    transformedOs = "Android";
  }

  return { os, transformedOs };
};

export const isMobile = () => {
  const { os } = getOS();
  if (os) {
    return ["iOS", "Android"].includes(os);
  }
  return false;
};

export const isMobileView = () => {
  const { transformedOs } = getOS();
  if (transformedOs) {
    return ["iOS", "Android"].includes(transformedOs);
  }
  return false;
};

export const getBrowser = () => {
  const ua = navigator.userAgent;

  if (/opera|opr/i.test(ua)) {
    return "Opera";
  } else if (/msie|trident/i.test(ua)) {
    return "Internet Explorer";
  } else if (/chrome.+? edge/i.test(ua)) {
    return "Microsoft Edge";
  } else if (/chrome|crios|crmo/i.test(ua)) {
    return "Google Chrome";
  } else if (/firefox/i.test(ua)) {
    return "Firefox";
  } else if (/safari/i.test(ua)) {
    return "Safari";
  }

  return "Unknown";
};

import { FC, useState, useEffect } from "react";
import { AppDispatch, RootState } from "../../../store";
import { connect } from "react-redux";
import { selectedValuesState } from "../../../store/modules/orderHistory";
import { QuantityFilterSection, RenderFilterSection } from "./CommonFilter";
import { filterArray } from "./utils";
import { IOrderDsd } from "../../../types/order.types";
import { setOrderHistoryFilteredValues } from "../../../store/modules/dsdOrderHistory";

interface IDCStoreFilterProps {
  orderHistoryData: IOrderDsd[];
  orderHistoryFilteredData: IOrderDsd[];
  setOrderHistoryFilteredValues: (value: IOrderDsd[]) => void;
  selectedvalues: any;
  selectedValuesState: (data: any) => void;
}

export enum FILTER {
  NONE = "none",
  ESTIMATED_ARRIVAL_DATE = "scheduledDeliveryDate",
  ORDER_NUMBER = "orderNumber",
  ORDER_QTY = "orderQuantity",
  STATUS = "statusTypeCd",
}

const DcStoreFilter: FC<IDCStoreFilterProps> = (props) => {
  const {
    orderHistoryData,
    orderHistoryFilteredData,
    setOrderHistoryFilteredValues,
    selectedvalues,
    selectedValuesState,
  } = props;

  const [expandedFilterIdx, setExpandedFilterIdx] = useState<FILTER>(
    FILTER.NONE
  );
  const [radioValue, setRadioValue] = useState<string>(
    selectedvalues.radioValue
  ); //selectedRadioValue options

  const [selectedPOs, setSelectedPOs] = useState<string[]>(
    selectedvalues.selectedPOs
  ); //po's array
  const [selectedDates, setSelectedDates] = useState<string[]>(
    selectedvalues.selectedDates
  ); //dates array
  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    selectedvalues.selectedStatus
  ); //status array

  const [quantityValueFirstSelect, setQuantityValueFirstSelect] = useState(
    selectedvalues.quantityValueFirstSelect
  ); //quantity section first input field
  const [quantityValueSecondSelect, setQuantityValueSecondSelect] = useState(
    selectedvalues.quantityValueSecondSelect
  ); //quantity section second input field

  const [quantityValueFirstValue, setQuantityValueFirstValue] = useState(
    selectedvalues.quantityValueFirstValue
  ); //quantity section first input field
  const [quantityValueSecondValue, setQuantityValueSecondValue] = useState(
    selectedvalues.quantityValueSecondValue
  ); //quantity section second input field

  const [poNumberData, setPoNumberData] = useState<string[]>([]); //poNumbers from table data
  const [status, setStatus] = useState<string[]>([]); //status from table data
  const [dcDates, setDcDates] = useState<string[]>([]); //dcDates from table data

  const changeExpandedFilterIdx = (idx: FILTER) => {
    setExpandedFilterIdx((prev) => (prev === idx ? FILTER.NONE : idx));
  };

  const updateFilterValues = () => {
    const updatedOrderHistoryData =
      selectedvalues.quantityValueFirstSelect?.value ||
      selectedvalues.quantityValueSecondSelect?.value
        ? (filterArray(
            orderHistoryData,
            selectedvalues.quantityValueFirstValue,
            selectedvalues.quantityValueSecondValue,
            selectedvalues.radioValue,
            selectedvalues.quantityValueFirstSelect.value,
            selectedvalues.quantityValueSecondSelect.value
          ) as IOrderDsd[])
        : orderHistoryData;

    const uniquePoNumbers = updatedOrderHistoryData
      .filter(
        (item) =>
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.statusTypeCd)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 ||
          selectedDates.includes(item.scheduledDeliveryDate)
      )
      .map((item) => item.orderNumber)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setPoNumberData(uniquePoNumbers);

    const uniqueStatus = updatedOrderHistoryData
      .filter(
        (item) =>
          selectedPOs.length === 0 || selectedPOs.includes(item.orderNumber)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 ||
          selectedDates.includes(item.scheduledDeliveryDate)
      )
      .map((item) => item.statusTypeCd)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setStatus(uniqueStatus);

    const uniqueDates = updatedOrderHistoryData
      .filter(
        (item) =>
          selectedPOs.length === 0 || selectedPOs.includes(item.orderNumber)
      )
      .filter(
        (item) =>
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.statusTypeCd)
      )
      .map((item) => item.scheduledDeliveryDate)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setDcDates(uniqueDates);
  };

  const filterTableData = () => {
    selectedValuesState({
      selectedPOs,
      selectedDates,
      selectedStatus,
      quantityValueFirstSelect,
      quantityValueFirstValue,
      quantityValueSecondSelect,
      quantityValueSecondValue,
      radioValue,
    });

    const fiteredDcStoreData = orderHistoryData
      .filter(
        (item) =>
          selectedPOs.length === 0 || selectedPOs.includes(item.orderNumber)
      )
      .filter(
        (item) =>
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.statusTypeCd)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 ||
          selectedDates.includes(item.scheduledDeliveryDate)
      );

    if (quantityValueFirstSelect?.value || quantityValueSecondSelect?.value) {
      const updatedOrderHistoryData = filterArray(
        fiteredDcStoreData,
        quantityValueFirstValue,
        quantityValueSecondValue,
        radioValue,
        quantityValueFirstSelect.value,
        quantityValueSecondSelect.value
      ) as IOrderDsd[];
      setOrderHistoryFilteredValues(updatedOrderHistoryData);
    } else {
      setOrderHistoryFilteredValues(fiteredDcStoreData);
    }
    setExpandedFilterIdx(FILTER.NONE);
  };

  useEffect(() => {
    updateFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderHistoryFilteredData]);

  return (
    <div className="mx-2">
      <div className="my-4">
        Filters on <b> Order History</b> Visual
      </div>
      {[FILTER.NONE, FILTER.ESTIMATED_ARRIVAL_DATE].includes(
        expandedFilterIdx
      ) && (
        <RenderFilterSection<FILTER>
          title="Estimated Arrival Date"
          type="date"
          data={dcDates}
          selectedArray={selectedDates}
          setSelectedArray={setSelectedDates}
          filterType={FILTER.ESTIMATED_ARRIVAL_DATE}
          isExpanded={expandedFilterIdx === FILTER.ESTIMATED_ARRIVAL_DATE}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.ORDER_NUMBER].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="Invoice # / Order #"
          data={poNumberData}
          selectedArray={selectedPOs}
          setSelectedArray={setSelectedPOs}
          filterType={FILTER.ORDER_NUMBER}
          isExpanded={expandedFilterIdx === FILTER.ORDER_NUMBER}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.ORDER_QTY].includes(expandedFilterIdx) && (
        <QuantityFilterSection<FILTER>
          title="Ordered Units"
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          inputValueFirst={quantityValueFirstValue}
          setInputValueFirst={setQuantityValueFirstValue}
          inputValueSecond={quantityValueSecondValue}
          setInputValueSecond={setQuantityValueSecondValue}
          quantityObjectFirst={quantityValueFirstSelect}
          handleFirstQuantitySelectChange={setQuantityValueFirstSelect}
          quantityObjectSecond={quantityValueSecondSelect}
          handleSecondQuantitySelectChange={setQuantityValueSecondSelect}
          filterType={FILTER.ORDER_QTY}
          isExpanded={expandedFilterIdx === FILTER.ORDER_QTY}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.STATUS].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="STATUS"
          data={status}
          selectedArray={selectedStatus}
          setSelectedArray={setSelectedStatus}
          filterType={FILTER.STATUS}
          isExpanded={expandedFilterIdx === FILTER.STATUS}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setOrderHistoryFilteredValues: (data: IOrderDsd[]) =>
    dispatch(setOrderHistoryFilteredValues(data)),
  selectedValuesState: (data: any) => dispatch(selectedValuesState(data)),
});

const mapStateToProps = (state: RootState) => ({
  orderHistoryData: state.dsdOrderHistory.orderHistoryData,
  filteredTotal: state.orderHistory.filteredTotal,
  selectedvalues: state.orderHistory.selectedvalues,
  orderHistoryFilteredData: state.dsdOrderHistory.orderHistoryFilteredData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DcStoreFilter);

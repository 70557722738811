import { FC, useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { LoadingIndicator } from "../../../../components";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ScanUsageProps {
  totalUserCount: number;
  scanUserCount: number;
  isLoading?: boolean;
}

const ScanUsage: FC<ScanUsageProps> = (props) => {
  const { totalUserCount, scanUserCount, isLoading = false } = props;

  const data = useMemo(
    () => ({
      labels: ["Users using Barcode Scan", "Users not using Barcode Scan"],
      datasets: [
        {
          label: "Users",
          data: [scanUserCount, totalUserCount - scanUserCount],
          backgroundColor: ["#F6DA79","#6AB9A0"],
          borderColor: ["#F6DA79", "#6AB9A0"],
          borderWidth: 1,
        },
      ],
    }),
    [scanUserCount, totalUserCount]
  );

  const options = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Scan Usage Statistics",
        color: "#000",
        font: {
          size: 20,
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="relative" data-testid="loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <Pie data={data} options={options} data-testid="scan-usage" />
      )}
    </>
  );
};

export default ScanUsage;

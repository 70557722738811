import { FC, useEffect, useRef, JSX } from "react";
import { MoreHorizontal } from "lucide-react";

interface MoreOptionsProps {
  options: {
    label: string;
    icon: JSX.Element;
    onClick: () => void;
    dataTestId: string;
  }[];
  showOptions: boolean;
  setShowOptions: (value: boolean) => void;
}

const MoreOptions: FC<MoreOptionsProps> = (props) => {
  const { options, showOptions, setShowOptions } = props;

  const tableOptionRef = useRef<HTMLDivElement>(null);
  const moreOptnBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !tableOptionRef?.current?.contains(event.target) &&
        !moreOptnBtnRef?.current?.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-5 flex items-center justify-end rounded-b text-black font-bold relative">
      <button
        className="border-2 bg-white"
        onClick={() => {
          setShowOptions(!showOptions);
        }}
        ref={moreOptnBtnRef}
        data-testid="more-option-button"
      >
        <MoreHorizontal />
      </button>
      {showOptions && (
        <div
          className="absolute w-52 h-auto top-6 z-50 bg-white shadow-sm border"
          ref={tableOptionRef}
        >
          {options.map((option) => (
            <button
              key={`option-${option.label}`}
              className="w-full h-12 bg-white flex items-center hover:bg-gray-100 py-1 px-3.5"
              onClick={option.onClick}
              data-testid={option.dataTestId}
            >
              <span className="w-[30px]">{option.icon}</span>
              <span className="text-sm">{option.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MoreOptions;

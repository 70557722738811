import clsx from "clsx";
import Card from "@albertsons/uds/molecule/Card";
import SkeletonLoader from "@albertsons/uds/molecule/SkeletonLoader";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { TabHeader } from "../../../components";
import { formatDateTime } from "../../../utils/date-time-format";
import { ICard } from "../../../types/cards.types";
import "./styles.css";

interface CardsProps<T> {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  sectionLabel: string;
  cards: ICard<T>[];
  isLoading: boolean;
  researchData: T | null;
}

export const Cards = <T,>(props: CardsProps<T>) => {
  const { forceScreen, sectionLabel, cards, isLoading, researchData } = props;

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const showAppliedForNotReceiveInvoiceTag = cards.some(
    (card) => card.forNotReceivedInvoice
  );

  const getCardValue = (card: ICard<T>) => {
    if (researchData?.[card.key]) {
      if (card.type === "date") {
        return formatDateTime(researchData[card.key] as string | number).date;
      }
      return `${researchData?.[card.key] as string | number}`;
    }
    return "NA";
  };

  return (
    <div className="mt-3">
      {screen.small ? (
        <TabHeader
          hideDivider
          title={`${sectionLabel} :`}
          className={screen.extraSmall ? "" : "!text-xl"}
        />
      ) : (
        <TabHeader title={sectionLabel} />
      )}
      <div className={clsx("pt-5 pb-2", screen.extraSmall ? "px-2" : "px-4")}>
        <div className="flex flex-col">
          <div
            className={clsx(
              "grid",
              screen.isMobile ? "grid-cols-3" : "grid-cols-6",
              "place-items-center gap-4"
            )}
          >
            {cards.map((card) => (
              <Card
                className={clsx(
                  "bg-white border-none rounded-none shadow-xl dc-research-cards w-full",
                  screen.extraSmall ? "h-[96px]" : "h-[76px]"
                )}
                key={card.key as string}
              >
                <Card.Header>
                  <div className="bg-neutral-300 border-b-4 w-full border-sky-700 h-full">
                    <div className="font-semibold flex items-center justify-center text-center text-xs">
                      <p
                        className={clsx(
                          "mt-1",
                          screen.extraSmall && "text-[11px]"
                        )}
                      >
                        {card.label}
                        {!card.label2 && card.forNotReceivedInvoice ? (
                          <span className="text-red-500">*</span>
                        ) : (
                          ""
                        )}
                        <br />
                        {card.label2 || (
                          <span className="invisible">PlaceholderText</span>
                        )}
                        {card.label2 && card.forNotReceivedInvoice ? (
                          <span className="text-red-500">*</span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </Card.Header>
                <Card.Content>
                  <div className="items-center font-semibold justify-center text-center !p-2">
                    {isLoading && (!researchData || !researchData[card.key]) ? (
                      <SkeletonLoader skeletonTheme baseColor="#dadce2" />
                    ) : (
                      <p
                        className="text-sm truncate"
                        title={getCardValue(card)}
                      >
                        {getCardValue(card)}
                      </p>
                    )}
                  </div>
                </Card.Content>
              </Card>
            ))}
          </div>
        </div>
      </div>
      {showAppliedForNotReceiveInvoiceTag ? (
        <p className="text-sm pt-2">
          <span className="text-red-500">*</span>
          {"applies to outstanding order (not received)"}
        </p>
      ) : null}
    </div>
  );
};

export default Cards;

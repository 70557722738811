// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-history-table.small-table,
.order-history-table.small-table table {
  max-height: 225px;
}

.order-history-table.large-table,
.order-history-table.large-table table {
  max-height: 200px;
}

.order-history-table {
  max-height: calc(100vh - 152px);
}

.order-history-table thead {
  position: sticky;
  top: -1px;
}

.order-history-table tbody tr:last-child {
  position: sticky;
  bottom: -1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Report/WarehouseOrderHistory/styles.css"],"names":[],"mappings":"AAAA;;EAEE,iBAAiB;AACnB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".order-history-table.small-table,\n.order-history-table.small-table table {\n  max-height: 225px;\n}\n\n.order-history-table.large-table,\n.order-history-table.large-table table {\n  max-height: 200px;\n}\n\n.order-history-table {\n  max-height: calc(100vh - 152px);\n}\n\n.order-history-table thead {\n  position: sticky;\n  top: -1px;\n}\n\n.order-history-table tbody tr:last-child {\n  position: sticky;\n  bottom: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { FC } from "react";
import { X as CloseIcon } from "lucide-react";
import clsx from "clsx";
import Modal from "@albertsons/uds/molecule/Modal";
import Button from "@albertsons/uds/molecule/Button";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";

interface ModalProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  showResetModal: boolean;
  closeResetModal: () => void;
  onReset: () => void;
  content: string;
  minHeight?: number;
}

const ResetModal: FC<ModalProps> = ({
  forceScreen,
  showResetModal,
  minHeight,
  closeResetModal,
  onReset,
  content,
}) => {
  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  return (
    <Modal
      isOpen={showResetModal}
      onClose={closeResetModal}
      minHeight={minHeight ? minHeight : 20}
      minWidth={20}
    >
      {({ close }) => (
        <div
          className={clsx(
            "flex flex-col justify-between bg-white",
            screen.small
              ? "rounded-lg border border-gray-204 p-5"
              : "px-6 pb-6 pt-3 w-[400px]"
          )}
        >
          <div className="flex items-center justify-between mb-5">
            <p
              className={clsx(
                "font-semibold",
                screen.small
                  ? "text-md mb-1"
                  : "text-[#242424] text-xl leading-8"
              )}
            >
              Reset to default
            </p>
            {screen.large && (
              <span
                className="cursor-pointer ml-auto"
                onClick={close}
                data-testid="desktop-close"
              >
                <CloseIcon />
              </span>
            )}
          </div>
          <p className={clsx(screen.large && "text-[#333333] mb-2.5 text-sm")}>
            {content}
          </p>
          <span className="flex justify-end pt-2.5">
            <span className={clsx(screen.large && "order-last")}>
              <Button
                data-testid="cancelbutton"
                fixed
                variant={screen.small ? "tertiary" : "secondary"}
                onClick={close}
                className={clsx(
                  "!w-20",
                  screen.large &&
                    "w-auto !h-8 py-1.5 px-4 ml-2 border-[1px] rounded-md text-black border border-black hover:bg-[#a8a6a6] hover:text-black hover:border-black"
                )}
              >
                {screen.small ? "CANCEL" : "Cancel"}
              </Button>
            </span>
            <span>
              <Button
                data-testid="resetbutton"
                fixed
                variant={screen.small ? "tertiary" : undefined}
                className={clsx(
                  "!w-20",
                  screen.large &&
                    "w-auto !h-8 py-1.5 px-4 ml-2 rounded-md bg-[#117865] border-none hover:bg-[#084d41]"
                )}
                onClick={onReset}
              >
                {screen.small ? "RESET" : "Reset"}
              </Button>
            </span>
          </span>
        </div>
      )}
    </Modal>
  );
};

export default ResetModal;

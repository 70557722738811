import { FC, useState, useEffect, useRef, RefObject } from "react";
import clsx from "clsx";
import html2canvas from "html2canvas";
import { fabric } from "fabric";
import { Pencil, Share2, ArrowLeft, Undo } from "lucide-react";
import { PiScribbleLoopThin } from "react-icons/pi";
import Modal from "@albertsons/uds/molecule/Modal";
import Button from "@albertsons/uds/molecule/Button";
import { Actions, Colors, Brush, Border, Background } from "./constants";
import "fabric-history";
import "./styles.css";

interface AnnotateProps {
  showModal: boolean;
  onClose: () => void;
  contentRef: RefObject<HTMLDivElement>;
}

const Annotate: FC<AnnotateProps> = (props) => {
  const { showModal, onClose, contentRef } = props;

  const [fabricCanvas, setFabricCanvas] = useState<any>(null); // canvas return by fabric
  const [isLoadingScreenshot, setIsLoadingScreenshot] = useState(false);
  const [color, setColor] = useState(Colors.BLACK);
  const [brushSize, setBrushSize] = useState(Brush.THIN);
  const [action, setAction] = useState(Actions.NONE);
  const [showCloseModal, setShowCloseModal] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null); // html canvas which will be forward to fabric
  const pencilRef = useRef<any>(null);

  const canvasWidthRef = useRef({
    width: 0,
    height: 0,
  });

  const openWarningModal = () => {
    setShowCloseModal(true);
  };

  const closeWarningModal = () => {
    setShowCloseModal(false);
  };

  const closeAnnotateModal = () => {
    onClose();
    setShowCloseModal(false);
    setColor(Colors.BLACK);
    setBrushSize(Brush.THIN);
    setAction(Actions.NONE);
  };

  const undo = () => {
    setAction(Actions.NONE);
    if (fabricCanvas?._objects?.length > 1) {
      // to prevent base image getting undo
      fabricCanvas.undo();
    }
  };

  const captureScreenshot = async () => {
    if (contentRef?.current) {
      const elementToCapture = document.documentElement;
      const { scrollLeft, scrollTop, clientWidth, clientHeight } =
        elementToCapture;

      canvasWidthRef.current.width = clientWidth;
      canvasWidthRef.current.height = clientHeight;

      setIsLoadingScreenshot(true);

      html2canvas(contentRef.current, {
        x: scrollLeft,
        y: scrollTop,
        width: clientWidth,
        height: clientHeight,
        scale: 2,
      }).then((canvas) => {
        setIsLoadingScreenshot(false);
        if (canvas) {
          const newFabCanvas = new fabric.Canvas(canvasRef.current);
          const screenshotDataUrl = canvas.toDataURL("image/png");
          fabric.Image.fromURL(screenshotDataUrl, (img: any) => {
            img
              .set({
                selectable: false,
              })
              .scale(0.5);

            img.bringToFront();
            newFabCanvas.add(img);
            newFabCanvas.renderAll();
          });
          setFabricCanvas(newFabCanvas);
        }
      });
    }
  };

  const paintPencil = () => {
    setAction(Actions.PENCIL);
    if (fabricCanvas) {
      const pencil = new fabric.PencilBrush(fabricCanvas);
      pencilRef.current = pencil;
      pencil.color = color;
      pencil.width = brushSize;
      fabricCanvas.freeDrawingBrush = pencil;
      fabricCanvas.isDrawingMode = true;
    }
  };

  const addText = () => {
    setAction(Actions.TEXT);
    if (fabricCanvas) {
      fabricCanvas.isDrawingMode = false;
      const txt = new fabric.IText("sample text");
      txt.set({ fill: color });
      fabricCanvas?.add(txt);
    }
  };

  /* istanbul ignore next */
  const shareScreenshot = () => {
    if (canvasRef.current) {
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "share.png", {
            type: "image/png",
          });
          if (navigator.share) {
            navigator
              .share({
                title: "Annotate",
                text: "Current Inventory Health Summary",
                url: window.location.href,
                // @ts-ignore
                files: [file],
              })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error in sharing", error));
          } else {
            console.log("System does not support sharing files.");
          }
        }
      });
    }
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (action === Actions.PENCIL && pencilRef.current) {
      pencilRef.current.color = color;
      pencilRef.current.width = brushSize;
    }
  }, [color, brushSize, action]);

  useEffect(() => {
    if (showModal) {
      captureScreenshot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div className="annotate-modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none w-full custom-bg h-full">
          <div className="relative w-full h-full">
            {/* modal content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full">
              {/* Modal header */}
              <div className="flex items-center justify-end p-3 border-b border-solid border-blueGray-200 z-10">
                <button
                  data-testid="back-button"
                  type="button"
                  className="w-8 h-8 mr-4 outline-none focus:outline-none text-neutral-700 bg-transparent flex justify-center items-center"
                  onClick={openWarningModal}
                >
                  <ArrowLeft />
                </button>
                <span className="font-medium">Annotate</span>
                <button
                  className="w-8 h-8 ml-auto outline-none focus:outline-none text-neutral-700 bg-transparent flex justify-center items-center"
                  type="button"
                  data-testid="share-button"
                  onClick={shareScreenshot}
                >
                  <Share2 />
                </button>
              </div>

              {/* modal body */}
              <div className="canvas-wrapper w-screen relative flex justify-center items-center">
                {isLoadingScreenshot && (
                  <p className="loading-text">
                    Capturing Screenshot. Please Wait...
                  </p>
                )}
                <canvas
                  ref={canvasRef}
                  width={canvasWidthRef.current.width}
                  height={canvasWidthRef.current.height}
                  className="shadow-md scale-75"
                />
              </div>

              {/* modal footer */}
              <div className="h-5 flex items-center justify-end py-4 px-2 border-t border-solid border-blueGray-200 rounded-b z-10">
                <button
                  className={clsx(
                    "button w-7 h-7 box-border font-bold text-sm p-2 rounded outline-none focus:outline-none rounded-full text-neutral-700 mr-3 justify-center",
                    action === Actions.TEXT && "button-active"
                  )}
                  type="button"
                  data-testid="text-edit-button"
                  onClick={addText}
                >
                  aA
                </button>

                <button
                  className={clsx(
                    "button w-7 h-7 box-border font-bold uppercase text-sm p-2 rounded outline-none focus:outline-none rounded-full text-neutral-700 mr-3",
                    action === Actions.PENCIL && "button-active"
                  )}
                  type="button"
                  data-testid="pencil-button"
                  onClick={paintPencil}
                >
                  <Pencil />
                </button>

                <button
                  className="button w-7 h-7 box-border font-bold uppercase text-sm p-2 rounded outline-none focus:outline-none rounded-full text-neutral-700"
                  type="button"
                  data-testid="undo-button"
                  onClick={undo}
                >
                  <Undo />
                </button>
              </div>
              <div className="h-5 flex items-center justify-between py-4 px-2 border-t border-solid border-blueGray-200 rounded-b z-10">
                <div className="brushset flex items-center justify-end  border-r-2b order-gray-200">
                  {action === Actions.PENCIL && (
                    <>
                      <div
                        className="w-6 h-6 rounded-full mr-2 flex items-center justify-center"
                        onClick={() => {
                          setBrushSize(Brush.THIN);
                        }}
                        style={{
                          background:
                            brushSize === Brush.THIN
                              ? Background.GRAY
                              : Background.NONE,
                        }}
                        data-testid="brush-thin-button"
                      >
                        <PiScribbleLoopThin
                          style={{ fontSize: "1.3em", strokeWidth: "5px" }}
                        />
                      </div>

                      <div
                        className="w-6 h-6 rounded-full mr-2 flex items-center justify-center"
                        onClick={() => {
                          setBrushSize(Brush.MEDIUM);
                        }}
                        style={{
                          background:
                            brushSize === Brush.MEDIUM
                              ? Background.GRAY
                              : Background.NONE,
                        }}
                        data-testid="brush-medium-button"
                      >
                        <PiScribbleLoopThin
                          style={{ fontSize: "1.3em", strokeWidth: "20px" }}
                        />
                      </div>

                      <div
                        className="w-6 h-6 rounded-full mr-2 flex items-center justify-center"
                        onClick={() => {
                          setBrushSize(Brush.THICK);
                        }}
                        style={{
                          background:
                            brushSize === Brush.THICK
                              ? Background.GRAY
                              : Background.NONE,
                        }}
                        data-testid="brush-thick-button"
                      >
                        <PiScribbleLoopThin
                          style={{ fontSize: "1.3em", strokeWidth: "40px" }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="colorset flex items-center justify-end">
                  <div
                    className="w-6 h-6 rounded-full bg-red-600 mr-3"
                    onClick={() => {
                      setColor(Colors.RED);
                    }}
                    style={{
                      border: color === Colors.RED ? Border.BLACK : Border.NONE,
                    }}
                    data-testid="red-color-button"
                  ></div>

                  <div
                    className="w-6 h-6 rounded-full bg-green-600 mr-3"
                    onClick={() => {
                      setColor(Colors.GREEN);
                    }}
                    style={{
                      border:
                        color === Colors.GREEN ? Border.BLACK : Border.NONE,
                    }}
                    data-testid="green-color-button"
                  ></div>

                  <div
                    className="w-6 h-6 rounded-full bg-yellow-300 mr-3"
                    onClick={() => {
                      setColor(Colors.YELLOW);
                    }}
                    style={{
                      border:
                        color === Colors.YELLOW ? Border.BLACK : Border.NONE,
                    }}
                    data-testid="yellow-color-button"
                  ></div>

                  <div
                    className="w-6 h-6 rounded-full bg-blue-700 mr-3"
                    onClick={() => {
                      setColor(Colors.BLUE);
                    }}
                    style={{
                      border:
                        color === Colors.BLUE ? Border.BLACK : Border.NONE,
                    }}
                    data-testid="blue-color-button"
                  ></div>

                  <div
                    className="w-6 h-6 rounded-full bg-neutral-950"
                    onClick={() => {
                      setColor(Colors.BLACK);
                    }}
                    style={{
                      border:
                        color === Colors.BLACK ? Border.GRAY : Border.NONE,
                    }}
                    data-testid="black-color-button"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={showCloseModal}
        onClose={closeWarningModal}
        minHeight={20}
        minWidth={20}
      >
        {({ close }) => (
          <div className="flex flex-col justify-between bg-white rounded-lg border border-gray-204 p-5">
            <span>
              <p className="font-semibold text-md mb-1">
                Are you sure you want to leave?
              </p>
              <p>If you leave this page, your changes will be lost.</p>
            </span>
            <span className="flex justify-end pt-2.5">
              <span>
                <Button
                  fixed
                  variant="tertiary"
                  onClick={close}
                  className="!w-20"
                  data-testid="cancelButton"
                >
                  CANCEL
                </Button>
              </span>
              <span>
                <Button
                  fixed
                  variant="tertiary"
                  className="!w-20"
                  onClick={closeAnnotateModal}
                  data-testid="warn-popup-close-confirm-button"
                >
                  LEAVE
                </Button>
              </span>
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Annotate;

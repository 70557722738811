import { IDCStore, IStoreOrder } from "../../../types/order.types";
import { transformQuantity } from "../../../utils/formatNumber";
import { formatDateTime } from "../../../utils/date-time-format";
import { TableColumn } from "../../Table/CommonTable.types";

export const orderHistoryTabs = [
  {
    id: "dc_order_history",
    label: "DC Order History",
  },
  {
    id: "store_order_history",
    label: "Store Order History",
  },
  {
    id: "buyer_comments",
    label: "Buyer Comments",
  },
];

export const dcStoreColumns: TableColumn<IDCStore>[] = [
  {
    id: "poNumber",
    label: "PO_NUMBER",
    value: (row) => row.poNumber || "-",
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
  },
  {
    id: "orderQuantity",
    label: "ORDER_QTY",
    value: (row) => transformQuantity(row.orderQuantity || 0) || "",
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "right",
    type: "number",
  },
  {
    id: "status",
    label: "STATUS",
    value: (row) => {
      if (row.isLast) {
        return "";
      }
      return row.status || "-";
    },
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
  },
  {
    id: "dcEstimatedArrivalDate",
    label: "DC Est/Arrival Date",
    value: (row) => {
      if (row.isLast) {
        return "";
      }
      return row.dcEstimatedArrivalDate
        ? formatDateTime(row.dcEstimatedArrivalDate).date
        : "-";
    },
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
    type: "date",
  },
];

export const storeOrderColumns: TableColumn<IStoreOrder>[] = [
  {
    id: "orderNumber",
    label: "ORDER ID",
    value: (row) => row.orderNumber || "-",
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
  },
  {
    id: "orderQuantity",
    label: "QUANTITY",
    value: (row) => transformQuantity(row.orderQuantity || 0) || "",
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "right",
    type: "number",
  },
  {
    id: "statusTypeCd",
    label: "STATUS",
    value: (row) => {
      if (row.isLast) {
        return "";
      }
      return row.statusTypeCd || "-";
    },
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
  },
  {
    id: "orderDate",
    label: "ORDER DATE",
    value: (row) => {
      if (row.isLast) {
        return "";
      }
      return row.orderDate ? formatDateTime(row.orderDate).date : "-";
    },
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
    type: "date",
  },
];

import { AnyAction } from "redux";
import {
  IBuyerComments,
  IDCStore,
  IOrderHistory,
  IStoreOrder,
  HISTORY_TAB,
} from "../../types/order.types";
import { IFilterOptions } from "../../types/filters.types";

export const SET_SELECTED_HISTORY_TAB = "SET_SELECTED_HISTORY_TAB";
export const GET_DC_STORE_DATA = "GET_DC_STORE_DATA";
export const GET_DC_STORE_DATA_SUCCESS = "GET_DC_STORE_DATA_SUCCESS";
export const GET_DC_STORE_DATA_ERROR = "GET_DC_STORE_DATA_ERROR";
export const GET_STORE_ORDER_DATA = "GET_STORE_ORDER_DATA";
export const GET_STORE_ORDER_DATA_SUCCESS = "GET_STORE_ORDER_DATA_SUCCESS";
export const GET_STORE_ORDER_DATA_ERROR = "GET_STORE_ORDER_DATA_ERROR";
export const GET_ORDER_HISTORY_TOTAL_QUANTITY =
  "GET_ORDER_HISTORY_TOTAL_QUANTITY";
export const GET_ORDER_HISTORY_TOTAL_QUANTITY_SUCCESS =
  "GET_ORDER_HISTORY_TOTAL_QUANTITY_SUCCESS";
export const GET_BUYER_COMMENTS_DATA = "GET_BUYER_COMMENTS_DATA";
export const GET_BUYER_COMMENTS_DATA_SUCCESS =
  "GET_BUYER_COMMENTS_DATA_SUCCESS";
export const GET_BUYER_COMMENTS_DATA_ERROR = "GET_BUYER_COMMENTS_DATA_ERROR";
export const DC_STORE_FILTERED_DATA = "DC_STORE_FILTERED_DATA";
export const STORE_ORDER_FILTERED_DATA = "STORE_ORDER_FILTERED_DATA";
export const VALUES_IN_STATE = "VALUES_IN_STATE";
export const SELECTED_VALUES_STORE_ORDER = "SELECTED_VALUES_STORE_ORDER";
export const SET_IS_EXPANDED_VIEW_OPEN = "SET_IS_EXPANDED_VIEW_OPEN";
export const RESET_EXPAND_MODE_DC_STORE_FILTER =
  "RESET_EXPAND_MODE_DC_STORE_FILTER";
export const RESET_EXPAND_MODE_STORE_ORDER_FILTER =
  "RESET_EXPAND_MODE_STORE_ORDER_FILTER";

export interface IOrderHistoryReducer {
  selectedHistoryTab: HISTORY_TAB;
  isLoadedDcStoreData: boolean;
  totalDcStoreTotalQuantity: number;
  dcStoreData: IDCStore[] | IOrderHistory[];
  dcStoreFilteredData: IDCStore[] | IOrderHistory[];
  isLoadedStoreOrderData: boolean;
  totalStoreOrderTotalQuantity: number;
  storeOrderData: IStoreOrder[];
  storeOrderFilteredData: IStoreOrder[];
  isLoadedBuyerCommentsData: boolean;
  buyerCommentsData: IBuyerComments | null;
  filteredTotal: any;
  selectedvalues: any;
  selectedvaluesStoreOrder: any;
  isExpandedViewOpen: boolean;
}

export const selectedValuesState = (value: any) => ({
  type: VALUES_IN_STATE,
  value,
});

export const selectedValuesStoreOrderState = (value: any) => ({
  type: SELECTED_VALUES_STORE_ORDER,
  value,
});

export const setSelectedHistoryTab = (value: HISTORY_TAB) => ({
  type: SET_SELECTED_HISTORY_TAB,
  value,
});

export const setDcStoreFilteredValues = (
  value: IDCStore[] | IOrderHistory[]
) => ({
  type: DC_STORE_FILTERED_DATA,
  value,
});

export const setStoreOrderFilteredValues = (value: IStoreOrder[]) => ({
  type: STORE_ORDER_FILTERED_DATA,
  value,
});

export const fetchDcStoreData = (filters: IFilterOptions) => ({
  type: GET_DC_STORE_DATA,
  value: {
    filters,
  },
});

export const fetchDcStoreDataSuccess = (
  data: IDCStore[] | IOrderHistory[]
) => ({
  type: GET_DC_STORE_DATA_SUCCESS,
  value: {
    data,
  },
});

export const fetchDcStoreDataError = () => ({
  type: GET_DC_STORE_DATA_ERROR,
});

export const fetchStoreOrderData = (filters: IFilterOptions) => ({
  type: GET_STORE_ORDER_DATA,
  value: {
    filters,
  },
});

export const fetchStoreOrderDataSuccess = (data: IStoreOrder[]) => ({
  type: GET_STORE_ORDER_DATA_SUCCESS,
  value: {
    data,
  },
});

export const fetchStoreOrderDataError = () => ({
  type: GET_STORE_ORDER_DATA_ERROR,
});

export const fetchOrderHistoryTotalQuantity = (filters: IFilterOptions) => ({
  type: GET_ORDER_HISTORY_TOTAL_QUANTITY,
  value: {
    filters,
  },
});

export const fetchOrderHistoryTotalQuantitySuccess = (
  dcOrderTotalQty: number,
  storeOrderTotalQty: number
) => ({
  type: GET_ORDER_HISTORY_TOTAL_QUANTITY_SUCCESS,
  value: {
    dcOrderTotalQty,
    storeOrderTotalQty,
  },
});

export const fetchBuyerCommentsData = (filters: IFilterOptions) => ({
  type: GET_BUYER_COMMENTS_DATA,
  value: {
    filters,
  },
});

export const fetchBuyerCommentsDataSuccess = (value: IBuyerComments) => ({
  type: GET_BUYER_COMMENTS_DATA_SUCCESS,
  value,
});

export const fetchBuyerCommentsDataError = () => ({
  type: GET_BUYER_COMMENTS_DATA_ERROR,
});

export const setIsExpandedViewOpen = (value: boolean) => ({
  type: SET_IS_EXPANDED_VIEW_OPEN,
  value,
});

export const resetExpandModeDCStoreFilter = () => ({
  type: RESET_EXPAND_MODE_DC_STORE_FILTER,
  value: {
    selectedPOs: [],
    selectedDates: [],
    selectedStatus: [],
    quantityValueFirstSelect: { label: "-Select-", value: undefined },
    quantityValueFirstValue: undefined,
    quantityValueSecondSelect: { label: "-Select-", value: undefined },
    quantityValueSecondValue: undefined,
    radioValue: "&&",
  },
});

export const resetExpandModeStoreOrderFilter = () => ({
  type: RESET_EXPAND_MODE_STORE_ORDER_FILTER,
  value: {
    selectedOrderIds: [],
    selectedDates: [],
    selectedStatus: [],
    quantityValueFirstSelect: { label: "-Select-", value: undefined },
    quantityValueFirstValue: undefined,
    quantityValueSecondSelect: { label: "-Select-", value: undefined },
    quantityValueSecondValue: undefined,
    radioValue: "&&",
  },
});

export const initialState: IOrderHistoryReducer = {
  selectedHistoryTab: HISTORY_TAB.DC_ORDER,
  isLoadedDcStoreData: false,
  totalDcStoreTotalQuantity: 0,
  dcStoreData: [],
  dcStoreFilteredData: [],
  isLoadedStoreOrderData: false,
  totalStoreOrderTotalQuantity: 0,
  storeOrderData: [],
  storeOrderFilteredData: [],
  isLoadedBuyerCommentsData: false,
  buyerCommentsData: null,
  filteredTotal: "",
  selectedvalues: {
    selectedPOs: [],
    selectedDates: [],
    selectedStatus: [],
    quantityValueFirstSelect: { label: "-Select-", value: undefined },
    quantityValueFirstValue: undefined,
    quantityValueSecondSelect: { label: "-Select-", value: undefined },
    quantityValueSecondValue: undefined,
    radioValue: "&&",
  },
  selectedvaluesStoreOrder: {
    selectedOrderIds: [],
    selectedDates: [],
    selectedStatus: [],
    quantityValueFirstSelect: { label: "-Select-", value: undefined },
    quantityValueFirstValue: undefined,
    quantityValueSecondSelect: { label: "-Select-", value: undefined },
    quantityValueSecondValue: undefined,
    radioValue: "&&",
  },
  isExpandedViewOpen: false,
};

export const orderHistoryReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_SELECTED_HISTORY_TAB:
      return {
        ...state,
        selectedHistoryTab: action.value,
      };
    case GET_DC_STORE_DATA:
      return {
        ...state,
        isLoadedDcStoreData: false,
        dcStoreData: [],
        dcStoreFilteredData: [],
      };
    case GET_DC_STORE_DATA_SUCCESS: {
      return {
        ...state,
        isLoadedDcStoreData: true,
        dcStoreData: action.value.data,
        dcStoreFilteredData: action.value.data,
      };
    }
    case GET_DC_STORE_DATA_ERROR: {
      return {
        ...state,
        isLoadedDcStoreData: true,
        dcStoreData: [],
        dcStoreFilteredData: [],
      };
    }
    case DC_STORE_FILTERED_DATA:
      return {
        ...state,
        dcStoreFilteredData: action.value,
      };
    case GET_STORE_ORDER_DATA:
      return {
        ...state,
        isLoadedStoreOrderData: false,
        storeOrderData: [],
        storeOrderFilteredData: [],
      };
    case GET_STORE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        isLoadedStoreOrderData: true,
        storeOrderData: action.value.data,
        storeOrderFilteredData: action.value.data,
      };
    case GET_STORE_ORDER_DATA_ERROR:
      return {
        ...state,
        isLoadedStoreOrderData: true,
        storeOrderData: [],
        storeOrderFilteredData: [],
      };
    case GET_ORDER_HISTORY_TOTAL_QUANTITY:
      return {
        ...state,
        totalDcStoreTotalQuantity: 0,
        totalStoreOrderTotalQuantity: 0,
      };
    case GET_ORDER_HISTORY_TOTAL_QUANTITY_SUCCESS:
      return {
        ...state,
        totalDcStoreTotalQuantity: action.value.dcOrderTotalQty,
        totalStoreOrderTotalQuantity: action.value.storeOrderTotalQty,
      };
    case STORE_ORDER_FILTERED_DATA:
      return {
        ...state,
        storeOrderFilteredData: action.value,
      };
    case GET_BUYER_COMMENTS_DATA:
      return {
        ...state,
        isLoadedBuyerCommentsData: false,
        buyerCommentsData: null,
      };
    case GET_BUYER_COMMENTS_DATA_SUCCESS:
      return {
        ...state,
        isLoadedBuyerCommentsData: true,
        buyerCommentsData: action.value,
      };
    case GET_BUYER_COMMENTS_DATA_ERROR:
      return {
        ...state,
        isLoadedBuyerCommentsData: true,
        buyerCommentsData: null,
      };
    case SET_IS_EXPANDED_VIEW_OPEN:
      return {
        ...state,
        isExpandedViewOpen: action.value,
      };
    case VALUES_IN_STATE:
      return {
        ...state,
        selectedvalues: action.value,
      };
    case RESET_EXPAND_MODE_DC_STORE_FILTER:
      return {
        ...state,
        selectedvalues: action.value,
      };
    case RESET_EXPAND_MODE_STORE_ORDER_FILTER:
      return {
        ...state,
        selectedvaluesStoreOrder: action.value,
      };
    case SELECTED_VALUES_STORE_ORDER:
      return {
        ...state,
        selectedvaluesStoreOrder: action.value,
      };
    default:
      return state;
  }
};

export default orderHistoryReducer;

import type { FC } from "react";
import clsx from "clsx";
import "./styles.css";

interface TabHeaderProps {
  title: string;
  className?: string;
  hideDivider?: boolean;
}

const TabHeader: FC<TabHeaderProps> = (props) => {
  const { title, className = "", hideDivider = false } = props;

  return (
    <div className="tab-header">
      <span
        className={clsx(
          "p-[5px] text-base font-bold text-[#007fb6]",
          className
        )}
        data-testid="heading"
      >
        {title}
      </span>
      {!hideDivider && <hr />}
    </div>
  );
};

export default TabHeader;

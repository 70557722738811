import { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  selectedValuesStoreOrderState,
  setStoreOrderFilteredValues,
} from "../../../store/modules/orderHistory";
import { QuantityFilterSection, RenderFilterSection } from "./CommonFilter";
import { filterArray } from "./utils";
import { IStoreOrder } from "../../../types/order.types";

interface IStoreOrderFilterProps {
  storeOrderData: IStoreOrder[];
  storeOrderFilteredData: IStoreOrder[];
  setStoreOrderFilteredValues: (value: IStoreOrder[]) => void;
  selectedvaluesStoreOrder: any;
  selectedValuesStoreOrderState: (data: any) => void;
}

export enum FILTER {
  NONE = "none",
  ORDER_DATE = "orderDate",
  ORDER_ID = "orderNumber",
  QUANTITY = "orderQuantity",
  STATUS = "statusTypeCd",
}

const StoreOrderFilter: FC<IStoreOrderFilterProps> = (props) => {
  const {
    storeOrderData,
    storeOrderFilteredData,
    setStoreOrderFilteredValues,
    selectedvaluesStoreOrder,
    selectedValuesStoreOrderState,
  } = props;

  const [expandedFilterIdx, setExpandedFilterIdx] = useState<FILTER>(
    FILTER.NONE
  );
  const [radioValue, setRadioValue] = useState<string>(
    selectedvaluesStoreOrder.radioValue
  ); //radio options

  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>(
    selectedvaluesStoreOrder.selectedOrderIds
  ); //order id array
  const [selectedDates, setSelectedDates] = useState<string[]>(
    selectedvaluesStoreOrder.selectedDates
  ); //dates array
  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    selectedvaluesStoreOrder.selectedStatus
  ); //status array

  const [quantityValueFirstSelect, setQuantityValueFirstSelect] = useState(
    selectedvaluesStoreOrder.quantityValueFirstSelect
  ); //quantity section first input field
  const [quantityValueSecondSelect, setQuantityValueSecondSelect] = useState(
    selectedvaluesStoreOrder.quantityValueSecondSelect
  ); //quantity section second input field

  const [quantityValueFirstValue, setQuantityValueFirstValue] = useState(
    selectedvaluesStoreOrder.quantityValueFirstValue
  ); //quantity section first input field
  const [quantityValueSecondValue, setQuantityValueSecondValue] = useState(
    selectedvaluesStoreOrder.quantityValueSecondValue
  ); //quantity section second input field

  const [orderIdData, setOrderIdData] = useState<string[]>([]); //order Id from table data
  const [status, setStatus] = useState<string[]>([]); //status from table data
  const [storeDates, setStoreDates] = useState<string[]>([]); //storeDates from table data

  const changeExpandedFilterIdx = (idx: FILTER) => {
    setExpandedFilterIdx((prev) => (prev === idx ? FILTER.NONE : idx));
  };

  const updateFilterValues = () => {
    const updatedStoreOrderData =
      selectedvaluesStoreOrder.quantityValueFirstSelect?.value ||
      selectedvaluesStoreOrder.quantityValueSecondSelect?.value
        ? (filterArray(
            storeOrderData,
            selectedvaluesStoreOrder.quantityValueFirstValue,
            selectedvaluesStoreOrder.quantityValueSecondValue,
            selectedvaluesStoreOrder.radioValue,
            selectedvaluesStoreOrder.quantityValueFirstSelect.value,
            selectedvaluesStoreOrder.quantityValueSecondSelect.value
          ) as IStoreOrder[])
        : storeOrderData;

    const uniqueorderId = updatedStoreOrderData
      .filter(
        (item) =>
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.statusTypeCd)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 || selectedDates.includes(item.orderDate)
      )
      .map((item) => item.orderNumber)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setOrderIdData(uniqueorderId);

    const uniquestatus = updatedStoreOrderData
      .filter(
        (item) =>
          selectedOrderIds.length === 0 ||
          selectedOrderIds.includes(item.orderNumber)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 || selectedDates.includes(item.orderDate)
      )
      .map((item) => item.statusTypeCd)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setStatus(uniquestatus);

    const uniqueDates = updatedStoreOrderData
      .filter(
        (item) =>
          selectedOrderIds.length === 0 ||
          selectedOrderIds.includes(item.orderNumber)
      )
      .filter(
        (item) =>
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.statusTypeCd)
      )
      .map((item) => item.orderDate)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setStoreDates(uniqueDates);
  };

  const filterTableData = () => {
    selectedValuesStoreOrderState({
      selectedOrderIds,
      selectedDates,
      selectedStatus,
      quantityValueFirstSelect,
      quantityValueFirstValue,
      quantityValueSecondSelect,
      quantityValueSecondValue,
      radioValue,
    });

    const filteredStoreOrderData = storeOrderData
      .filter(
        (item) =>
          selectedOrderIds.length === 0 ||
          selectedOrderIds.includes(item.orderNumber)
      )
      .filter(
        (item) =>
          selectedStatus.length === 0 ||
          selectedStatus.includes(item.statusTypeCd)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 || selectedDates.includes(item.orderDate)
      );

    if (quantityValueFirstSelect?.value || quantityValueSecondSelect?.value) {
      const updatedStoreOrderData = filterArray(
        filteredStoreOrderData,
        quantityValueFirstValue,
        quantityValueSecondValue,
        radioValue,
        quantityValueFirstSelect.value,
        quantityValueSecondSelect.value
      ) as IStoreOrder[];
      setStoreOrderFilteredValues(updatedStoreOrderData);
    } else {
      setStoreOrderFilteredValues(filteredStoreOrderData);
    }
    setExpandedFilterIdx(FILTER.NONE);
  };

  useEffect(() => {
    updateFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrderFilteredData]);

  return (
    <div className="mx-2">
      <div className="my-4">
        Filters on <b> Store Order</b> Visual
      </div>
      {[FILTER.NONE, FILTER.ORDER_DATE].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="ORDER DATE"
          type="date"
          data={storeDates}
          selectedArray={selectedDates}
          setSelectedArray={setSelectedDates}
          filterType={FILTER.ORDER_DATE}
          isExpanded={expandedFilterIdx === FILTER.ORDER_DATE}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.ORDER_ID].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="ORDER ID"
          data={orderIdData}
          selectedArray={selectedOrderIds}
          setSelectedArray={setSelectedOrderIds}
          filterType={FILTER.ORDER_ID}
          isExpanded={expandedFilterIdx === FILTER.ORDER_ID}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.QUANTITY].includes(expandedFilterIdx) && (
        <QuantityFilterSection<FILTER>
          title="QUANTITY"
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          inputValueFirst={quantityValueFirstValue}
          setInputValueFirst={setQuantityValueFirstValue}
          inputValueSecond={quantityValueSecondValue}
          setInputValueSecond={setQuantityValueSecondValue}
          quantityObjectFirst={quantityValueFirstSelect}
          handleFirstQuantitySelectChange={setQuantityValueFirstSelect}
          quantityObjectSecond={quantityValueSecondSelect}
          handleSecondQuantitySelectChange={setQuantityValueSecondSelect}
          filterType={FILTER.QUANTITY}
          isExpanded={expandedFilterIdx === FILTER.QUANTITY}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.STATUS].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="STATUS"
          data={status}
          selectedArray={selectedStatus}
          setSelectedArray={setSelectedStatus}
          filterType={FILTER.STATUS}
          isExpanded={expandedFilterIdx === FILTER.STATUS}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setStoreOrderFilteredValues: (data: IStoreOrder[]) =>
    dispatch(setStoreOrderFilteredValues(data)),
  selectedValuesStoreOrderState: (data: any) =>
    dispatch(selectedValuesStoreOrderState(data)),
});

const mapStateToProps = (state: RootState) => ({
  storeOrderData: state.orderHistory.storeOrderData,
  storeOrderFilteredData: state.orderHistory.storeOrderFilteredData,
  selectedvaluesStoreOrder: state.orderHistory.selectedvaluesStoreOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreOrderFilter);

import axios from "../../lib/axios";
import { IFilter } from "../../types/filters.types";
import checkIsLocalhost from "../../utils/checkIsLocalhost";

const baseUrl = process.env.REACT_APP_API_BASE_URL || "/";
const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;

export const getDCResearchData = (
  fields: string,
  filters: IFilter,
  isFar: boolean
): Promise<any> => {
  const isLocalhost = checkIsLocalhost();

  const headers = {
    ...(isLocalhost
      ? {}
      : {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
          "Content-Type": "text/plain",
        }),
  };

  const raw = `{
    getDcResearchValues(MViewInput: {DCDivision: ${filters.warehouseDivision}, RetailDivision: ${filters.retailDivision}, DistributionCenter: ${filters.distribution}, Warehouse: ${filters.warehouse}, RetailStore: ${filters.retailStore}, Department: ${filters.department}, RetailSection: ${filters.retailSection}, SMIC: ${filters.smic}, UPC: ${filters.upc}, CIC: "${filters.cic}"}, FarDataRequired: ${isFar})
    {
      ${fields}
      errors{ errorCode errorDescription }
    }
  }`;
  const url = `${baseUrl}sdiminventory/mview`;

  return axios.post(
    isLocalhost ? "/api/middleware" : url,
    isLocalhost ? { raw, url } : raw,
    { headers }
  );
};

import {
  FC,
  useState,
  useEffect,
  useRef,
  useMemo,
  FocusEvent,
  createRef,
  RefObject,
} from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Dropdown from "@albertsons/uds/molecule/Dropdown";
import Tooltip from "@albertsons/uds/molecule/Tooltip";
import Popper from "@albertsons/uds/molecule/Popper";
import Input from "@albertsons/uds/molecule/Input";
import Backdrop from "@albertsons/uds/molecule/Backdrop";
import { AppDispatch, RootState } from "../../../store";
import {
  File,
  ChevronDown,
  Share2,
  ArrowLeftToLine,
  Undo2,
  Bookmark,
  RotateCw,
  Info,
  Printer,
  Link2,
  FileText,
  BookmarkPlus,
  MoreHorizontal,
  BookMarked,
  X,
  Pen,
} from "lucide-react";
import { ReportIssueModal, CopyLinkModal } from "../ToolbarSections";
import {
  blockBodyScroll,
  releaseBodyScroll,
} from "../../../utils/blockScrolling";
import {
  addBookmark,
  floatingResetAllAction,
  markAsDefault,
  onDeleteBookmark,
  onRenameBookmark,
  onSelectBookmark,
  refreshLatestDataAction,
} from "../../../store/modules/dashboard";
import SidebarPopup from "../SidebarPopup";
import { AppInfo } from "./components";
import ResetModal from "../ResetModal";
import { IBookmark } from "../../../types/bookmark.types";
import { IFilterOptions } from "../../../types/filters.types";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./styles.css";

interface DesktopToolbarProps {
  isLoading?: boolean;
  floatingResetAll: () => void;
  refreshLatestData: () => void;
  addBookmark: () => void;
  bookmarks: IBookmark[];
  isBookmarkFilled: boolean;
  onSelectBookmark: (index: number) => void;
  onDeleteBookmark: (index: number) => void;
  filters: IFilterOptions;
  onRenameBookmark: (value: string, index: number) => void;
  markAsDefault: (index: number) => void;
  selectedBookmarkIdxDefault: number;
  selectedBookmarkIdx: number;
}

const DesktopToolbar: FC<DesktopToolbarProps> = (props) => {
  const {
    isLoading = false,
    filters,
    bookmarks,
    isBookmarkFilled,
    selectedBookmarkIdxDefault,
    selectedBookmarkIdx,
    floatingResetAll,
    refreshLatestData,
    addBookmark,
    onSelectBookmark,
    onDeleteBookmark,
    onRenameBookmark,
    markAsDefault,
  } = props;

  const [showReportModal, setShowReportModal] = useState(false);
  const [showCopyLinkModal, setShowCopyLinkModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [openAppInfoSection, setOpenAppInfoSection] = useState(false);
  const [bookmarkName, setBookmarkName] = useState("");
  const [showBookmarkPopper, setShowBookmarkPopper] = useState(false);
  const [isOpenBookmarkActions, setIsOpenBookmarkActions] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [currentSelectedBookmarkIdx, setCurrentSelectedBookmarkIdx] =
    useState(-1);

  const isFiltersApplied =
    Object.values(filters).filter((v) => v.length > 0).length > 0;

  const bookmarkRef = useRef<HTMLDivElement>(null);
  const bookmarkRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => bookmarks.map(() => createRef<HTMLDivElement>()),
    [bookmarks]
  );

  const openReportModal = () => {
    blockBodyScroll();
    setShowReportModal(true);
  };

  const closeReportModal = () => {
    releaseBodyScroll();
    setShowReportModal(false);
  };

  const openCopyLinkModal = () => {
    blockBodyScroll();
    setShowCopyLinkModal(true);
  };

  const closeCopyLinkModal = () => {
    releaseBodyScroll();
    setShowCopyLinkModal(false);
  };

  const printPage = () => {
    //setTimeout to makesure the dropdown closes before the print popup
    setTimeout(() => {
      window.print();
    }, 0);
  };

  const closeResetModal = () => {
    setShowResetModal(false);
  };

  const openResetModal = () => {
    setShowResetModal(true);
  };

  /* istanbul ignore next */
  const onReset = () => {
    floatingResetAll();
    closeResetModal();
  };

  const openAppInfo = (isOpen: boolean) => {
    if (isOpen) {
      window.scrollTo(0, 0);
      blockBodyScroll();
    } else {
      releaseBodyScroll();
    }
    setOpenAppInfoSection(isOpen);
  };

  const onAddBookmark = () => {
    addBookmark();
    setBookmarkName(`Bookmark ${bookmarks.length + 1}`);
  };

  const boomarkName = (name: string) => {
    setBookmarkName(name);
  };

  const onBlurBookmarkActions = (ev: FocusEvent<HTMLElement>): void => {
    // Modify based on DOM structure
    /* istanbul ignore next */
    if (
      ev.relatedTarget !==
      bookmarkRefs[currentSelectedBookmarkIdx].current?.firstChild
    ) {
      setIsOpenBookmarkActions(false);
      setIsRenaming(false);
      setCurrentSelectedBookmarkIdx(-1);
    }
  };

  const exportHandler = () => {
    /* istanbul ignore next */
    setTimeout(() => {
      const content = document.getElementById("root");
      const scrollOffset = 10;
      const marginOffset = 15;

      if (content) {
        const { clientWidth, clientHeight } = content;
        const contentWidth = clientWidth + marginOffset;
        const contentHeight = clientHeight + marginOffset;
        html2canvas(content, {
          width: contentWidth + scrollOffset,
          height: contentHeight + scrollOffset,
          scale: 1,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("l", "pt", "letter");
          pdf.addImage(imgData, 0, 0, contentWidth / 2, clientHeight / 2);
          pdf.save("IMPACT mVIEW Dashboard.pdf");
        });
      }
    }, 0);
  };

  useEffect(() => {
    if (bookmarks.length && selectedBookmarkIdx > -1) {
      setBookmarkName(bookmarks[selectedBookmarkIdx].name);
    } else {
      setBookmarkName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBookmarkIdx]);

  /* istanbul ignore next */
  return (
    <>
      <div className="flex items-center w-auto justify-between h-15 bg-[#004B8B] text-white">
        <div className="flex h-12 relative">
          <Dropdown className="nav-dropdown" width={160}>
            <Dropdown.Anchor>
              <button
                className="flex items-center justify-center gap-2 h-15 p-3 hover:bg-[#013c77]"
                data-testid="toolbar-file-button"
                title="File"
              >
                <File size={16} />
                <span className="text-base">File</span>
                <ChevronDown size={16} />
              </button>
            </Dropdown.Anchor>
            <Dropdown.Item onClick={printPage}>
              <span
                className="flex items-center justify-start gap-2"
                data-testid="toolbar-print-button"
              >
                <Printer size={16} />
                <span className="text-base">Print this page</span>
              </span>
            </Dropdown.Item>
          </Dropdown>

          <Dropdown className="nav-dropdown" width={160}>
            <Dropdown.Anchor>
              <button
                className="flex items-center justify-center gap-2 h-15 p-3 hover:bg-[#013c77]"
                data-testid="toolbar-share-button"
                title="Share App"
              >
                <Share2 size={16} />
                <span className="text-base">Share</span>
                <ChevronDown size={16} />
              </button>
            </Dropdown.Anchor>
            <Dropdown.Item onClick={openCopyLinkModal}>
              <span
                className="flex items-center justify-start gap-2"
                data-testid="toolbar-copy-link-button"
              >
                <Link2 size={16} />
                <span className="text-base">Copy link</span>
              </span>
            </Dropdown.Item>
          </Dropdown>

          <Dropdown className="nav-dropdown" width={160}>
            <Dropdown.Anchor>
              <button
                disabled={isLoading}
                className={clsx(
                  "flex items-center justify-center gap-2 h-15 p-3",
                  isLoading ? "bg-gray-100/25" : "hover:bg-[#013c77]"
                )}
                data-testid="toolbar-export-button"
                title="Export"
              >
                <ArrowLeftToLine size={16} />
                <span className="text-base">Export</span>
                <ChevronDown size={16} />
              </button>
            </Dropdown.Anchor>
            <Dropdown.Item onClick={exportHandler}>
              <span
                className="flex items-center justify-start gap-2"
                data-testid="toolbar-export-pdf-button"
              >
                <FileText size={16} />
                <span className="text-base">PDF</span>
              </span>
            </Dropdown.Item>
          </Dropdown>

          <button
            className="p-3 hover:bg-[#013c77]"
            data-testid="report-issue-button"
            onClick={openReportModal}
            title="Report Issue"
          >
            <span className="text-base">Report Issue</span>
          </button>

          <Link
            to="/power-bi-report"
            className="flex items-center gap-2 p-3 hover:bg-[#013c77]"
          >
            PowerBI Reports
          </Link>
        </div>
        <div className="flex h-12">
          <div className="flex item-center mr-2">
            <Tooltip
              zIndex={10}
              anchor="left"
              label={
                isFiltersApplied
                  ? `Reset filters, slicers, and other data view changes
                you've made.`
                  : "You're viewing the author's default view of this report."
              }
            >
              <button
                className="px-3 py-4 hover:bg-[#013c77]"
                onClick={openResetModal}
                disabled={!isFiltersApplied}
                data-testid="reset-modal"
              >
                <Undo2
                  size={16}
                  className={isFiltersApplied ? "" : "opacity-40"}
                />
              </button>
            </Tooltip>
            <Tooltip
              zIndex={10}
              anchor="left"
              label={isBookmarkFilled ? bookmarkName : "Bookmarks"}
            >
              <div ref={bookmarkRef}>
                <button
                  className="flex items-center justify-center gap-2 h-15 px-3 py-4 hover:bg-[#013c77]"
                  data-testid="bookmark-section"
                  title="Bookmarks"
                  onClick={() => {
                    setIsOpenBookmarkActions(false);
                    setIsRenaming(false);
                    setShowBookmarkPopper(true);
                  }}
                >
                  <Bookmark
                    size={16}
                    fill={isBookmarkFilled ? "white" : "none"}
                  />
                  <ChevronDown size={16} />
                </button>
              </div>
            </Tooltip>
            <Backdrop
              isOpen={showBookmarkPopper}
              onClose={() => setShowBookmarkPopper(false)}
              className="backdrop-blur-none bg-transparent"
            >
              <Popper
                anchor={bookmarkRef}
                open={showBookmarkPopper}
                zIndex={1000}
                className="nav-dropdown !right-[36px] border border-gray-204 rounded-lg drop-shadow-md"
              >
                <div className="w-[240px] text-white">
                  <div className="flex items-center justify-start gap-2 p-2">
                    <span className="text-base text-bold">
                      Personal bookmarks
                    </span>
                    <Tooltip
                      zIndex={10}
                      anchor="bottom"
                      label="Personal bookmarks let you return to a specific view of the report."
                    >
                      <Info size={16} />
                    </Tooltip>
                  </div>
                  {bookmarks.map((bookmark, index) => (
                    <div
                      className={clsx(
                        "flex items-center justify-start gap-1 p-2 hover:bg-[#013c77]",
                        index === selectedBookmarkIdx && "bg-[#013c77]"
                      )}
                      data-testid={`bookmark${index}`}
                      key={`bookmark-${bookmark.id}`}
                      onClick={() => {
                        if (!isRenaming) {
                          onSelectBookmark(index);
                          boomarkName(bookmark.name);
                        }
                      }}
                    >
                      {isRenaming && index === currentSelectedBookmarkIdx ? (
                        <span className="flex items-center cursor-pointer">
                          {index === selectedBookmarkIdxDefault && (
                            <BookMarked size={16} className="min-w-4" />
                          )}
                          <Input
                            type="text"
                            autoFocus
                            defaultValue={bookmark.name}
                            onBlur={(e) => {
                              onRenameBookmark(e.target.value, index);
                              setIsRenaming(false);
                            }}
                            className={clsx(
                              "rounded mx-1.5 first:[&>div]:!h-6 [&>div>input]:h-6 [&>div>input]:bg-transparent [&>div>input]:text-white",
                              index !== selectedBookmarkIdxDefault
                                ? "pl-[22px]"
                                : "pl-[6px]"
                            )}
                          />
                        </span>
                      ) : (
                        <span className="flex items-center cursor-pointer">
                          {index === selectedBookmarkIdxDefault && (
                            <BookMarked size={16} className="min-w-4" />
                          )}
                          <span
                            className={clsx(
                              "mx-1.5",
                              index !== selectedBookmarkIdxDefault
                                ? "pl-[22px]"
                                : "pl-[6px]"
                            )}
                          >
                            {bookmark.name}
                          </span>
                        </span>
                      )}
                      <div
                        ref={bookmarkRefs[index]}
                        className="w-fit h-fit ml-auto cursor-pointer"
                        data-testid="more"
                        title="More Options"
                      >
                        <MoreHorizontal
                          size={14}
                          onClick={(event) => {
                            event.stopPropagation();
                            setIsRenaming(false);
                            setCurrentSelectedBookmarkIdx(index);
                            setIsOpenBookmarkActions((prev) => !prev);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    className="flex items-center justify-start border-t border-white gap-1 p-2 rounded-b-lg hover:bg-[#013c77] cursor-pointer"
                    onClick={onAddBookmark}
                    data-testid="add-bookmark"
                  >
                    <BookmarkPlus size={16} />
                    <span className="text-base">Add a personal bookmark</span>
                  </div>
                  {isOpenBookmarkActions && (
                    <Popper
                      anchor={bookmarkRefs[currentSelectedBookmarkIdx]}
                      open
                      onBlur={onBlurBookmarkActions}
                      zIndex={20}
                      className="popper-box w-[137px] text-black"
                    >
                      <div className="flex flex-col bg-white border border-gray-204">
                        <span className="flex items-center cursor-pointer hover:bg-[#F3F2F1] ">
                          <BookMarked size={25} className="min-w-4 pl-2" />
                          <span
                            data-testid="make-default"
                            className=" mt-0 w-full h-fit p-0 p-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              if (
                                currentSelectedBookmarkIdx ===
                                selectedBookmarkIdxDefault
                              ) {
                                markAsDefault(-1);
                              } else {
                                markAsDefault(currentSelectedBookmarkIdx);
                              }
                              setIsOpenBookmarkActions(false);
                            }}
                          >
                            {currentSelectedBookmarkIdx ===
                            selectedBookmarkIdxDefault
                              ? "Clear Default"
                              : "Make Default"}
                          </span>
                        </span>
                        <span className="flex items-center cursor-pointer hover:bg-[#F3F2F1] ">
                          <Pen size={25} className="min-w-4 pl-2" />
                          <span
                            data-testid="rename-bookmark"
                            className="pt-2 mt-0 w-full h-fit p-0 p-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsRenaming(true);
                              setIsOpenBookmarkActions(false);
                            }}
                          >
                            Rename
                          </span>
                        </span>
                        <span className="flex items-center cursor-pointer hover:bg-[#F3F2F1] ">
                          <X size={25} className="min-w-4 pl-2" />
                          <span
                            data-testid="deleteBookmark"
                            className="pt-2 mt-0 w-full h-fit p-0 p-2"
                            onClick={(event) => {
                              event.stopPropagation();
                              onDeleteBookmark(currentSelectedBookmarkIdx);
                              setIsOpenBookmarkActions(false);
                              setBookmarkName("Bookmarks");
                            }}
                          >
                            Delete
                          </span>
                        </span>
                      </div>
                    </Popper>
                  )}
                </div>
              </Popper>
            </Backdrop>
            <Tooltip
              zIndex={10}
              anchor="left"
              label="Refresh visuals-when the data model has been
            updated, refreshing will update all visuals with the
            latest data."
            >
              <button
                className="px-3 py-4 hover:bg-[#013c77]"
                onClick={refreshLatestData}
                data-testid="refreshButton"
              >
                <RotateCw size={16} />
              </button>
            </Tooltip>
          </div>
          <span className="bg-[#032D63] w-[1px] my-2.5" />
          <div className="flex item-center ml-2 mr-1">
            <button
              className="px-3 py-4 hover:bg-[#013c77]"
              onClick={() => openAppInfo(true)}
              title="App Info"
              data-testid="appinfo"
            >
              <Info size={16} />
            </button>
          </div>
        </div>
      </div>
      <ReportIssueModal
        isOpen={showReportModal}
        closeHandler={closeReportModal}
      />
      <CopyLinkModal
        isOpen={showCopyLinkModal}
        closeHandler={closeCopyLinkModal}
      />
      <ResetModal
        showResetModal={showResetModal}
        closeResetModal={closeResetModal}
        onReset={onReset}
        minHeight={64}
        content="Do you want to reset filters, slicers, and other data view changes you've made?"
      />
      {openAppInfoSection && (
        <SidebarPopup
          appInfo
          header="App info"
          handleClose={() => openAppInfo(false)}
        >
          <AppInfo handleClose={() => openAppInfo(false)} />
        </SidebarPopup>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  filters: state.dashboard.selectedFilterOptions,
  bookmarks: state.dashboard.bookmarks,
  isBookmarkFilled: state.dashboard.isBookmarkFilled,
  selectedBookmarkIdxDefault: state.dashboard.selectedBookmarkIdxDefault,
  selectedBookmarkIdx: state.dashboard.selectedBookmarkIdx,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  floatingResetAll: () => dispatch(floatingResetAllAction()),
  refreshLatestData: () => dispatch(refreshLatestDataAction()),
  addBookmark: () => dispatch(addBookmark()),
  onSelectBookmark: (index: number) => dispatch(onSelectBookmark(index)),
  onDeleteBookmark: (index: number) => dispatch(onDeleteBookmark(index)),
  onRenameBookmark: (value: string, index: number) =>
    dispatch(onRenameBookmark(value, index)),
  markAsDefault: (index: number) => dispatch(markAsDefault(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopToolbar);

import type { FC } from "react";
import LoadingIndicator from "../LoadingIndicator";

interface CardProps {
  label: string;
  value: string | number;
  isLoading?: boolean;
}

const Card: FC<CardProps> = (props) => {
  const { label, value, isLoading } = props;

  return (
    <div className="relative">
      <div
        className="bg-white pt-2 px-4 pb-3 text-center"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.3) 7.07107px 7.07107px 10px 3px",
        }}
      >
        <p className="text-sm whitespace-nowrap">{label}</p>
        <p className="text-2xl pt-2 font-semibold">{value}</p>
      </div>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default Card;

export const formatNumber = (
  value: string | number,
  percision = 2
): string | undefined => {
  let formattedValue;
  if (typeof value === "string") {
    formattedValue = parseFloat(value).toFixed(percision);
  }
  if (typeof value === "number") {
    formattedValue = value.toFixed(percision);
  }
  return formattedValue;
};

export const transformQuantity = (
  value: string | number,
  percision = 2
): string | undefined => {
  const formattedValue = formatNumber(value, percision);
  const quantity = Number(formattedValue);
  if (quantity >= 1000 && quantity < 10000) {
    return quantity.toLocaleString(undefined, { maximumFractionDigits: 1 });
  }
  if (quantity >= 10000 && quantity < 1000000) {
    return `${(quantity / 1000).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })}K`;
  }
  if (quantity >= 1000000 && quantity < 1000000000) {
    return `${(quantity / 1000000).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })}M`;
  }
  if (quantity >= 1000000000) {
    return `${(quantity / 1000000000).toLocaleString(undefined, {
      maximumFractionDigits: 1,
    })}bn`;
  }
  return formattedValue;
};

import { FC, useState, useEffect, useCallback, useRef } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { FileDown } from "lucide-react";
import { downloadExcel } from "react-export-table-to-excel";
import { AppDispatch, RootState } from "../../store";
import DatePicker from "@albertsons/uds/molecule/DatePicker";
import { Slider } from "@albertsons/uds/molecule/Slider";
import Select from "@albertsons/uds/molecule/Select";
import { MonitoringHeader } from "./components";
import { Card, MoreOptions, Table } from "../../components";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import {
  fetchUsageDetails,
  fetchMonthlyUsageCount,
  fetchUniqueUserCount,
  fetchUniqueScanUserCount,
} from "../../store/modules/monitor";
import UsageTrends from "./components/Charts/UsageTrends";
import UsagePlatformStatistics from "./components/Charts/UsagePlatformStatistics";
import ScanUsage from "./components/Charts/ScanUsage";
import TopUser from "./components/Charts/TopUsers";
import { REPORT_OPTIONS, userMonitoringColumns } from "./utils";
import {
  formatDateTime,
  addDays,
  getDateDiff,
} from "../../utils/date-time-format";
import { IDropdownOption } from "../../types/dropdown.types";
import { IUniqueUsage, IUsageDetail } from "../../types/monitor.types";
import "./styles.css";

interface UserMonitoringProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  showCharts?: boolean;
  isLoadingUsageDetails: boolean;
  usageDetails: IUsageDetail[];
  isLoadingMonthlyUsageCount: boolean;
  monthlyUsageCount: number;
  isLoadingUniqueUsers: boolean;
  uniqueUsers: IUniqueUsage[];
  isLoadingUniqueScanUsers: boolean;
  uniqueScanUsers: IUniqueUsage[];
  fetchUsageDetails: (start: string, end: string) => void;
  fetchMonthlyUsageCount: (start: string, end: string) => void;
  fetchUniqueUserCount: (start: string, end: string) => void;
  fetchUniqueScanUserCount: (start: string, end: string) => void;
}

const UserMonitoring: FC<UserMonitoringProps> = (props) => {
  const {
    forceScreen,
    showCharts = true,
    isLoadingUsageDetails,
    usageDetails,
    isLoadingMonthlyUsageCount,
    monthlyUsageCount,
    isLoadingUniqueUsers,
    uniqueUsers,
    isLoadingUniqueScanUsers,
    uniqueScanUsers,
    fetchUsageDetails,
    fetchMonthlyUsageCount,
    fetchUniqueUserCount,
    fetchUniqueScanUserCount,
  } = props;

  const [dates, setDates] = useState<Date[]>([]);
  const [bufferDates, setBufferDates] = useState<Date[]>([]);
  const [maxSliderRange, setMaxSliderRange] = useState(30);
  const [sliderValue, setSliderValue] = useState<number[]>([0, 30]);
  const [selectedReport, setSelectedReport] = useState(REPORT_OPTIONS[0]);
  const [showTableOption, setShowTableOption] = useState(false);

  const debounceTimerRef = useRef<NodeJS.Timeout>();
  const isInitializedRef = useRef(false);

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const onExportHandler = () => {
    setShowTableOption(false);

    const header = userMonitoringColumns.map((column) => column.label);
    const body = usageDetails.map((detail) => {
      const row: Array<number | string> = [];
      userMonitoringColumns.forEach((column) => {
        row.push(detail[column.id as keyof IUsageDetail]);
      });
      return row;
    });

    downloadExcel({
      fileName: "mView - Usage Metrics",
      sheet: "mView",
      tablePayload: {
        header,
        body,
      },
    });
  };

  const handleDateChange = ([start, end]: Date[]) => {
    const startDate = new Date(start.setHours(0, 0, 0, 0));
    const endDate = new Date(end.setHours(0, 0, 0, 0));
    setBufferDates([startDate, endDate]);
    setMaxSliderRange(getDateDiff(startDate, endDate));
  };

  const handleStartDateChange = ([start, endDate]: Date[]) => {
    const startDate = new Date(start.setHours(0, 0, 0, 0));
    setBufferDates([startDate, endDate]);
    setMaxSliderRange(getDateDiff(startDate, endDate));
  };

  const handleEndDateChange = ([startDate, end]: Date[]) => {
    const endDate = new Date(end.setHours(0, 0, 0, 0));
    setBufferDates([startDate, endDate]);
    setMaxSliderRange(getDateDiff(startDate, endDate));
  };

  const handleSliderChange = useCallback(
    ({ min, max }: { min: number; max: number }) => {
      setSliderValue([min, max]);
      const startDate = addDays(bufferDates[0], min);
      const endDate = addDays(bufferDates[1], max - maxSliderRange);
      setDates([startDate, endDate]);
      setBufferDates([startDate, endDate]);
    },
    [bufferDates, maxSliderRange]
  );

  useEffect(() => {
    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      if (isInitializedRef.current) {
        const startDate = formatDateTime(bufferDates[0].getTime(), "UTC", {
          date: "YYYY-MM-DD",
        }).date;
        const endDate = formatDateTime(bufferDates[1].getTime(), "UTC", {
          date: "YYYY-MM-DD",
        }).date;
        fetchUniqueUserCount(startDate, endDate);
        fetchUniqueScanUserCount(startDate, endDate);
        fetchUsageDetails(startDate, endDate);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bufferDates]);

  useEffect(() => {
    if (!isInitializedRef.current) {
      isInitializedRef.current = true;
      const startDate = new Date(addDays(new Date(), -30).setHours(0, 0, 0, 0));
      const endDate = new Date(new Date().setHours(0, 0, 0, 0));
      fetchMonthlyUsageCount(
        formatDateTime(startDate.getTime(), "UTC", {
          date: "YYYY-MM-DD",
        }).date,
        formatDateTime(undefined, "UTC", {
          date: "YYYY-MM-DD",
        }).date
      );
      setDates([startDate, endDate]);
      setBufferDates([startDate, endDate]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "Usage Metrics | IMPACT mVIEW Dashboard";
  }, []);

  return (
    <>
      <MonitoringHeader forceScreen={forceScreen} />
      <div className="relative">
        {screen.large && (
          <div
            className="absolute top-0 left-0 w-full h-[150px] z-[10]"
            style={{
              background: "linear-gradient(to bottom, #124d8d, white)",
            }}
          />
        )}
        <div
          className={clsx(
            "py-3 relative z-[20]",
            screen.isMobile ? "px-3" : "px-5"
          )}
        >
          <div className={clsx("flex gap-3", screen.isMobile && "flex-col")}>
            <div
              className={clsx(
                "px-3 border border-white",
                screen.large && "[&>div>button]:bg-white",
                screen.isMobile ? "py-3 [&>div>button]:w-full" : "pt-2 pb-3"
              )}
              style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 15px 3px" }}
            >
              {screen.isMobile ? (
                <>
                  <p className="text-xs font-medium pb-2">Start Date:</p>
                  <DatePicker
                    calendarPosition="left"
                    value={[dates[0]]}
                    onChange={([date]) =>
                      handleStartDateChange([date, bufferDates[1]])
                    }
                    configuration={{ maxDays: 30 }}
                  />
                  <p className="text-xs font-medium py-2">End Date:</p>
                  <DatePicker
                    calendarPosition="left"
                    value={[dates[1]]}
                    onChange={([date]) =>
                      handleEndDateChange([bufferDates[0], date])
                    }
                    configuration={{ maxDays: 30 }}
                  />
                </>
              ) : (
                <>
                  <DatePicker
                    range
                    calendarPosition="left"
                    value={dates}
                    onChange={handleDateChange}
                    configuration={{ maxDays: 30 }}
                  />
                  <div className="p-2 last:[&>div>div]:hidden">
                    <Slider
                      unitLabel=""
                      max={maxSliderRange}
                      hideValue
                      value={sliderValue}
                      onChange={handleSliderChange}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={clsx(
                "px-3 border border-white",
                screen.isMobile ? "py-3" : "pt-2 pb-3"
              )}
              style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 15px 3px" }}
            >
              <p
                className={clsx(
                  "text-xs font-medium pb-2",
                  screen.large && "text-white"
                )}
              >
                Report Name
              </p>
              <Select<IDropdownOption>
                className="w-full border border-[#e5e7eb] text-sm disabled:bg-white"
                disabled
                items={REPORT_OPTIONS}
                itemText="label"
                itemKey="value"
                size="md"
                noTags
                id="report-change-select"
                value={selectedReport}
                onChange={(item) => setSelectedReport(item)}
              />
            </div>
          </div>
          <div className={clsx("flex mt-4 gap-6", screen.small && "flex-col")}>
            <div
              className={clsx(
                "gap-4",
                screen.large ? "flex flex-col" : "grid grid-cols-4",
                screen.isMobile &&
                  (screen.extraSmall ? "!grid-cols-1" : "!grid-cols-2")
              )}
            >
              <Card
                label="Active Report"
                value={usageDetails.length > 0 ? 1 : 0}
                isLoading={isLoadingUsageDetails}
              />
              <Card
                label="Monthly Views"
                value={monthlyUsageCount}
                isLoading={isLoadingMonthlyUsageCount}
              />
              <Card
                label="Total Users"
                value={uniqueUsers.length}
                isLoading={isLoadingUniqueUsers}
              />
              <Card
                label="Unused Reports"
                value={usageDetails.length === 0 ? 1 : 0}
                isLoading={isLoadingUsageDetails}
              />
            </div>
            <div className="w-full h-full my-5 py-5">
              <div className="grid grid-cols-2 gap-5">
                <div
                  className="w-full bg-white h-[35vh] flex justify-center items-center"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 7.07107px 7.07107px 10px 3px",
                  }}
                >
                  {showCharts ? (
                    <UsageTrends
                      usageDetails={usageDetails}
                      startDate={bufferDates[0]}
                      endDate={bufferDates[1]}
                      isLoading={isLoadingUsageDetails}
                    />
                  ) : null}
                </div>
                <div
                  className="w-full bg-white h-[35vh] flex justify-center items-center"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 7.07107px 7.07107px 10px 3px",
                  }}
                >
                  {showCharts ? (
                    <TopUser
                      uniqueUsers={uniqueUsers}
                      isLoading={isLoadingUsageDetails}
                    />
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <div
                  className="w-full bg-white h-[35vh] flex justify-center items-center"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 7.07107px 7.07107px 10px 3px",
                  }}
                >
                  {showCharts ? (
                    <ScanUsage
                      totalUserCount={uniqueUsers.length}
                      scanUserCount={uniqueScanUsers.length}
                      isLoading={isLoadingUniqueScanUsers}
                    />
                  ) : null}
                </div>
                <div
                  className="w-full bg-white h-[35vh] flex justify-center items-center"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.3) 7.07107px 7.07107px 10px 3px",
                  }}
                >
                  {showCharts ? (
                    <UsagePlatformStatistics
                      usageDetails={usageDetails}
                      isLoading={isLoadingUsageDetails}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <MoreOptions
              showOptions={showTableOption}
              setShowOptions={setShowTableOption}
              options={[
                {
                  label: "Export Data",
                  icon: <FileDown size={20} />,
                  onClick: onExportHandler,
                  dataTestId: "export-button",
                },
              ]}
            />
            <Table<IUsageDetail>
              id="usermonitor"
              columns={userMonitoringColumns}
              rows={[...usageDetails]}
              isLoading={isLoadingUsageDetails}
              className="user-monitoring-table"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoadingUsageDetails: state.monitor.isLoadingUsageDetails,
  usageDetails: state.monitor.usageDetails,
  isLoadingMonthlyUsageCount: state.monitor.isLoadingMonthlyUsageCount,
  monthlyUsageCount: state.monitor.monthlyUsageCount,
  isLoadingUniqueUsers: state.monitor.isLoadingUniqueUsers,
  uniqueUsers: state.monitor.uniqueUsers,
  isLoadingUniqueScanUsers: state.monitor.isLoadingUniqueScanUsers,
  uniqueScanUsers: state.monitor.uniqueScanUsers,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsageDetails: (start: string, end: string) =>
    dispatch(fetchUsageDetails(start, end)),
  fetchMonthlyUsageCount: (start: string, end: string) =>
    dispatch(fetchMonthlyUsageCount(start, end)),
  fetchUniqueUserCount: (start: string, end: string) =>
    dispatch(fetchUniqueUserCount(start, end)),
  fetchUniqueScanUserCount: (start: string, end: string) =>
    dispatch(fetchUniqueScanUserCount(start, end)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMonitoring);

import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
} from "react";
import type { FC, ReactNode } from "react";
import { isMobileView } from "../utils/isMobile";

const breakpoints = {
  extraSmall: 320,
  mobile: 576,
  tablet: 768,
  desktop: 1025,
};

interface Screen {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  extraSmall: boolean;
  small: boolean;
  large: boolean;
}

interface WindowBreakpointsContextValue {
  isInitialized: boolean;
  screen: Screen;
  getScreen: (
    forceScreen: "extraSmall" | "mobile" | "desktop" | undefined
  ) => Screen;
}

interface WindowBreakpointsProviderProps {
  children?: ReactNode;
}

const WindowBreakpointsContext = createContext<WindowBreakpointsContextValue>({
  isInitialized: false,
  screen: {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    extraSmall: false,
    small: false,
    large: false,
  },
  getScreen: (forceScreen) => ({
    isMobile: forceScreen === "extraSmall" || forceScreen === "mobile",
    isTablet: forceScreen === "extraSmall" || forceScreen === "mobile",
    isDesktop: forceScreen === "desktop",
    extraSmall: forceScreen === "extraSmall",
    small: forceScreen === "extraSmall" || forceScreen === "mobile",
    large: forceScreen === "desktop",
  }),
});

export const WindowBreakpointsProvider: FC<WindowBreakpointsProviderProps> = (
  props
) => {
  const { children } = props;

  const [isInitialized, setIsInitialized] = useState(false);
  const [screen, setScreen] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    extraSmall: false,
    small: false,
    large: false,
  });

  const getScreen = useCallback(
    (forceScreen: "extraSmall" | "mobile" | "desktop" | undefined) => {
      if (forceScreen) {
        return {
          isMobile: forceScreen === "extraSmall" || forceScreen === "mobile",
          isTablet: forceScreen === "extraSmall" || forceScreen === "mobile",
          isDesktop: forceScreen === "desktop",
          extraSmall: forceScreen === "extraSmall",
          small: forceScreen === "extraSmall" || forceScreen === "mobile",
          large: forceScreen === "desktop",
        };
      }
      return screen;
    },
    [screen]
  );

  const breakpointsProviderValue = useMemo(
    () => ({ isInitialized, screen, getScreen }),
    [isInitialized, screen, getScreen]
  );

  const listener = () => {
    const width = document.body.clientWidth;
    const isSmall = width < breakpoints.desktop || isMobileView();
    setScreen({
      isMobile: width < breakpoints.tablet,
      isTablet: width >= breakpoints.tablet && width < breakpoints.desktop,
      isDesktop: width >= breakpoints.desktop,
      extraSmall: width <= breakpoints.extraSmall,
      small: isSmall,
      large: !isSmall,
    });
    if (!isInitialized) {
      setIsInitialized(true);
    }
  };

  const resizeListener = () => {
    setTimeout(() => {
      listener();
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WindowBreakpointsContext.Provider value={breakpointsProviderValue}>
      {children}
    </WindowBreakpointsContext.Provider>
  );
};

export default WindowBreakpointsContext;

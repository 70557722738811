import { FC, ReactNode } from "react";
import clsx from "clsx";
import { X as CloseIcon } from "lucide-react";
import "./styles.css";

interface SidebarPopupProps {
  header: string;
  appInfo?: boolean;
  children: ReactNode;
  handleClose: () => void;
}

const SidebarPopup: FC<SidebarPopupProps> = (props) => {
  const { header, appInfo = false, children, handleClose } = props;

  return (
    <div
      className={clsx(
        "sidebarSection fixed top-[48px] right-0 z-20 bg-white border border-[#b3b0ad] overflow-y-auto overflow-x-hidden pb-5 pt-2",
        appInfo ? "w-[395px]" : "w-[325px]"
      )}
    >
      <div
        className={clsx(
          "flex h-12",
          header && !appInfo && "bg-neutral-100 p-2"
        )}
      >
        <div
          className={
            appInfo
              ? "px-7 font-semibold text-2xl mt-3"
              : "flex text-center flex items-center justify-center font-bold w-full"
          }
        >
          {header}
        </div>
        <div
          className={clsx(
            "absolute cursor-pointer",
            appInfo ? "top-6 right-5" : "top-3 right-2"
          )}
          onClick={handleClose}
          data-testid="sidebar-popup-close-icon"
        >
          <CloseIcon className={clsx(appInfo && "h-[20px] iconColor")} />
        </div>
      </div>
      <div className={clsx("relative", !appInfo && "px-2")}>{children}</div>
    </div>
  );
};

export default SidebarPopup;

import axios from "axios";

export const getToken = () => {
  const headers = {
    tenant_id: process.env.REACT_APP_TENANT_ID,
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
  };

  return axios.get("/api/login", { headers });
};

import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { impactLogo, inventoryLogo } from "../../assets";
import AboutLinkTable from "./AboutLinkTable";
import {
  ReportInformation,
  dataSource,
  kpiDefinition,
  kpiDefinitionDesr,
  reportConsiderations,
  reportInformation,
} from "./utils";

const About: FC = () => {
  useEffect(() => {
    document.title = "Report Information | IMPACT mVIEW Dashboard";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-screen absolute top-0 left-0 bg-red-500">
      <div className="w-full h-[8%] bg-[#223962] flex justify-between items-center px-2">
        <div className="w-auto h-full flex justify-center items-center">
          <img src={inventoryLogo} alt="albertsons-logo" className="h-[40px]" />
          <img alt="impact-logo" src={impactLogo} className="h-[40px]" />
        </div>
        <div className="w-auto h-full flex justify-center items-center">
          <p className="text-lg font-bold text-white">
            Current Inventory Health Summary
          </p>
        </div>
        <div className="w-auto h-full flex justify-center items-center gap-2">
          <Link
            to="/monitoring"
            className="flex justify-center items-center bg-gray-100 h-4/5 px-5"
            data-testid="usage-metrics-button"
          >
            Usage Metrics
          </Link>
          <Link
            to="/"
            className="flex justify-center items-center bg-gray-100 h-4/5 px-5"
            data-testid="return-to-dashboard-button"
          >
            Return To Dashboard
          </Link>
        </div>
      </div>
      <div className="w-full h-[92%] bg-white px-2 overflow-x-hidden oveflow-y-auto">
        <div className="w-full px-2 py-1 text-[#007fb6] border-b-2 font-bold border-zinc-400">
          Report Information
        </div>

        <div className="w-full px-2 py-1">
          {Object.keys(reportInformation).map(
            (key: keyof ReportInformation, index: number) => {
              return (
                <div
                  key={index}
                  className="flex justify-center items-center h-6"
                >
                  <div className="w-1/3 h-full bg-[#d6dce5] px-2 border border-white hover:bg-gray-400 text-sm font-bold">
                    {key}
                  </div>
                  <div className="w-3/4 h-full bg-[#d6dce5] px-2 border border-white hover:bg-gray-400 text-sm">
                    {reportInformation[key]}
                  </div>
                </div>
              );
            }
          )}
        </div>

        <div className="w-full px-2 py-1 text-[#007fb6] border-b-2 font-bold border-zinc-400">
          KPI Definitions Description of the inventory
        </div>

        <div className="w-full px-2 py-1">
          {kpiDefinitionDesr.map((description: string) => (
            <div className="w-full h-8 bg-[#d6dce5] px-2 border border-white hover:bg-gray-400 text-sm flex justify-start items-center">
              {description}
            </div>
          ))}
        </div>

        <AboutLinkTable
          tableDataList={kpiDefinition}
          tableTitle="KPI Definition"
          tableHeaderList={Object.keys(kpiDefinition[0])}
          tableFooter="*Toggle allows unit count to be displayed in terms of item’s Selling Units, Shipping Units, or Vendor Units using Item Master conversion factor"
        />

        <AboutLinkTable
          tableDataList={dataSource}
          tableTitle="Data Source"
          tableHeaderList={Object.keys(dataSource[0])}
        />

        <div className="w-full px-2 py-1 text-[#007fb6] border-b-2 font-bold border-zinc-400">
          Report Considerations
        </div>

        <div className="w-full px-2 py-1">
          {reportConsiderations.map((consideration: string) => (
            <div className="w-full h-12 bg-gray-300 px-2 border border-white hover:bg-gray-400 text-sm flex justify-start items-center">
              {consideration}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;

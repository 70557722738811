import { IFilterLoaded, IFilter, IFilterOptions } from "../types/filters.types";

export const defaultFiltersLoaded: IFilterLoaded = {
  warehouseDivision: false,
  warehouse: false,
  distribution: false,
  dsdVendor: false,
  retailDivision: false,
  retailStore: false,
  smic: false,
  upc: false,
  cic: false,
  department: false,
  retailSection: false,
};

export const defaultFilters: IFilter = {
  warehouseDivision: "",
  warehouse: "",
  distribution: "",
  dsdVendor: "",
  retailDivision: "",
  retailStore: "",
  smic: "",
  upc: "",
  cic: "",
  department: "",
  retailSection: "",
};

export const defaultFilterOptions: IFilterOptions = {
  warehouseDivision: [],
  warehouse: [],
  distribution: [],
  dsdVendor: [],
  retailDivision: [],
  retailStore: [],
  smic: [],
  upc: [],
  cic: [],
  department: [],
  retailSection: [],
};

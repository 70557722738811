export const filterArray = (
  data: any[],
  value1: any,
  value2: any,
  option: string,
  operator1?: string,
  operator2?: string
) =>
  data.filter((item) => {
    const updatedItemValue = item.orderQuantity;
    if (option === "&&" && value1 && value2) {
      const condition1 = compareValues(updatedItemValue, value1, operator1);
      const condition2 = compareValues(updatedItemValue, value2, operator2);
      return condition1 && condition2;
    } else if (option === "&&" && value1) {
      const condition1 = compareValues(updatedItemValue, value1, operator1);
      return condition1;
    } else if (option === "&&" && value2) {
      const condition2 = compareValues(updatedItemValue, value2, operator2);
      return condition2;
    }
    const condition1 = compareValues(updatedItemValue, value1, operator1);
    const condition2 = compareValues(updatedItemValue, value2, operator2);
    return condition1 || condition2;
  });

const compareValues = (actual: number, expected: number, operator?: string) => {
  switch (operator) {
    case "<":
      return actual < expected;
    case ">":
      return actual > expected;
    case "===":
      return actual === expected;
    case "!==":
      return actual !== expected;
    case ">=":
      return actual >= expected;
    case "<=":
      return actual <= expected;
    case "!":
      return (
        (actual !== undefined && actual !== null) ||
        actual !== "" ||
        (expected !== undefined && expected !== null) ||
        expected !== ""
      );
    case "":
      return (
        actual === undefined ||
        actual === null ||
        expected === undefined ||
        expected === null
      );
    default:
      return false;
  }
};

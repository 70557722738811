import { IFilterOption } from "../types/dropdown.types";
import { IFilter, IFilterOptions } from "../types/filters.types";

export const transformFilterOptions = (
  filterOptions: IFilterOptions
): IFilter => ({
  warehouseDivision: formatFilterOptions(
    filterOptions.warehouseDivision,
    "value"
  ),
  warehouse: formatFilterOptions(filterOptions.warehouse, "value"),
  distribution: formatFilterOptions(filterOptions.distribution, "value"),
  dsdVendor: formatFilterOptions(filterOptions.dsdVendor, "value"),
  retailDivision: formatFilterOptions(filterOptions.retailDivision, "value"),
  retailStore: formatFilterOptions(filterOptions.retailStore, "value"),
  smic: formatFilterOptions(filterOptions.smic, "value"),
  upc: formatFilterOptions(filterOptions.upc, "value"),
  cic: filterOptions.cic.length > 0 ? `${filterOptions.cic[0].value}` : "",
  department: formatFilterOptions(filterOptions.department, "value", true),
  retailSection: formatFilterOptions(
    filterOptions.retailSection,
    "value",
    true
  ),
});

export const formatFilterOptions = (
  filterValues: IFilterOption[],
  key: "label" | "value",
  split = false
) => {
  if (split) {
    return `[${filterValues
      .map((option) => {
        return `"${option[key].split(" - ")[0]}"`;
      })
      .join(",")}]`;
  }
  return `[${filterValues
    .map((option) => {
      return `"${option[key]}"`;
    })
    .join(",")}]`;
};

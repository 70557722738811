import clsx from "clsx";
import { FC } from "react";

interface ChipProps {
  label: string;
  isSelected: boolean;
  onSelect: () => void;
  role?: string;
}

const Chip: FC<ChipProps> = ({ label, isSelected, onSelect, role }) => {
  return (
    <div
      className={clsx(
        "inline-flex items-center rounded-full px-2 py-1 m-1 w-30 cursor-pointer border border-[1.5px]",
        isSelected
          ? "text-black text-bold border-black"
          : "text-slate-400 border-slate-400"
      )}
      role={role}
      onClick={onSelect}
    >
      <span>{label}</span>
    </div>
  );
};

export default Chip;

import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import Body from "@albertsons/uds/molecule/Table/Common/Body";
import Cell from "@albertsons/uds/molecule/Table/Common/Cell";
import Head from "@albertsons/uds/molecule/Table/Common/Head";
import Header from "@albertsons/uds/molecule/Table/Common/Header";
import Row from "@albertsons/uds/molecule/Table/Common/Row";
import Table from "@albertsons/uds/molecule/Table/Common/Table";
import SkeletonLoader from "@albertsons/uds/molecule/SkeletonLoader";
import {
  ArrowDownUp,
  ArrowDownWideNarrow,
  ArrowUpNarrowWide,
} from "lucide-react";
import { TableColumn } from "./CommonTable.types";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import { ISort } from "../../types/sort.types";

interface TableProps<T> {
  id: string;
  columns: TableColumn<T>[];
  rows: T[];
  totalRow?: T;
  isLoading: boolean;
  hasCustomSort?: boolean;
  hasMoreRows?: boolean;
  className?: string;
  handleSort?: (sortDetails?: ISort) => void;
  fetchMore?: () => void;
}

const CommonTable = <T,>(props: TableProps<T>) => {
  const {
    id,
    columns,
    rows,
    totalRow,
    isLoading,
    className = "",
    hasCustomSort = false,
    hasMoreRows = false,
    handleSort = () => {},
    fetchMore = () => {},
  } = props;

  const [sortedRows, setSortedRows] = useState<T[]>([]);
  const [sortByColumn, setSortByColumn] = useState<TableColumn<T> | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const tableRef = useRef<HTMLDivElement>(null);
  const focusedColumnHeaderRef = useRef<string | null>(null);

  const { screen } = useWindowBreakpoints();

  const handleTableSroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isLoading && hasMoreRows && !scrolledToBottom) {
      const element = e.target as HTMLDivElement;
      if (
        element.offsetHeight + element.scrollTop >=
        element.scrollHeight - 50
      ) {
        setScrolledToBottom(true);
      }
    }
  };

  const updateRows = (
    column: TableColumn<T> | null,
    columnIdx: number | null,
    direction: "asc" | "desc"
  ) => {
    if (hasCustomSort) {
      handleSort(
        columnIdx === null
          ? undefined
          : { sortKey: columnIdx + 1, sortOrder: direction }
      );
      if (column) {
        const columnId = column.id;
        focusedColumnHeaderRef.current = columnId;
      }
      return;
    }
    if (column === null) {
      setSortedRows([...rows]);
    } else {
      const columnId = column.id;
      focusedColumnHeaderRef.current = columnId;
      setSortedRows(
        rows.sort((a, b) => {
          if (direction === "asc") {
            if (column.type === "number") {
              // @ts-ignore
              return a[columnId] > b[columnId] ? 1 : -1;
            }
            if (column.type === "date") {
              // @ts-ignore
              return Date.parse(a[columnId]) - Date.parse(b[columnId]);
            }
            // @ts-ignore
            return a[columnId].localeCompare(b[columnId]);
          } else {
            if (column.type === "number") {
              // @ts-ignore
              return b[columnId] > a[columnId] ? 1 : -1;
            }
            if (column.type === "date") {
              // @ts-ignore
              return Date.parse(b[columnId]) - Date.parse(a[columnId]);
            }
            // @ts-ignore
            return b[columnId].localeCompare(a[columnId]);
          }
        })
      );
    }
  };

  const onSort = (column: TableColumn<T>, idx: number) => {
    const columnId = column.id;
    if (tableRef.current && tableRef.current.scrollTo) {
      tableRef.current.scrollTo(0, 0);
    }
    if (sortByColumn && sortByColumn.id === columnId) {
      if (sortDirection === "desc") {
        setSortByColumn(null);
        setSortDirection("asc");
        updateRows(null, null, "asc");
      } else {
        setSortDirection("desc");
        updateRows(column, idx, "desc");
      }
    } else {
      setSortByColumn(column);
      setSortDirection("asc");
      updateRows(column, idx, "asc");
    }
  };

  useEffect(() => {
    if (scrolledToBottom) {
      fetchMore();
      setScrolledToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom]);

  useEffect(() => {
    if (!isLoading) {
      setSortedRows([...rows]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isLoading]);

  return (
    <div
      className={clsx(
        "overflow-auto",
        className,
        screen.small ? "small-table" : "large-table"
      )}
      onScroll={handleTableSroll}
      ref={tableRef}
      data-testid="table-ref"
    >
      <Table className="w-full inline-table">
        <Head>
          <Row hover border className={clsx(screen.large && "h-10")}>
            {columns.map((column, columnIdx) => (
              <Header key={`header-${column.id}`} className="text-center px-0">
                <div className="flex items-center justify-between w-full h-full last:[&>div]:hover:!visible">
                  <div className="font-bold leading-4 text-dark-text select-none whitespace-nowrap truncate text-center text-sm px-3 w-full">
                    {column.label}
                  </div>
                  <div
                    className={clsx(
                      "w-fit h-fit p-1 rounded-full hover:bg-gray-204 cursor-pointer",
                      focusedColumnHeaderRef.current !== column.id
                        ? "invisible"
                        : ""
                    )}
                    data-testid="onSort-click"
                    onClick={() => onSort(column, columnIdx)}
                  >
                    {sortByColumn && sortByColumn.id === column.id ? (
                      <>
                        {sortDirection === "asc" ? (
                          <ArrowUpNarrowWide
                            className="min-w-[13px]"
                            size={13}
                            color="#5A697B"
                          />
                        ) : (
                          <ArrowDownWideNarrow
                            className="min-w-[13px]"
                            size={13}
                            color="#5A697B"
                          />
                        )}
                      </>
                    ) : (
                      <ArrowDownUp
                        className="min-w-[13px]"
                        size={13}
                        color="#5A697B"
                      />
                    )}
                  </div>
                </div>
              </Header>
            ))}
          </Row>
        </Head>
        <Body hover>
          {sortedRows.map((row: any, rowIdx: number) => (
            <Row
              key={`row-${row[id]}-${rowIdx}`}
              hover
              border
              className={clsx("bg-white", screen.large && "h-10")}
            >
              {columns.map((column) => (
                <Cell
                  key={`cell-${column.id}`}
                  className="px-0"
                  {...(column.align ? { align: column.align } : {})}
                >
                  <span
                    className={clsx(
                      "text-base px-3",
                      column.className,
                      column.getClassName && column.getClassName(row)
                    )}
                  >
                    {column.value(row)}
                  </span>
                </Cell>
              ))}
            </Row>
          ))}
          {(isLoading || hasMoreRows) && (
            <Row
              key="loading-row"
              hover
              border
              className={clsx("bg-white", screen.large && "h-10")}
            >
              {columns.map((column) => (
                <Cell
                  key={`cell-${column.id}`}
                  className="px-0"
                  {...(column.align ? { align: column.align } : {})}
                >
                  <span className="block px-2">
                    <SkeletonLoader skeletonTheme baseColor="#dadce2" />
                  </span>
                </Cell>
              ))}
            </Row>
          )}
          {totalRow && (
            <Row
              key="total-row"
              hover
              border
              className={clsx("bg-white", screen.large && "h-10")}
            >
              {columns.map((column) => (
                <Cell
                  key={`cell-${column.id}`}
                  className="px-0"
                  {...(column.align ? { align: column.align } : {})}
                >
                  <span
                    className={clsx(
                      "text-base px-3",
                      column.className,
                      column.getClassName && column.getClassName(totalRow)
                    )}
                  >
                    {column.value(totalRow)}
                  </span>
                </Cell>
              ))}
            </Row>
          )}
        </Body>
      </Table>
    </div>
  );
};

export default CommonTable;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.annotate-modal .button {
  display: flex;
  align-items: center;
  color: #fff;
  background: lightgray;
  border: 1px solid lightgray;
}

.annotate-modal .button-active {
  border-color: black;
}

.annotate-modal .loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.canvas-wrapper {
  height: calc(100vh - 125px);
}
`, "",{"version":3,"sources":["webpack://./src/components/Report/Annotate/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".annotate-modal .button {\n  display: flex;\n  align-items: center;\n  color: #fff;\n  background: lightgray;\n  border: 1px solid lightgray;\n}\n\n.annotate-modal .button-active {\n  border-color: black;\n}\n\n.annotate-modal .loading-text {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.canvas-wrapper {\n  height: calc(100vh - 125px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { FC, useState, useMemo } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { AppDispatch, RootState } from "../../../store";
import Card from "@albertsons/uds/molecule/Card";
import SkeletonLoader from "@albertsons/uds/molecule/SkeletonLoader";
import { ButtonGroup, MoreOptions, Table } from "../../../components";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { dcStoreColumns, storeOrderColumns, orderHistoryTabs } from "./utils";
import ExpandedTablePopup from "../ExpandedTablePopup";
import SidebarPopup from "../SidebarPopup";
import { DcStoreFilter, StoreOrderFilter } from "../FilterAccordian";
import { Expand } from "lucide-react";
import {
  blockBodyScroll,
  releaseBodyScroll,
} from "../../../utils/blockScrolling";
import {
  fetchDcStoreData,
  fetchStoreOrderData,
  fetchBuyerCommentsData,
  setIsExpandedViewOpen,
  setSelectedHistoryTab,
} from "../../../store/modules/orderHistory";
import {
  changeExpandFloatingMenuAction,
  filterAccordianOpenAction,
} from "../../../store/modules/dashboard";
import { IFilterOptions } from "../../../types/filters.types";
import {
  IDCStore,
  IStoreOrder,
  IBuyerComments,
  HISTORY_TAB,
} from "../../../types/order.types";
import "./styles.css";

interface WarehouseOrderHistoryProps {
  selectedFilterOptions: IFilterOptions;
  selectedHistoryTab: HISTORY_TAB;
  isLoadedDcStoreData: boolean;
  totalDcStoreTotalQuantity: number;
  dcStoreFilteredData: IDCStore[];
  isLoadedStoreOrderData: boolean;
  totalStoreOrderTotalQuantity: number;
  storeOrderFilteredData: IStoreOrder[];
  isLoadedBuyerCommentsData: boolean;
  buyerCommentsData: IBuyerComments | null;
  isExpandedViewOpen: boolean;
  setSelectedHistoryTab: (value: number) => void;
  fetchDcStoreData: (filters: IFilterOptions) => void;
  fetchStoreOrderData: (filters: IFilterOptions) => void;
  fetchBuyerCommentsData: (filters: IFilterOptions) => void;
  setIsExpandedViewOpen: (isOpen: boolean) => void;
  filterAccordianOpen: boolean;
  changeExpandFloatingMenu: (value: boolean) => void;
  filterAccordianOpenAction: (value: boolean) => void;
}

const WarehouseOrderHistory: FC<WarehouseOrderHistoryProps> = (props) => {
  const {
    selectedFilterOptions,
    selectedHistoryTab,
    isLoadedDcStoreData,
    isLoadedStoreOrderData,
    dcStoreFilteredData,
    totalDcStoreTotalQuantity,
    totalStoreOrderTotalQuantity,
    storeOrderFilteredData,
    isLoadedBuyerCommentsData,
    buyerCommentsData,
    isExpandedViewOpen,
    setIsExpandedViewOpen,
    setSelectedHistoryTab,
    fetchDcStoreData,
    fetchStoreOrderData,
    fetchBuyerCommentsData,
    filterAccordianOpen,
    filterAccordianOpenAction,
    changeExpandFloatingMenu,
  } = props;

  const [showTableOption, setShowTableOption] = useState(false);

  const { screen } = useWindowBreakpoints();

  const totalDcStoreData = useMemo(
    () => ({
      poNumber: "Total",
      orderQuantity: totalDcStoreTotalQuantity,
      status: "",
      dcEstimatedArrivalDate: "",
      isLast: true,
    }),
    [totalDcStoreTotalQuantity]
  );

  const totalStoreOrderData = useMemo(
    () => ({
      orderNumber: "Total",
      orderQuantity: totalStoreOrderTotalQuantity,
      statusTypeCd: "",
      orderDate: "",
      isLast: true,
    }),
    [totalStoreOrderTotalQuantity]
  );

  const changeHistoryTab = (tab: HISTORY_TAB) => {
    if (tab === selectedHistoryTab) {
      return;
    }
    if (!isLoadedDcStoreData && tab === HISTORY_TAB.DC_ORDER) {
      fetchDcStoreData(selectedFilterOptions);
    } else if (!isLoadedStoreOrderData && tab === HISTORY_TAB.STORE_ORDER) {
      fetchStoreOrderData(selectedFilterOptions);
    } else if (
      !isLoadedBuyerCommentsData &&
      tab === HISTORY_TAB.BUYER_COMMENTS
    ) {
      fetchBuyerCommentsData(selectedFilterOptions);
    }
    setSelectedHistoryTab(tab);
  };

  const handleExpandAction = (action: boolean) => {
    setShowTableOption(false);
    setIsExpandedViewOpen(action);
    if (action) {
      blockBodyScroll();
    } else {
      if (screen.large) {
        window.scrollTo(0, 0);
      }
      releaseBodyScroll();
      changeExpandFloatingMenu(false);
      if (filterAccordianOpen) {
        filterAccordianOpenAction(false);
      }
    }
  };

  return (
    <div className="mb-1">
      <div className="px-2">
        <ButtonGroup
          buttons={orderHistoryTabs}
          value={selectedHistoryTab}
          setValue={changeHistoryTab}
        />
      </div>
      <div className="pt-2">
        <div
          className={clsx(
            "grid grid-cols-2 mb-2",
            [HISTORY_TAB.DC_ORDER, HISTORY_TAB.STORE_ORDER].includes(
              selectedHistoryTab
            )
              ? ""
              : "hidden"
          )}
        >
          <p className="font-bold">
            {selectedHistoryTab === HISTORY_TAB.DC_ORDER
              ? "DC store"
              : "Store order"}
          </p>
          <MoreOptions
            showOptions={showTableOption}
            setShowOptions={setShowTableOption}
            options={[
              {
                label: "Expand to focus mode",
                icon: <Expand size={20} />,
                onClick: () => {
                  handleExpandAction(true);
                  changeExpandFloatingMenu(true);
                },
                dataTestId: "expand-button",
              },
            ]}
          />
        </div>
        <div
          key="dc-store-history-table"
          className={clsx(
            selectedHistoryTab === HISTORY_TAB.DC_ORDER ? "" : "hidden"
          )}
        >
          <Table<IDCStore>
            id="poNumber"
            columns={dcStoreColumns}
            rows={[...dcStoreFilteredData]}
            totalRow={totalDcStoreData}
            isLoading={!isLoadedDcStoreData}
            className="order-history-table"
          />
        </div>
        <div
          key="store-order-history-table"
          className={clsx(
            selectedHistoryTab === HISTORY_TAB.STORE_ORDER ? "" : "hidden"
          )}
        >
          <Table<IStoreOrder>
            id="orderNumber"
            columns={storeOrderColumns}
            rows={[...storeOrderFilteredData]}
            totalRow={totalStoreOrderData}
            isLoading={!isLoadedStoreOrderData}
            className="order-history-table"
          />
        </div>
        {selectedHistoryTab === HISTORY_TAB.BUYER_COMMENTS && (
          <>
            <Card className="bg-white border-none rounded-none shadow-xl dc-research-cards">
              <Card.Header>
                <div className="bg-neutral-300 w-full h-full">
                  <div className="flex items-center justify-center text-center font-bold text-lg">
                    Buyer's Comments1
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <div className="items-center font-semibold justify-center text-center font-bold text-xl !p-2 !h-[44px]">
                  {isLoadedBuyerCommentsData ? (
                    buyerCommentsData?.comment1 || "-"
                  ) : (
                    <SkeletonLoader skeletonTheme baseColor="#dadce2" />
                  )}
                </div>
              </Card.Content>
            </Card>
            <Card className="bg-white border-none rounded-none shadow-xl dc-research-cards">
              <Card.Header>
                <div className="bg-neutral-300 w-full h-full">
                  <div className="flex items-center justify-center text-center font-bold text-lg">
                    Buyer's Comments2
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <div className="items-center font-semibold justify-center text-center font-bold text-xl !p-2 !h-[44px]">
                  {isLoadedBuyerCommentsData ? (
                    buyerCommentsData?.comment2 || "-"
                  ) : (
                    <SkeletonLoader skeletonTheme baseColor="#dadce2" />
                  )}
                </div>
              </Card.Content>
            </Card>
            <Card className="bg-white border-none rounded-none shadow-xl dc-research-cards">
              <Card.Header>
                <div className="bg-neutral-300 w-full h-full">
                  <div className="flex items-center justify-center text-center font-bold text-lg">
                    Buyer's Comments3
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <div className="items-center font-semibold justify-center text-center font-bold text-xl !p-2 !h-[44px]">
                  {isLoadedBuyerCommentsData ? (
                    buyerCommentsData?.comment3 || "-"
                  ) : (
                    <SkeletonLoader skeletonTheme baseColor="#dadce2" />
                  )}
                </div>
              </Card.Content>
            </Card>
          </>
        )}
      </div>
      {isExpandedViewOpen && (
        <ExpandedTablePopup handleClose={() => handleExpandAction(false)}>
          {selectedHistoryTab === HISTORY_TAB.DC_ORDER && (
            <Table<IDCStore>
              key="dc-store-history-expanded-table"
              id="poNumber"
              columns={dcStoreColumns}
              rows={[...dcStoreFilteredData]}
              totalRow={totalDcStoreData}
              isLoading={!isLoadedDcStoreData}
              className="order-history-table"
            />
          )}
          {selectedHistoryTab === HISTORY_TAB.STORE_ORDER && (
            <Table<IStoreOrder>
              key="store-order-history-expanded-table"
              id="orderNumber"
              columns={storeOrderColumns}
              rows={[...storeOrderFilteredData]}
              totalRow={totalStoreOrderData}
              isLoading={!isLoadedStoreOrderData}
              className="order-history-table"
            />
          )}
        </ExpandedTablePopup>
      )}
      {filterAccordianOpen && (
        <>
          {screen.small ? (
            <ExpandedTablePopup
              header="Filters"
              handleClose={() => filterAccordianOpenAction(false)}
            >
              {selectedHistoryTab === HISTORY_TAB.DC_ORDER ? (
                <DcStoreFilter />
              ) : (
                <StoreOrderFilter />
              )}
            </ExpandedTablePopup>
          ) : (
            <SidebarPopup
              header="Filters"
              handleClose={() => filterAccordianOpenAction(false)}
            >
              {selectedHistoryTab === HISTORY_TAB.DC_ORDER ? (
                <DcStoreFilter />
              ) : (
                <StoreOrderFilter />
              )}
            </SidebarPopup>
          )}
        </>
      )}
      {[HISTORY_TAB.DC_ORDER, HISTORY_TAB.STORE_ORDER].includes(
        selectedHistoryTab
      ) && (
        <p className="text-sm pt-2">
          Note- Only outstanding orders (on order and in transit) are shown in
          Totals
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedFilterOptions: state.dashboard.selectedFilterOptions,
  selectedHistoryTab: state.orderHistory.selectedHistoryTab,
  isLoadedDcStoreData: state.orderHistory.isLoadedDcStoreData,
  totalDcStoreTotalQuantity: state.orderHistory.totalDcStoreTotalQuantity,
  dcStoreFilteredData: state.orderHistory.dcStoreFilteredData,
  isLoadedStoreOrderData: state.orderHistory.isLoadedStoreOrderData,
  totalStoreOrderTotalQuantity: state.orderHistory.totalStoreOrderTotalQuantity,
  storeOrderFilteredData: state.orderHistory.storeOrderFilteredData,
  isLoadedBuyerCommentsData: state.orderHistory.isLoadedBuyerCommentsData,
  buyerCommentsData: state.orderHistory.buyerCommentsData,
  isExpandedViewOpen: state.orderHistory.isExpandedViewOpen,
  filterAccordianOpen: state.dashboard.filterAccordianOpen,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchDcStoreData: (filters: IFilterOptions) =>
    dispatch(fetchDcStoreData(filters)),
  fetchStoreOrderData: (filters: IFilterOptions) =>
    dispatch(fetchStoreOrderData(filters)),
  fetchBuyerCommentsData: (filters: IFilterOptions) =>
    dispatch(fetchBuyerCommentsData(filters)),
  setSelectedHistoryTab: (tab: number) => dispatch(setSelectedHistoryTab(tab)),
  setIsExpandedViewOpen: (isOpen: boolean) =>
    dispatch(setIsExpandedViewOpen(isOpen)),
  filterAccordianOpenAction: (value: boolean) =>
    dispatch(filterAccordianOpenAction(value)),
  changeExpandFloatingMenu: (value: boolean) =>
    dispatch(changeExpandFloatingMenuAction(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseOrderHistory);

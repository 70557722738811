import { call, put } from "redux-saga/effects";

import {
  fetchDcResearchData,
  fetchDcResearchDataSuccess,
  fetchDcResearchDataError,
} from "../modules/dcResearch";
import { getDCResearchData } from "../end-points/dcResearch";
import { transformFilterOptions } from "../../utils/formatFilterOptions";
import { ISuccessResponse } from "../../types/response.types";
import { DCResearchCards } from "../../types/cards.types";
import { CATEGORY } from "../../types/category.types";

interface IDCResearchData {
  getDcResearchValues: Partial<DCResearchCards>;
}

export function* getDcResearchData1Saga(
  action: ReturnType<typeof fetchDcResearchData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const fields = `
      dcEstimatedArrivalDate
    `;
    const response: ISuccessResponse<IDCResearchData> = yield call(
      getDCResearchData,
      fields,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    yield put(fetchDcResearchDataSuccess(response.data.getDcResearchValues));
  } catch (error: any) {
    console.log("getDcResearchData1Saga Error:", error);
    yield put(fetchDcResearchDataError());
  }
}

export function* getDcResearchData2Saga(
  action: ReturnType<typeof fetchDcResearchData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const fields = `
      dcLastDateReceived
      cosmoLimits
    `;
    const response: ISuccessResponse<IDCResearchData> = yield call(
      getDCResearchData,
      fields,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    yield put(fetchDcResearchDataSuccess(response.data.getDcResearchValues));
  } catch (error: any) {
    console.log("getDcResearchData2Saga Error:", error);
    yield put(fetchDcResearchDataError());
  }
}

export function* getDcResearchData3Saga(
  action: ReturnType<typeof fetchDcResearchData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const fields = `
      thisWeeksDemand
      codeDatePickSlot
    `;
    const response: ISuccessResponse<IDCResearchData> = yield call(
      getDCResearchData,
      fields,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    yield put(fetchDcResearchDataSuccess(response.data.getDcResearchValues));
  } catch (error: any) {
    console.log("getDcResearchData3Saga Error:", error);
    yield put(fetchDcResearchDataError());
  }
}

export function* getDcResearchData4Saga(
  action: ReturnType<typeof fetchDcResearchData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const fields = `
      mgrOptionR
    `;
    const response: ISuccessResponse<IDCResearchData> = yield call(
      getDCResearchData,
      fields,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    yield put(fetchDcResearchDataSuccess(response.data.getDcResearchValues));
  } catch (error: any) {
    console.log("getDcResearchData4Saga Error:", error);
    yield put(fetchDcResearchDataError());
  }
}

import { FC } from "react";
import Button from "@albertsons/uds/molecule/Button";
import "./styles.css";

interface AppInfoProps {
  handleClose: () => void;
}

const AppInfo: FC<AppInfoProps> = ({ handleClose }) => (
  <>
    <div className="app-info-body border-b border-gray-200 px-7 pt-5">
      <div>
        <p className="font-semibold font-md">App name</p>
        <p className="font-md">IMPACT mVIEW</p>
      </div>
      <div className="mt-5">
        <p className="font-semibold font-md">Owner</p>
        <p className="font-md">Supply Chain IT IMPACT Team</p>
      </div>
      <div className="mt-5">
        <p className="font-semibold font-md">Contact</p>
        <a
          href="mailto:IMPACT.Support@albertsons.com"
          className="font-md text-[#0c695a]  font-bold"
        >
          IMPACT Support
        </a>
      </div>
      <div className="mt-5">
        <p className="font-semibold font-md">Description</p>
        <p className="font-md">
          Provides Insights into the current health of DC and Store inventory
          units
        </p>
      </div>
    </div>
    <div className="mt-[27px] mr-7 w-[90px] ml-auto">
      <Button
        variant="secondary"
        onClick={handleClose}
        data-testid="appinfo-close-button"
        className="ml-auto rounded-none h-[45px] text-black border border-black border-[1px] hover:bg-[#a8a6a6] hover:text-black hover:border-black"
      >
        Close
      </Button>
    </div>
  </>
);

export default AppInfo;

import { FC, useState, useMemo } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { AppDispatch, RootState } from "../../../store";
import { MoreOptions, TabHeader, Table } from "../../../components";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { orderHistoryColumns } from "./utils";
import ExpandedTablePopup from "../ExpandedTablePopup";
import SidebarPopup from "../SidebarPopup";
import { DsdOrderFilter } from "../FilterAccordian";
import { Expand } from "lucide-react";
import {
  blockBodyScroll,
  releaseBodyScroll,
} from "../../../utils/blockScrolling";
import { setIsExpandedViewOpen } from "../../../store/modules/orderHistory";
import {
  changeExpandFloatingMenuAction,
  filterAccordianOpenAction,
} from "../../../store/modules/dashboard";
import { IOrderDsd } from "../../../types/order.types";
import "./styles.css";

interface DSDOrderHistoryProps {
  isLoadedOrderHistoryData: boolean;
  totalOrderHistoryQuantity: number;
  orderHistoryFilteredData: IOrderDsd[];
  isExpandedViewOpen: boolean;
  setIsExpandedViewOpen: (isOpen: boolean) => void;
  filterAccordianOpen: boolean;
  changeExpandFloatingMenu: (value: boolean) => void;
  filterAccordianOpenAction: (value: boolean) => void;
}

const DSDOrderHistory: FC<DSDOrderHistoryProps> = (props) => {
  const {
    isLoadedOrderHistoryData,
    totalOrderHistoryQuantity,
    orderHistoryFilteredData,
    isExpandedViewOpen,
    setIsExpandedViewOpen,
    filterAccordianOpen,
    filterAccordianOpenAction,
    changeExpandFloatingMenu,
  } = props;

  const [showTableOption, setShowTableOption] = useState(false);

  const { screen } = useWindowBreakpoints();

  const totalOrderHistoryData = useMemo(
    () => ({
      orderNumber: "Total",
      orderQuantity: totalOrderHistoryQuantity,
      statusTypeCd: "",
      orderDate: "",
      scheduledDeliveryDate: "",
      isLast: true,
    }),
    [totalOrderHistoryQuantity]
  );

  const handleExpandAction = (action: boolean) => {
    setShowTableOption(false);
    setIsExpandedViewOpen(action);
    if (action) {
      blockBodyScroll();
    } else {
      if (screen.large) {
        window.scrollTo(0, 0);
      }
      releaseBodyScroll();
      changeExpandFloatingMenu(false);
      if (filterAccordianOpen) {
        filterAccordianOpenAction(false);
      }
    }
  };

  return (
    <div className="relative mt-3 mb-1">
      {screen.small ? (
        <TabHeader
          hideDivider
          title="Order History Section :"
          className={screen.extraSmall ? "" : "!text-xl"}
        />
      ) : (
        <TabHeader title="Order History Section" />
      )}

      <div className="pt-2">
        <div
          className={clsx(
            "absolute right-0",
            screen.small ? "top-0.5" : "top-0"
          )}
        >
          <MoreOptions
            showOptions={showTableOption}
            setShowOptions={setShowTableOption}
            options={[
              {
                label: "Expand to focus mode",
                icon: <Expand size={20} />,
                onClick: () => {
                  handleExpandAction(true);
                  changeExpandFloatingMenu(true);
                },
                dataTestId: "expand-button",
              },
            ]}
          />
        </div>
        <div key="order-history-table">
          <Table<IOrderDsd>
            id="invoiceNumber"
            columns={orderHistoryColumns}
            rows={[...orderHistoryFilteredData]}
            totalRow={totalOrderHistoryData}
            isLoading={!isLoadedOrderHistoryData}
            className="order-history-table"
          />
        </div>
      </div>
      {isExpandedViewOpen && (
        <ExpandedTablePopup handleClose={() => handleExpandAction(false)}>
          <Table<IOrderDsd>
            key="order-history-expanded-table"
            id="invoiceNumber"
            columns={orderHistoryColumns}
            rows={[...orderHistoryFilteredData]}
            totalRow={totalOrderHistoryData}
            isLoading={!isLoadedOrderHistoryData}
            className="order-history-table"
          />
        </ExpandedTablePopup>
      )}
      {filterAccordianOpen && (
        <>
          {screen.small ? (
            <ExpandedTablePopup
              header="Filters"
              handleClose={() => filterAccordianOpenAction(false)}
            >
              <DsdOrderFilter />
            </ExpandedTablePopup>
          ) : (
            <SidebarPopup
              header="Filters"
              handleClose={() => filterAccordianOpenAction(false)}
            >
              <DsdOrderFilter />
            </SidebarPopup>
          )}
        </>
      )}
      <p className="text-sm pt-2">
        Note- Only outstanding orders (on order and in transit) are shown in
        Totals
      </p>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoadedOrderHistoryData: state.dsdOrderHistory.isLoadedOrderHistoryData,
  totalOrderHistoryQuantity: state.dsdOrderHistory.totalOrderHistoryQuantity,
  orderHistoryFilteredData: state.dsdOrderHistory.orderHistoryFilteredData,
  isExpandedViewOpen: state.orderHistory.isExpandedViewOpen,
  filterAccordianOpen: state.dashboard.filterAccordianOpen,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setIsExpandedViewOpen: (isOpen: boolean) =>
    dispatch(setIsExpandedViewOpen(isOpen)),
  filterAccordianOpenAction: (value: boolean) =>
    dispatch(filterAccordianOpenAction(value)),
  changeExpandFloatingMenu: (value: boolean) =>
    dispatch(changeExpandFloatingMenuAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DSDOrderHistory);

import axios from "../../lib/axios";
import checkIsLocalhost from "../../utils/checkIsLocalhost";

export const getUsageDetails = (start: string, end: string) =>
  axios.get(`/api/usage-details?start=${start}&end=${end}`);

export const getUsageCount = (start: string, end: string) =>
  axios.get(`/api/usage-count?start=${start}&end=${end}`);

export const getUniqueUserCount = (start: string, end: string) =>
  axios.get(`/api/unique-user-count?start=${start}&end=${end}`);

export const getUniqueScanUserCount = (start: string, end: string) =>
  axios.get(`/api/unique-scan-user-count?start=${start}&end=${end}`);

export const monitor = (type: string, data: any) => {
  const isLocalhost = checkIsLocalhost();

  return axios.get(`/api/monitor?type=${type}&data=${JSON.stringify(data)}`, {
    headers: {
      istesting: isLocalhost,
    },
  });
};

export const monitorBarcodeUsage = (data: any) => {
  const isLocalhost = checkIsLocalhost();

  return axios.get(`/api/scan?data=${JSON.stringify(data)}`, {
    headers: {
      istesting: isLocalhost,
    },
  });
};

import { call, put } from "redux-saga/effects";

import {
  fetchInvoiceReceiptsResearchData,
  fetchInvoiceReceiptsResearchDataSuccess,
  fetchInvoiceReceiptsResearchDataError,
} from "../modules/invoiceReceipts";
import { getInvoiceReceiptsResearchData } from "../end-points/invoiceReceipts";
import { transformFilterOptions } from "../../utils/formatFilterOptions";
import { ISuccessResponse } from "../../types/response.types";
import { InvoiceReceiptsResearchCards } from "../../types/cards.types";

interface IInvoiceReceiptsResearchData {
  getInvoiceReceiptsValues: Partial<InvoiceReceiptsResearchCards>;
}

export function* getInvoiceReceiptsResearchDataSaga(
  action: ReturnType<typeof fetchInvoiceReceiptsResearchData>
) {
  const { filters } = action.value;
  try {
    const response: ISuccessResponse<IInvoiceReceiptsResearchData> = yield call(
      getInvoiceReceiptsResearchData,
      transformFilterOptions(filters)
    );
    yield put(
      fetchInvoiceReceiptsResearchDataSuccess(
        response.data.getInvoiceReceiptsValues
      )
    );
  } catch (error: any) {
    console.log("getInvoiceReceiptsResearchDataSaga Error:", error);
    yield put(fetchInvoiceReceiptsResearchDataError());
  }
}

import { Fragment } from "react";
import type { FC } from "react";
import clsx from "clsx";
import Tooltip from "@albertsons/uds/molecule/Tooltip";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import { ButtonGroupProps } from "./ButtonGroup.types";

export const ButtonGroup: FC<ButtonGroupProps> = (props) => {
  const { title, buttons, value, setValue, forceScreen } = props;

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  return (
    <div className="flex items-center py-2">
      {title && (
        <p
          className="font-bold mr-2 text-sm"
          data-testid="button-group-heading"
        >
          {title}
        </p>
      )}
      <div className="flex w-fit gap-1">
        {buttons.map((button, index) => (
          <Fragment key={button.id}>
            {screen.small ? (
              <button
                className={clsx(
                  "pt-1.5 px-3 pb-0.5 text-sm non-italic border border-solid border-gray-204 border-b-4 text-white font-normal",
                  screen.extraSmall && "!px-2 !text-xs",
                  value === index
                    ? "border-b-brand-light bg-brand hover:bg-brand/90 active:bg-brand/80 focus:bg-brand/80"
                    : "border-b-transparent bg-brand-ultralight hover:bg-brand-ultralight/90 active:bg-brand-ultralight/80 focus:bg-brand-ultralight/80"
                )}
                onClick={() => {
                  setValue(index);
                }}
                data-testid="button-click"
              >
                {button.label}
              </button>
            ) : (
              <Tooltip
                id={`button-${button.id}`}
                zIndex={20}
                anchor="top"
                overflow
              >
                <Tooltip.Popover>
                  <span className="text-md m-2">Click here to follow link</span>
                </Tooltip.Popover>
                <button
                  className={clsx(
                    "pt-1.5 px-3 pb-0.5 text-sm non-italic border border-solid border-gray-204 border-b-4 text-white font-normal",
                    value === index
                      ? "border-b-brand-light bg-brand hover:bg-brand/90 active:bg-brand/80 focus:bg-brand/80"
                      : "border-b-transparent bg-brand-ultralight hover:bg-brand-ultralight/90 active:bg-brand-ultralight/80 focus:bg-brand-ultralight/80"
                  )}
                  onClick={() => {
                    setValue(index);
                  }}
                >
                  {button.label}
                </button>
              </Tooltip>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ButtonGroup;

import { call, put } from "redux-saga/effects";
import { getToken } from "../end-points/auth";

import { gotAccessToken } from "../modules/auth";
import { ISuccessResponse } from "../../types/response.types";

export function* getAccessTokenSaga() {
  const access_token = sessionStorage.getItem("access_token");
  if (access_token) {
    yield put(gotAccessToken({ access_token }));
    return;
  }
  try {
    const response: ISuccessResponse<any> = yield call(getToken);

    yield put(gotAccessToken(response.data));
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

import { FC } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  IPublicClientApplication,
  NavigationClient,
} from "@azure/msal-browser";
import useWindowBreakpoints from "./hooks/useWindowBreakpoints";
import Home from "./pages/home";
import WarehouseFARReport from "./pages/warehouseFARReport";
import DSDnonFreshReport from "./pages/DSDnonFreshReport";
import About from "./pages/about";
import UserMonitoring from "./pages/userMonitoring";
import PowerBiReport from "./pages/powerBi";
import RestrictedRoute from "./routes/RestrictedRoute";
import { CATEGORY } from "./types/category.types";

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
  navigate: any;

  constructor(navigate: any) {
    super();
    this.navigate = navigate;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: any) {
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}

interface AppProps {
  pca: IPublicClientApplication;
}

const App: FC<AppProps> = (props) => {
  const { pca } = props;

  const matches = useWindowBreakpoints();
  const navigate = useNavigate();

  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  if (!matches.isInitialized) {
    return null;
  }

  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="/" element={<RestrictedRoute Element={Home} />} />
        <Route
          path={CATEGORY.WAREHOUSE_FAR}
          element={<RestrictedRoute Element={WarehouseFARReport} />}
        />
        <Route
          path={CATEGORY.WAREHOUSE_NON_FAR}
          element={<RestrictedRoute Element={WarehouseFARReport} />}
        />
        <Route
          path={CATEGORY.DSD_NON_FRESH}
          element={<RestrictedRoute Element={DSDnonFreshReport} />}
        />
        <Route path="/about" element={<RestrictedRoute Element={About} />} />
        <Route
          path="/monitoring"
          element={<RestrictedRoute Element={UserMonitoring} />}
        />
        <Route
          path="/power-bi-report"
          element={<RestrictedRoute Element={PowerBiReport} />}
        />
      </Routes>
    </MsalProvider>
  );
};

export default App;

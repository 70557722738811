import { useState, useRef } from "react";
import type { FC } from "react";
import clsx from "clsx";
import { ArrowDownUp } from "lucide-react";

interface TableData {
  [key: string]: any;
}

interface AboutLinkTableProps {
  tableTitle: string;
  tableHeaderList: Array<string>;
  tableDataList: Array<TableData>;
  tableFooter?: string;
}

const AboutLinkTable: FC<AboutLinkTableProps> = (props) => {
  const { tableTitle, tableHeaderList, tableDataList, tableFooter } = props;

  const [localTableData, setLocalTableData] = useState(tableDataList);
  const sortRef = useRef("asc");

  const ascSort = (sortBy: keyof TableData) => {
    const currentData = [...localTableData];
    currentData.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
    sortRef.current = "desc";
    return currentData;
  };

  const descSort = (sortBy: keyof TableData) => {
    const currentData = [...localTableData];
    currentData.sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : -1));
    sortRef.current = "asc";
    return currentData;
  };

  const doSorting = (sortKey: keyof TableData) => {
    if (sortRef.current === "asc") {
      setLocalTableData(ascSort(sortKey));
    } else {
      setLocalTableData(descSort(sortKey));
    }
  };

  return (
    <div className="w-full">
      <div className="w-full px-2 py-1 text-[#007fb6] border-b-2 font-bold border-zinc-400">
        {tableTitle}
      </div>
      <div className="w-full px-2 py-1">
        <table className="w-full">
          <thead>
            <tr className="bg-[#333f50] text-white">
              {tableHeaderList.map((header: string, index: number) => (
                <th
                  key={index}
                  className="border border-white h-6 group"
                  style={{ minWidth: "150px" }}
                  onClick={() => doSorting(header)}
                  data-testid="sort-table-header-cell"
                >
                  <div className="flex h-full w-full justify-center items-center gap-5">
                    {header}
                    <ArrowDownUp
                      className="min-w-[13px] invisible group-hover:visible"
                      size={16}
                      color="#fff"
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {localTableData.map((tdata: TableData, dindex: number) => (
              <tr
                key={dindex}
                className={clsx(
                  "h-auto",
                  dindex % 2 === 0 ? "bg-white" : "bg-[#d6dce5]"
                )}
              >
                {Object.keys(tdata).map((tDataKey: any, kindex: number) => (
                  <td
                    className="h-full border border-white text-sm hover:bg-gray-400"
                    key={`${dindex}-${kindex}`}
                  >
                    {tdata[tDataKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {tableFooter && (
            <tfoot className="w-full">
              <tr className="bg-[#333f50] text-white">
                <td colSpan={tableHeaderList.length} className="px-2 text-sm">
                  {tableFooter}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {/* This hidden field is to increase the coverage by checking its value for asc/desc sorting functions */}
      <input
        type="hidden"
        value={sortRef.current}
        data-testid="sort-hidden-field"
      />
    </div>
  );
};

export default AboutLinkTable;

import { AnyAction } from "redux";
import { IFilterOptions } from "../../types/filters.types";
import { InvoiceReceiptsResearchCards } from "../../types/cards.types";

export const GET_INVOICE_RECEIPTS_RESEARCH_DATA =
  "GET_INVOICE_RECEIPTS_RESEARCH_DATA";
export const GET_INVOICE_RECEIPTS_RESEARCH_DATA_SUCCESS =
  "GET_INVOICE_RECEIPTS_RESEARCH_DATA_SUCCESS";
export const GET_INVOICE_RECEIPTS_RESEARCH_DATA_ERROR =
  "GET_INVOICE_RECEIPTS_RESEARCH_DATA_ERROR";

export interface IInvoiceReceiptsResearchReducer {
  isLoadingInvoiceReceiptsResearchCards: boolean;
  invoiceReceiptsResearchData: InvoiceReceiptsResearchCards | null;
}

export const fetchInvoiceReceiptsResearchData = (filters: IFilterOptions) => ({
  type: GET_INVOICE_RECEIPTS_RESEARCH_DATA,
  value: {
    filters,
  },
});

export const fetchInvoiceReceiptsResearchDataSuccess = (
  value: Partial<InvoiceReceiptsResearchCards>
) => ({
  type: GET_INVOICE_RECEIPTS_RESEARCH_DATA_SUCCESS,
  value,
});

export const fetchInvoiceReceiptsResearchDataError = () => ({
  type: GET_INVOICE_RECEIPTS_RESEARCH_DATA_ERROR,
});

export const initialState: IInvoiceReceiptsResearchReducer = {
  isLoadingInvoiceReceiptsResearchCards: false,
  invoiceReceiptsResearchData: null,
};

export const dcResearchReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_INVOICE_RECEIPTS_RESEARCH_DATA:
      return {
        ...state,
        isLoadingInvoiceReceiptsResearchCards: true,
        invoiceReceiptsResearchData: null,
      };
    case GET_INVOICE_RECEIPTS_RESEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingInvoiceReceiptsResearchCards: false,
        invoiceReceiptsResearchData: action.value,
      };
    case GET_INVOICE_RECEIPTS_RESEARCH_DATA_ERROR:
      return {
        ...state,
        isLoadingInvoiceReceiptsResearchCards: false,
      };
    default:
      return state;
  }
};

export default dcResearchReducer;

import { FC, useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { IUsageDetail } from "../../../../types/monitor.types";
import { LoadingIndicator } from "../../../../components";

ChartJS.register(ArcElement, Tooltip, Legend);

interface UsagePlatformStatisticsProps {
  usageDetails: IUsageDetail[];
  isLoading?: boolean;
}

const UsagePlatformStatistics: FC<UsagePlatformStatisticsProps> = (props) => {
  const { usageDetails, isLoading = false } = props;

  const [platformMetrics, setPlatformMetrics] = useState({
    mobile: 0,
    desktop: 0,
  });

  useEffect(() => {
    const mobile = usageDetails.filter(
      (detail: IUsageDetail) => detail.platform === "Mobile"
    ).length;
    const desktop = usageDetails.filter(
      (detail: IUsageDetail) => detail.platform === "Desktop"
    ).length;
    setPlatformMetrics({ mobile, desktop });
  }, [usageDetails]);

  const data = {
    labels: ["Mobile", "Web"],
    datasets: [
      {
        label: "Users",
        data: [platformMetrics.mobile, platformMetrics.desktop],
        backgroundColor: ["#F6DA79","#6AB9A0"],
        borderColor: ["#F6DA79","#6AB9A0"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Usage Platform Statistics",
        color: "#000",
        font: {
          size: 20,
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="relative" data-testid="loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <Pie data={data} options={options} />
      )}
    </>
  );
};

export default UsagePlatformStatistics;

import { call, put } from "redux-saga/effects";

import {
  fetchUsageDetails,
  fetchUsageDetailsSuccess,
  fetchUsageDetailsError,
  monitorViewReport,
  monitorBarcodeScanUsage,
  fetchMonthlyUsageCount,
  fetchMonthlyUsageCountSuccess,
  fetchMonthlyUsageCountError,
  fetchUniqueUserCount,
  fetchUniqueUserCountSuccess,
  fetchUniqueUserCountError,
  fetchUniqueScanUserCountSuccess,
  fetchUniqueScanUserCountError,
} from "../modules/monitor";
import {
  getUniqueUserCount,
  getUsageCount,
  getUsageDetails,
  monitor,
  monitorBarcodeUsage,
  getUniqueScanUserCount,
} from "../end-points/monitor";
import { ISuccessResponse } from "../../types/response.types";
import {
  IUsageAPIResponse,
  IUsageDetail,
  IUniqueUsage,
} from "../../types/monitor.types";

export function* getUsageDetailsSaga(
  action: ReturnType<typeof fetchUsageDetails>
) {
  const { start, end } = action.value;
  try {
    const response: ISuccessResponse<IUsageAPIResponse[]> = yield call(
      getUsageDetails,
      start,
      end
    );
    const data: IUsageDetail[] = response.data.map((row) => ({
      ...row,
      created_at: row.created_at.value,
    }));
    yield put(fetchUsageDetailsSuccess(data));
  } catch (error: any) {
    console.log("getUsageDetailsSaga Error:", error);
    yield put(fetchUsageDetailsError());
  }
}

export function* getMonthlyUsageCountSaga(
  action: ReturnType<typeof fetchMonthlyUsageCount>
) {
  const { start, end } = action.value;
  try {
    const response: ISuccessResponse<{ count: number }> = yield call(
      getUsageCount,
      start,
      end
    );
    yield put(fetchMonthlyUsageCountSuccess(response.data.count));
  } catch (error: any) {
    console.log("getMonthlyUsageCountSaga Error:", error);
    yield put(fetchMonthlyUsageCountError());
  }
}

export function* getUniqueUserCountSaga(
  action: ReturnType<typeof fetchUniqueUserCount>
) {
  const { start, end } = action.value;
  try {
    const response: ISuccessResponse<IUniqueUsage[]> = yield call(
      getUniqueUserCount,
      start,
      end
    );
    yield put(fetchUniqueUserCountSuccess(response.data));
  } catch (error: any) {
    console.log("getUniqueUserCountSaga Error:", error);
    yield put(fetchUniqueUserCountError());
  }
}

export function* getUniqueScanUserCountSaga(
  action: ReturnType<typeof fetchUniqueUserCount>
) {
  const { start, end } = action.value;
  try {
    const response: ISuccessResponse<IUniqueUsage[]> = yield call(
      getUniqueScanUserCount,
      start,
      end
    );
    yield put(fetchUniqueScanUserCountSuccess(response.data));
  } catch (error: any) {
    console.log("getUniqueScanUserCountSaga Error:", error);
    yield put(fetchUniqueScanUserCountError());
  }
}

export function* monitorViewReportSaga(
  action: ReturnType<typeof monitorViewReport>
) {
  const tracked_user_login = sessionStorage.getItem("tracked_user_login");
  if (tracked_user_login === "1") {
    return;
  }
  const { data } = action.value;
  try {
    const response: ISuccessResponse<{ ok: boolean; monitorId: string }> =
      yield call(monitor, "View Report", data);

    sessionStorage.setItem("monitorId", response.data.monitorId ?? null);
    sessionStorage.setItem("tracked_user_login", "1");
  } catch (error: any) {
    console.log("monitorViewReportSaga Error:", error);
  }
}

export function* monitorBarcodeUsageSaga(
  action: ReturnType<typeof monitorBarcodeScanUsage>
) {
  const { data } = action.value;
  try {
    yield call(monitorBarcodeUsage, data);
  } catch (error: any) {
    console.log("monitorBarcodeUsageSaga Error:", error);
  }
}

import { TableColumn } from "../../components/Table/CommonTable.types";
import { IUsageDetail } from "../../types/monitor.types";
import { formatDateTime } from "../../utils/date-time-format";

export const REPORT_OPTIONS = [
  { label: "IMPACT mVIEW Dashboard", value: "mView" },
];

export const userMonitoringColumns: TableColumn<IUsageDetail>[] = [
  {
    id: "report",
    label: "REPORT NAME",
    value: (row) => row.report || "",
    align: "center",
  },
  {
    id: "name",
    label: "NAME",
    value: (row) => row.name || "",
    align: "center",
  },
  {
    id: "email",
    label: "EMAIL",
    value: (row) => row.email || "",
    align: "center",
  },
  {
    id: "created_at",
    label: "ACCESSED DATE",
    value: (row) => {
      return row.created_at
        ? `${formatDateTime(row.created_at).date} ${
            formatDateTime(row.created_at).time
          } `
        : "";
    },
    type: "date",
    align: "center",
  },
];

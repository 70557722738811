export interface IDCStore {
  poNumber: string;
  orderQuantity: number;
  status: string;
  dcEstimatedArrivalDate: string;
  isLast?: boolean;
}

export interface IStoreOrder {
  orderNumber: string;
  orderQuantity: number;
  statusTypeCd: string;
  orderDate: string;
  isLast?: boolean;
}

export interface IOrderDsd {
  orderNumber: string;
  orderQuantity: number;
  statusTypeCd: string;
  orderDate: string;
  scheduledDeliveryDate: string;
  isLast?: boolean;
}

export interface IBuyerComments {
  comment1?: string;
  comment2?: string;
  comment3?: string;
}

export interface IOrderHistory {
  invoiceNumber: string;
  orderedUnits: number;
  status: string;
  estimatedArrivalDate: string;
  isLast?: boolean;
}

export enum HISTORY_TAB {
  DC_ORDER = 0,
  STORE_ORDER = 1,
  BUYER_COMMENTS = 2,
}

export interface ReportInformation {
  [key: string]: string;
}

export interface TableSchema {
  [key: string]: string;
}

export const reportInformation: ReportInformation = {
  "Business Owner": "Amanda Martinez & Christopher Preli",
  "Data Freshness": "<2 hours",
  "Expected Business Users":
    "Replenishment VP & GVP & Director, Distribution VP, GVP, & GM, Director of Ops",
  Purpose: "Provide insight into the current health of DC and store inventory",
  "Version History": "1.0 (Current)  ||  Initial version",
};

export const kpiDefinition: Array<TableSchema> = [
  {
    KPI: "Total In-Stock Rate",
    Definition: "% of Store Inventory In Stock ",
    Formula:
      "Sum (4 weeks forward demand in selling units for CSC's with greater than > 0 units of inventory in stores) / Sum (4 weeks forward demand in selling units for ALL CSCs)",
  },
  {
    KPI: "Unit Count*",
    Definition: "Count of Inventory in a given inventory bucket",
    Formula: "Sum of Inventory units by Inventory Bucket",
  },
  {
    KPI: "Item Time Supply",
    Definition:
      "Expected days of supply of inventory in a given inventory bucket, based on forecasted demand",
    Formula:
      "(Total Selling Unit Count for a specific Inventory Bucket) / Avg. Daily 4-Week Forward Store Demand in selling units",
  },
  {
    KPI: "Days Of Sales On Hand",
    Definition:
      "Expected days of supply of inventory in a given inventory bucket, based on historical sales",
    Formula:
      "(Total Selling Unit Count for a specific Inventory Bucket) / Avg. Daily 8-Week Historical Sales in selling units",
  },
  // {
  //   KPI: "Average # of CSC's OOS",
  //   Definition: "The average number of CSC's OOS at each store",
  //   Formula: "Avg (Count of CSC's with 0 units in stores, by store)",
  // },
];

export const dataSource: Array<TableSchema> = [
  {
    Data: "Demand",
    Source: "Azure Data Lake",
  },
  {
    Data: "RetailSales",
    Source: "EDM",
  },
  {
    Data: "RetailStoreInventory",
    Source: "EDIS",
  },
  {
    Data: "Shipment",
    Source: "EDIS",
  },
  {
    Data: "StoreOrders",
    Source: "EDIS",
  },
  {
    Data: "InventoryBalance",
    Source: "EDIS",
  },
  {
    Data: "WarehouseTransfer",
    Source: "EDIS",
  },
  {
    Data: "InventoryAdjustment",
    Source: "EDIS",
  },
  {
    Data: "ReceiveDelivery",
    Source: "EDIS",
  },
  {
    Data: "DeliveryAppointment",
    Source: "EDIS",
  },
  {
    Data: "AdvancedShippingNotice",
    Source: "EDIS",
  },
  {
    Data: "PurchaseOrder",
    Source: "EDIS",
  },
];

export const kpiDefinitionDesr: Array<string> = [
  "On Order in Warehouse - Defins the Purchase Order sent to Vendors/Suppliers",
  "On Order in DSD - Defins the store Order sent from stores to Vendors/Suppliers",
];

export const reportConsiderations: Array<string> = [
  "Data includes only products currently managed in FaR",
  "A small portion (~1-2%) of products have incorrect CIC/CSC/UPC relationships, which may result in incorrect results when using the CSC or UPC filters",
  "Blue Yonder demand data is updated weekly on Monday afternoon",
];

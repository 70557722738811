import { PermissionState } from "../types/permission.types";

export const checkIfHasCameraPermission =
  async (): Promise<PermissionState> => {
    try {
      // @ts-ignore
      const permission = await navigator.permissions.query({ name: "camera" });

      return permission.state;
    } catch (error) {}

    return "granted";
  };

const DEFAULT_STEPS_TO_ENABLE_CAMERA = [
  "Click on the lock icon in the address bar.",
  "Click on Permissions.",
  "Find Camera and change it to Allow.",
  "Click 'Retry' to start the camera.",
];

export const STEPS_TO_ENABLE_CAMERA = {
  "Google Chrome": [DEFAULT_STEPS_TO_ENABLE_CAMERA],
  Opera: [DEFAULT_STEPS_TO_ENABLE_CAMERA],
  "Internet Explorer": [DEFAULT_STEPS_TO_ENABLE_CAMERA],
  "Microsoft Edge": [DEFAULT_STEPS_TO_ENABLE_CAMERA],
  Firefox: [
    [
      "Tap the Lock icon.",
      "Tap Clear cookies and site data.",
      "Click 'Retry' to to allow the camera permission prompt to reappear.",
    ],
  ],
  Safari: [
    [
      "Tap the 'aA' icon.",
      "Tap Website Settings",
      "Under the 'Allow [website name] to Access' section, tap Camera and select either Ask or Allow.",
      "Click 'Retry' to start the camera.",
    ],
  ],
  Unknown: [DEFAULT_STEPS_TO_ENABLE_CAMERA],
};

import { FC, useState, useEffect } from "react";
import { AppDispatch, RootState } from "../../../store";
import { connect } from "react-redux";
import {
  selectedValuesState,
  setDcStoreFilteredValues,
} from "../../../store/modules/orderHistory";
import { QuantityFilterSection, RenderFilterSection } from "./CommonFilter";
import { filterArray } from "./utils";
import { IDCStore } from "../../../types/order.types";

interface IDCStoreFilterProps {
  dcStoreData: IDCStore[];
  dcStoreFilteredData: IDCStore[];
  setDcStoreFilteredValues: (value: IDCStore[]) => void;
  selectedvalues: any;
  selectedValuesState: (data: any) => void;
}

export enum FILTER {
  NONE = "none",
  ARRIVAL_DATE = "dcEstimatedArrivalDate",
  PO_NUMBER = "poNumber",
  ORDER_QTY = "orderQuantity",
  STATUS = "status",
}

const DcStoreFilter: FC<IDCStoreFilterProps> = (props) => {
  const {
    dcStoreData,
    dcStoreFilteredData,
    setDcStoreFilteredValues,
    selectedvalues,
    selectedValuesState,
  } = props;

  const [expandedFilterIdx, setExpandedFilterIdx] = useState<FILTER>(
    FILTER.NONE
  );
  const [radioValue, setRadioValue] = useState<string>(
    selectedvalues.radioValue
  ); //selectedRadioValue options

  const [selectedPOs, setSelectedPOs] = useState<string[]>(
    selectedvalues.selectedPOs
  ); //po's array
  const [selectedDates, setSelectedDates] = useState<string[]>(
    selectedvalues.selectedDates
  ); //dates array
  const [selectedStatus, setSelectedStatus] = useState<string[]>(
    selectedvalues.selectedStatus
  ); //status array

  const [quantityValueFirstSelect, setQuantityValueFirstSelect] = useState(
    selectedvalues.quantityValueFirstSelect
  ); //quantity section first input field
  const [quantityValueSecondSelect, setQuantityValueSecondSelect] = useState(
    selectedvalues.quantityValueSecondSelect
  ); //quantity section second input field

  const [quantityValueFirstValue, setQuantityValueFirstValue] = useState(
    selectedvalues.quantityValueFirstValue
  ); //quantity section first input field
  const [quantityValueSecondValue, setQuantityValueSecondValue] = useState(
    selectedvalues.quantityValueSecondValue
  ); //quantity section second input field

  const [poNumberData, setPoNumberData] = useState<string[]>([]); //poNumbers from table data
  const [status, setStatus] = useState<string[]>([]); //status from table data
  const [dcDates, setDcDates] = useState<string[]>([]); //dcDates from table data

  const changeExpandedFilterIdx = (idx: FILTER) => {
    setExpandedFilterIdx((prev) => (prev === idx ? FILTER.NONE : idx));
  };

  const updateFilterValues = () => {
    const updatedDcOrderData =
      selectedvalues.quantityValueFirstSelect?.value ||
      selectedvalues.quantityValueSecondSelect?.value
        ? (filterArray(
            dcStoreData,
            selectedvalues.quantityValueFirstValue,
            selectedvalues.quantityValueSecondValue,
            selectedvalues.radioValue,
            selectedvalues.quantityValueFirstSelect.value,
            selectedvalues.quantityValueSecondSelect.value
          ) as IDCStore[])
        : dcStoreData;

    const uniquePoNumbers = updatedDcOrderData
      .filter(
        (item) =>
          selectedStatus.length === 0 || selectedStatus.includes(item.status)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 ||
          selectedDates.includes(item.dcEstimatedArrivalDate)
      )
      .map((item) => item.poNumber)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setPoNumberData(uniquePoNumbers);

    const uniqueStatus = updatedDcOrderData
      .filter(
        (item) =>
          selectedPOs.length === 0 || selectedPOs.includes(item.poNumber)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 ||
          selectedDates.includes(item.dcEstimatedArrivalDate)
      )
      .map((item) => item.status)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setStatus(uniqueStatus);

    const uniqueDates = updatedDcOrderData
      .filter(
        (item) =>
          selectedPOs.length === 0 || selectedPOs.includes(item.poNumber)
      )
      .filter(
        (item) =>
          selectedStatus.length === 0 || selectedStatus.includes(item.status)
      )
      .map((item) => item.dcEstimatedArrivalDate)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
    setDcDates(uniqueDates);
  };

  const filterTableData = () => {
    selectedValuesState({
      selectedPOs,
      selectedDates,
      selectedStatus,
      quantityValueFirstSelect,
      quantityValueFirstValue,
      quantityValueSecondSelect,
      quantityValueSecondValue,
      radioValue,
    });

    const fiteredDcStoreData = dcStoreData
      .filter(
        (item) =>
          selectedPOs.length === 0 || selectedPOs.includes(item.poNumber)
      )
      .filter(
        (item) =>
          selectedStatus.length === 0 || selectedStatus.includes(item.status)
      )
      .filter(
        (item) =>
          selectedDates.length === 0 ||
          selectedDates.includes(item.dcEstimatedArrivalDate)
      );

    if (quantityValueFirstSelect?.value || quantityValueSecondSelect?.value) {
      const updatedDcOrderData = filterArray(
        fiteredDcStoreData,
        quantityValueFirstValue,
        quantityValueSecondValue,
        radioValue,
        quantityValueFirstSelect.value,
        quantityValueSecondSelect.value
      ) as IDCStore[];
      setDcStoreFilteredValues(updatedDcOrderData);
    } else {
      setDcStoreFilteredValues(fiteredDcStoreData);
    }
    setExpandedFilterIdx(FILTER.NONE);
  };

  useEffect(() => {
    updateFilterValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dcStoreFilteredData]);

  return (
    <div className="mx-2">
      <div className="my-4">
        Filters on <b> DC Store</b> Visual
      </div>
      {[FILTER.NONE, FILTER.ARRIVAL_DATE].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="DC Est/Arrival Date"
          type="date"
          data={dcDates}
          selectedArray={selectedDates}
          setSelectedArray={setSelectedDates}
          filterType={FILTER.ARRIVAL_DATE}
          isExpanded={expandedFilterIdx === FILTER.ARRIVAL_DATE}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.PO_NUMBER].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="PO_NUMBER"
          data={poNumberData}
          selectedArray={selectedPOs}
          setSelectedArray={setSelectedPOs}
          filterType={FILTER.PO_NUMBER}
          isExpanded={expandedFilterIdx === FILTER.PO_NUMBER}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.ORDER_QTY].includes(expandedFilterIdx) && (
        <QuantityFilterSection<FILTER>
          title="ORDER_QTY"
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          inputValueFirst={quantityValueFirstValue}
          setInputValueFirst={setQuantityValueFirstValue}
          inputValueSecond={quantityValueSecondValue}
          setInputValueSecond={setQuantityValueSecondValue}
          quantityObjectFirst={quantityValueFirstSelect}
          handleFirstQuantitySelectChange={setQuantityValueFirstSelect}
          quantityObjectSecond={quantityValueSecondSelect}
          handleSecondQuantitySelectChange={setQuantityValueSecondSelect}
          filterType={FILTER.ORDER_QTY}
          isExpanded={expandedFilterIdx === FILTER.ORDER_QTY}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
      {[FILTER.NONE, FILTER.STATUS].includes(expandedFilterIdx) && (
        <RenderFilterSection<FILTER>
          title="STATUS"
          data={status}
          selectedArray={selectedStatus}
          setSelectedArray={setSelectedStatus}
          filterType={FILTER.STATUS}
          isExpanded={expandedFilterIdx === FILTER.STATUS}
          changeExpandedFilterIdx={changeExpandedFilterIdx}
          filterTableData={filterTableData}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setDcStoreFilteredValues: (data: IDCStore[]) =>
    dispatch(setDcStoreFilteredValues(data)),
  selectedValuesState: (data: any) => dispatch(selectedValuesState(data)),
});

const mapStateToProps = (state: RootState) => ({
  dcStoreData: state.orderHistory.dcStoreData,
  filteredTotal: state.orderHistory.filteredTotal,
  selectedvalues: state.orderHistory.selectedvalues,
  dcStoreFilteredData: state.orderHistory.dcStoreFilteredData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DcStoreFilter);

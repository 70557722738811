import { FC, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import clsx from "clsx";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import Table from "@albertsons/uds/molecule/Table";
import {
  fetchUnitsData,
  initDashboardState,
  transformFilterOptions,
} from "../../store/modules/dashboard";
import { fetchInvoiceReceiptsResearchData } from "../../store/modules/invoiceReceipts";
import {
  fetchOrderHistoryDsdData,
  fetchOrderHistoryDsdTotalQuantity,
} from "../../store/modules/dsdOrderHistory";
import { monitorViewReport } from "../../store/modules/monitor";
import { monitorBarcodeScanUsage } from "../../store/modules/monitor";
import { TabHeader, ButtonGroup } from "../../components";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import DesktopToolbar from "../../components/Report/DesktopToolbar";
import ReportHeader from "../../components/Report/ReportHeader";
import Filters from "../../components/Report/Filters";
import Cards from "../../components/Report/Cards";
import DSDOrderHistory from "../../components/Report/DSDOrderHistory";
import FloatingMenu from "../../components/Report/FloatingMenu";
import BarcodeScanner from "../../components/Report/Scanner";
import Annotate from "../../components/Report/Annotate";
import CategoryToggle from "../../components/CategoryToggle";
import {
  getTotal,
  getValues,
  getDesktopTableColumns,
  getMobileTableColumns,
  CARDS,
} from "./utils";
import { unitTabs } from "../../constants/units";
import { blockBodyScroll, releaseBodyScroll } from "../../utils/blockScrolling";
import { getBrowser, isMobile } from "../../utils/isMobile";
import { canMonitor } from "../../utils/app-env";
import { clipboardCheck } from "../../assets";
import { IMonitorViewReport, IBarcodeUsage } from "../../types/monitor.types";
import { InvoiceReceiptsResearchCards } from "../../types/cards.types";
import { IUnitsData, IUnitsTotalData } from "../../types/data.types";
import { IFilterOptions } from "../../types/filters.types";
import { BarcodeValue } from "../../types/barcode.types";
import "./styles.css";

const currrentTime = performance.now();

interface DSDnonFreshReportProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  canScanBarcode?: boolean;
  isInitialized: boolean;
  selectedFilterOptions: IFilterOptions;
  sellingUnitsData: IUnitsData;
  shippingUnitsData: IUnitsData;
  vendorUnitsData: IUnitsData;
  totalWarehouseData: IUnitsTotalData;
  totalStoreData: IUnitsTotalData;
  invoiceReceiptsResearchData: InvoiceReceiptsResearchCards | null;
  refreshDataKey: number;
  isUnitsDataLoading: number;
  isLoadingInvoiceReceiptsResearchCards: boolean;
  isLoadedOrderHistoryData: boolean;
  isTrackedViewReport: boolean;
  initDashboardState: () => void;
  fetchUnitsData: (filters: IFilterOptions) => void;
  fetchOrderHistoryDsdData: (filters: IFilterOptions) => void;
  fetchOrderHistoryDsdTotalQuantity: (filters: IFilterOptions) => void;
  fetchInvoiceReceiptsResearchData: (filters: IFilterOptions) => void;
  monitorViewReport: (data: IMonitorViewReport) => void;
  monitorBarcodeScanUsage: (data: IBarcodeUsage) => void;
}

const DSDnonFreshReport: FC<DSDnonFreshReportProps> = (props) => {
  const {
    forceScreen,
    canScanBarcode = true,
    isInitialized,
    selectedFilterOptions,
    isUnitsDataLoading,
    sellingUnitsData,
    shippingUnitsData,
    vendorUnitsData,
    totalWarehouseData,
    totalStoreData,
    invoiceReceiptsResearchData,
    refreshDataKey,
    isLoadingInvoiceReceiptsResearchCards,
    isLoadedOrderHistoryData,
    isTrackedViewReport,
    initDashboardState,
    fetchUnitsData,
    fetchOrderHistoryDsdData,
    fetchOrderHistoryDsdTotalQuantity,
    fetchInvoiceReceiptsResearchData,
    monitorViewReport,
    monitorBarcodeScanUsage,
  } = props;

  const [selectedUnitTab, setSelectedUnitTab] = useState<0 | 1 | 2>(0);
  const [showAnnotateModal, setShowAnnotateModal] = useState(false);
  const [showBarcodeModal, setShowBarcodeMadal] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState<BarcodeValue | null>(null);

  const prevFiltersRef = useRef("");
  const mainScreenContentRef = useRef<HTMLDivElement>(null);

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const { accounts } = useMsal();
  const isActualMobile = isMobile();
  const browser = getBrowser();

  const isLoading =
    !isInitialized ||
    isUnitsDataLoading > 0 ||
    isLoadingInvoiceReceiptsResearchCards ||
    !isLoadedOrderHistoryData;

  const reportName = "Current Inventory Health Summary";

  const unitsData = useMemo(() => {
    switch (selectedUnitTab) {
      case 0:
        return sellingUnitsData;
      case 1:
        return shippingUnitsData;
      case 2:
        return vendorUnitsData;
      default:
        return {};
    }
  }, [selectedUnitTab, sellingUnitsData, shippingUnitsData, vendorUnitsData]);

  const openAnnotateModal = () => {
    setShowAnnotateModal(true);
    blockBodyScroll();
  };

  const closeAnnotateModal = () => {
    setShowAnnotateModal(false);
    releaseBodyScroll();
  };

  const resetBarcodeValue = () => {
    setBarcodeValue(null);
  };

  const openBarcodeModal = () => {
    setShowBarcodeMadal(true);
    blockBodyScroll();
  };

  const closeBarcodeModal = () => {
    setShowBarcodeMadal(false);
    releaseBodyScroll();
  };

  const updateBarcodeValue = (barcode: BarcodeValue) => {
    const { name, username } = accounts[0];
    monitorBarcodeScanUsage({
      name: name ?? "",
      email: username,
      type: barcode.type,
      scannedvalue: barcode.data,
      deviceid: localStorage.getItem("scandit-device-id") ?? "",
      monitorid: sessionStorage.getItem("monitorId") ?? "",
    });
    setBarcodeValue(barcode);
    closeBarcodeModal();
  };

  const fetchOrderHistoryData = () => {
    fetchOrderHistoryDsdData(selectedFilterOptions);
    fetchOrderHistoryDsdTotalQuantity(selectedFilterOptions);
  };

  const applyFilter = () => {
    fetchUnitsData(selectedFilterOptions);
    fetchOrderHistoryData();
    fetchInvoiceReceiptsResearchData(selectedFilterOptions);
  };

  useEffect(() => {
    if (isInitialized) {
      const newFilters = JSON.stringify(
        transformFilterOptions(selectedFilterOptions)
      );
      if (newFilters !== prevFiltersRef.current) {
        applyFilter();
        prevFiltersRef.current = newFilters;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOptions, selectedUnitTab, isInitialized]);

  useEffect(() => {
    if (canMonitor && !isTrackedViewReport && !isLoading) {
      const { name, username } = accounts[0];
      const loadingTime = performance.now() - currrentTime;
      monitorViewReport({
        name: name ?? "",
        email: username,
        loading_time: Math.round(loadingTime / 1000),
        platform: isActualMobile ? "Mobile" : "Desktop",
        browser,
        report: "IMPACT mView Dashboard",
        page: reportName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackedViewReport, isLoading]);

  useEffect(() => {
    if (refreshDataKey) {
      applyFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataKey]);

  useEffect(() => {
    initDashboardState();
    document.title = `${reportName} | IMPACT mVIEW Dashboard`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableRows = useCallback((): any => {
    if (screen.small) {
      return [
        {
          id: 0,
          label: "DSD Order",
          units: unitsData.onOrder?.unitCount,
          doh: unitsData.onOrder?.daysOfSOH,
          its: unitsData.onOrder?.itemTimeSupply,
        },
        {
          id: 1,
          label: "In Transit to Store",
        },
        {
          id: 2,
          label: "Store Inventory",
          units: unitsData.storeInventory?.unitCount,
          doh: unitsData.storeInventory?.daysOfSOH,
          its: unitsData.storeInventory?.itemTimeSupply,
        },
        {
          id: 3,
          label: "Total",
          units: getTotal(getValues(unitsData, "unitCount")),
          doh: getTotal(getValues(unitsData, "daysOfSOH"), {
            totalWarehouseData,
            totalStoreData,
            column: "doh",
            tab: selectedUnitTab,
          }),
          its: getTotal(getValues(unitsData, "itemTimeSupply"), {
            totalWarehouseData,
            totalStoreData,
            column: "its",
            tab: selectedUnitTab,
          }),
          className: "font-semibold",
        },
      ];
    }
    return [
      {
        id: 0,
        label: unitTabs[selectedUnitTab].label,
        ...getValues(unitsData, "unitCount"),
        total: getTotal(getValues(unitsData, "unitCount")),
        isLoading: isUnitsDataLoading,
      },
      {
        id: 1,
        label: "Day of Sales on Hand",
        ...getValues(unitsData, "daysOfSOH"),
        total: getTotal(getValues(unitsData, "daysOfSOH"), {
          totalWarehouseData,
          totalStoreData,
          column: "doh",
          tab: selectedUnitTab,
        }),
        isLoading: isUnitsDataLoading,
      },
      {
        id: 2,
        label: "Item Time Supply",
        ...getValues(unitsData, "itemTimeSupply"),
        total: getTotal(getValues(unitsData, "itemTimeSupply"), {
          totalWarehouseData,
          totalStoreData,
          column: "its",
          tab: selectedUnitTab,
        }),
        isLoading: isUnitsDataLoading,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUnitsDataLoading,
    unitsData,
    totalWarehouseData,
    totalStoreData,
    screen,
    selectedUnitTab,
  ]);

  const getTableColumns = useCallback(() => {
    if (screen.small)
      return getMobileTableColumns(
        unitTabs[selectedUnitTab].label,
        screen.extraSmall,
        isUnitsDataLoading
      );
    return getDesktopTableColumns();
  }, [isUnitsDataLoading, screen, selectedUnitTab]);

  return (
    <div className="relative">
      <div className="row-col-reverse" />
      <div ref={mainScreenContentRef}>
        {screen.large && <DesktopToolbar isLoading={isLoading} />}
        <div
          className={clsx(screen.small && !showAnnotateModal && "mb-[80px]")}
        >
          <ReportHeader
            forceScreen={forceScreen}
            reportName={reportName}
            showMviewLabel
            showDateTimeFetched
            showAboutLink
            showCategoryToggle
            sectionLabel="Cross Network Health"
          />
          <div className={clsx("flex", screen.small && "flex-col")}>
            <div className={clsx(screen.small && "px-2 pb-3")}>
              <Filters
                showDSDVendor
                showFullUPC
                forceScreen={forceScreen}
                barcodeValue={barcodeValue}
                resetBarcodeValue={resetBarcodeValue}
              />
            </div>
            <div className={clsx(screen.large ? "px-4" : "px-2 pb-6")}>
              {screen.large ? (
                <div className="flex pr-5">
                  <img
                    src={clipboardCheck}
                    alt="clipboard-icon"
                    className="mt-2.5 h-[28px]"
                  />
                  <span className="text-[#004181] font-bold text-lg flex items-center font-Arial pl-2">
                    {reportName}
                  </span>
                  <div className="ml-auto mt-1.5 pr-2">
                    <CategoryToggle />
                  </div>
                  <ButtonGroup
                    title="Unit Toggle:"
                    buttons={unitTabs}
                    value={selectedUnitTab}
                    setValue={(value) => setSelectedUnitTab(value as 0 | 1 | 2)}
                  />
                </div>
              ) : (
                <ButtonGroup
                  title="Unit Toggle:"
                  buttons={unitTabs}
                  value={selectedUnitTab}
                  setValue={(value) => setSelectedUnitTab(value as 0 | 1 | 2)}
                />
              )}
              {screen.large && <TabHeader title="Cross Network Health" />}
              <Table
                id="units-table"
                itemKey="id"
                items={getTableRows()}
                columns={getTableColumns()}
                noHeader
                noPagination
                dividers
                className={clsx(
                  "units-table",
                  screen.small ? "small-table" : "large-table"
                )}
              />
              <p className="text-sm pt-2">
                <span className="text-red-500">*</span>
                ITS is available only for items that have FaR forecast
              </p>
              <p className="text-sm pt-2">
                <span className="text-red-500">*</span>
                No In-Transit to Store data for DSD at this time
              </p>
              <Cards<InvoiceReceiptsResearchCards>
                forceScreen={forceScreen}
                sectionLabel="Invoice/Receipt Research"
                cards={CARDS}
                isLoading={isLoadingInvoiceReceiptsResearchCards}
                researchData={invoiceReceiptsResearchData}
              />
              <DSDOrderHistory />
            </div>
          </div>
        </div>
        {screen.small && (
          <FloatingMenu
            forceScreen={forceScreen}
            canScanBarcode={canScanBarcode}
            isAnnotating={showAnnotateModal}
            openAnnotateModal={openAnnotateModal}
            openBarcodeModal={openBarcodeModal}
          />
        )}
        {canScanBarcode && isInitialized && (
          <BarcodeScanner
            isOn={showBarcodeModal}
            updateBarcodeValue={updateBarcodeValue}
            closeBarcodeModal={closeBarcodeModal}
          />
        )}
      </div>
      <Annotate
        showModal={showAnnotateModal}
        onClose={closeAnnotateModal}
        contentRef={mainScreenContentRef}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isInitialized: state.dashboard.isStateInitialized,
  selectedFilterOptions: state.dashboard.selectedFilterOptions,
  sellingUnitsData: state.dashboard.sellingUnitsData,
  shippingUnitsData: state.dashboard.shippingUnitsData,
  vendorUnitsData: state.dashboard.vendorUnitsData,
  totalWarehouseData: state.dashboard.totalWarehouseData,
  totalStoreData: state.dashboard.totalStoreData,
  refreshDataKey: state.dashboard.refreshDataKey,
  isUnitsDataLoading: state.dashboard.isUnitsDataLoading,
  isLoadingInvoiceReceiptsResearchCards:
    state.invoiceReceipts.isLoadingInvoiceReceiptsResearchCards,
  invoiceReceiptsResearchData:
    state.invoiceReceipts.invoiceReceiptsResearchData,
  isLoadedOrderHistoryData: state.dsdOrderHistory.isLoadedOrderHistoryData,
  isTrackedViewReport: state.monitor.isTrackedViewReport,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  initDashboardState: () => dispatch(initDashboardState()),
  fetchUnitsData: (filters: IFilterOptions) =>
    dispatch(fetchUnitsData(filters)),
  fetchOrderHistoryDsdData: (filters: IFilterOptions) =>
    dispatch(fetchOrderHistoryDsdData(filters)),
  fetchOrderHistoryDsdTotalQuantity: (filters: IFilterOptions) =>
    dispatch(fetchOrderHistoryDsdTotalQuantity(filters)),
  fetchInvoiceReceiptsResearchData: (filters: IFilterOptions) =>
    dispatch(fetchInvoiceReceiptsResearchData(filters)),
  monitorViewReport: (data: IMonitorViewReport) =>
    dispatch(monitorViewReport(data)),
  monitorBarcodeScanUsage: (data: IBarcodeUsage) =>
    dispatch(monitorBarcodeScanUsage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DSDnonFreshReport);

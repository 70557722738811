// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-monitoring-table.large-table {
  max-height: calc(100vh - 225px);
}

.user-monitoring-table.small-table {
  max-height: 300px;
}

.user-monitoring-table thead {
  position: sticky;
  top: -1px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/userMonitoring/styles.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".user-monitoring-table.large-table {\n  max-height: calc(100vh - 225px);\n}\n\n.user-monitoring-table.small-table {\n  max-height: 300px;\n}\n\n.user-monitoring-table thead {\n  position: sticky;\n  top: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

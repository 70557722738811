import { FC } from "react";
import Button from "@albertsons/uds/molecule/Button";
import { getBrowser, isMobile } from "../../../../../utils/isMobile";
import { STEPS_TO_ENABLE_CAMERA } from "../../../../../utils/permissions";

interface CameraPermissionDeniedProps {
  onRetry: () => void;
}

const CameraPermissionDenied: FC<CameraPermissionDeniedProps> = (props) => {
  const { onRetry } = props;

  const browser = isMobile() ? getBrowser() : "Unknown";

  return (
    <>
      <p className="font-semibold mb-5 text-base">Camera Permission Denied!</p>
      <p className="text-base font-semibold mb-2.5">
        To enable camera permissions on browser:
      </p>
      {STEPS_TO_ENABLE_CAMERA[browser].map((steps, index) => (
        <div className="mb-2.5">
          {steps.map((step, stepIdx) => (
            <div className="flex gap-1">
              <span className="text-sm min-w-5 min-h-5 w-5 h-5 my-0.5 text-white bg-[#1b6ebb] rounded-full flex items-center justify-center">
                {stepIdx + 1}
              </span>
              <span>{step}</span>
            </div>
          ))}
          {index !== STEPS_TO_ENABLE_CAMERA[browser].length - 1 && (
            <p className="text-base font-semibold mt-2.5">OR</p>
          )}
        </div>
      ))}
      <div className="flex justify-end">
        <Button
          id="camera-retry-button"
          fixed
          variant="tertiary"
          onClick={onRetry}
          className="!w-20"
        >
          Retry
        </Button>
        <Button
          id="camera-issue-report-button"
          fixed
          variant="tertiary"
          onClick={() =>
            window.open(`mailto:IMPACT.Support@albertsons.com?subject=IMPACT mView - Report Issue&body=Report Name: %0D%0AReport Link: 
          %0D%0AIssue Type (data quality, report not refreshed, etc…): %0D%0AIssue Description: %0D%0AIssue Example (include screenshot): `)
          }
          className="!w-[140px]"
        >
          Report an issue
        </Button>
      </div>
    </>
  );
};

export default CameraPermissionDenied;

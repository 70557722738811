// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/inventoryLogo.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/impactLogo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inventory-logo {
    height: 47.8824px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-size: contain;
    background-position: center;
    cursor: inherit;
}
.impact-logo {
    height: 31.8824px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat;
    cursor: inherit;
    background-position: center;
    background-size: 152px 39px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/userMonitoring/components/MonitoringHeader/styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,6DAAiE;IACjE,wBAAwB;IACxB,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,6DAA8D;IAC9D,eAAe;IACf,2BAA2B;IAC3B,2BAA2B;AAC/B","sourcesContent":[".inventory-logo {\n    height: 47.8824px;\n    background: url(\"../../../../assets/inventoryLogo.png\") no-repeat;\n    background-size: contain;\n    background-position: center;\n    cursor: inherit;\n}\n.impact-logo {\n    height: 31.8824px;\n    background: url(\"../../../../assets/impactLogo.png\") no-repeat;\n    cursor: inherit;\n    background-position: center;\n    background-size: 152px 39px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

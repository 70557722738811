// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.units-table.large-table {
  padding: 5px;
}
.units-table.large-table table {
  border-collapse: separate;
  table-layout: fixed;
}
.units-table table thead tr {
  background-color: transparent !important;
}
.units-table.large-table table thead tr,
.units-table.large-table table tbody tr {
  height: 40px !important;
}
.units-table.large-table table thead tr th {
  background-color: #717a90;
  border: 1px solid #717a90;
}
.units-table.small-table table thead tr th {
  background-color: #b7bcc7;
  border: 1px solid #b7bcc7;
}
.units-table table thead tr th:first-child {
  background-color: transparent;
  border-color: transparent;
  text-align: center;
}
.units-table table tbody tr td {
  background-color: #f8f8f9;
  border: 1px solid #dadce2;
}
.units-table.large-table table tbody tr td:first-child {
  background-color: #b7bcc7;
  border-color: #b7bcc7;
}
.units-table.small-table table tbody tr td:first-child {
  background-color: #717a90;
  border: 1px solid #717a90;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/warehouseFARReport/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,wCAAwC;AAC1C;AACA;;EAEE,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".units-table.large-table {\n  padding: 5px;\n}\n.units-table.large-table table {\n  border-collapse: separate;\n  table-layout: fixed;\n}\n.units-table table thead tr {\n  background-color: transparent !important;\n}\n.units-table.large-table table thead tr,\n.units-table.large-table table tbody tr {\n  height: 40px !important;\n}\n.units-table.large-table table thead tr th {\n  background-color: #717a90;\n  border: 1px solid #717a90;\n}\n.units-table.small-table table thead tr th {\n  background-color: #b7bcc7;\n  border: 1px solid #b7bcc7;\n}\n.units-table table thead tr th:first-child {\n  background-color: transparent;\n  border-color: transparent;\n  text-align: center;\n}\n.units-table table tbody tr td {\n  background-color: #f8f8f9;\n  border: 1px solid #dadce2;\n}\n.units-table.large-table table tbody tr td:first-child {\n  background-color: #b7bcc7;\n  border-color: #b7bcc7;\n}\n.units-table.small-table table tbody tr td:first-child {\n  background-color: #717a90;\n  border: 1px solid #717a90;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { FC, useEffect, ComponentType } from "react";
import { connect } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { AppDispatch, RootState } from "../store";
import { getAccessToken } from "../store/modules/auth";
import { isTestingAPIEnv, isTestingEnv } from "../utils/app-env";

interface RestrictedRouteProps {
  Element: ComponentType;
  accessToken: string;
  getAccessToken: () => void;
}

const RestrictedRoute: FC<RestrictedRouteProps> = (props) => {
  const { Element, accessToken, getAccessToken } = props;

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (!isTestingEnv) {
      if (
        !isTestingAPIEnv &&
        !isAuthenticated &&
        inProgress === InteractionStatus.None
      ) {
        const account = instance.getActiveAccount();
        if (!account) {
          instance.loginRedirect({
            scopes: ["https://analysis.windows.net/powerbi/api/.default"],
            claims: JSON.stringify({
              access_token: { xms_cc: { values: ["CP1"] } },
            }),
          });
        }
      }
      if (
        (isTestingAPIEnv || isAuthenticated) &&
        inProgress === InteractionStatus.None &&
        !accessToken
      ) {
        getAccessToken();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  if (
    !isTestingEnv &&
    ((!isTestingAPIEnv && !isAuthenticated) || !accessToken)
  ) {
    return null;
  }

  return <Element />;
};

const mapStateToProps = (state: RootState) => ({
  accessToken: state.auth.accessToken,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAccessToken: () => dispatch(getAccessToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedRoute);

import { FC } from "react";
import { X as CloseIcon } from "lucide-react";
import Modal from "@albertsons/uds/molecule/Modal";
import "./styles.css";

interface ReportPopupProps {
  isOpen: boolean;
  closeHandler: () => void;
}

const ReportIssueModal: FC<ReportPopupProps> = (props) => {
  const { isOpen, closeHandler } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      minHeight={20}
      minWidth={700}
      maxWidth={700}
      className="bg-white overflow-hidden !rounded-lg"
    >
      {({ close }) => (
        <div data-testid="report-modal">
          <div className="flex h-12 p-2 bg-neutral-100">
            <div className="flex text-centeritems-center justify-center font-bold w-full px-3">
              <p>Report Issue</p>
              <span
                className="ml-auto flex text-center items-center justify-center cursor-pointer"
                data-testid="report-issue-close-button"
                onClick={close}
              >
                <CloseIcon />
              </span>
            </div>
          </div>

          <div className="relative p-5 tollbar-Report text-[18px] font-semibold text-center">
            <p>
              For report issues with IMPACT mView, please email the following
              details to
              <p>
                IMPACT Support at{" "}
                <a
                  className="underline report-Link"
                  href="mailto:IMPACT.Support@albertsons.com?subject=IMPACT mView - Report Issue&body=Report Name: %0D%0AReport Link: 
                      %0D%0AIssue Type (data quality, report not refreshed, etc…): %0D%0AIssue Description: %0D%0AIssue Example (include screenshot): "
                >
                  IMPACT.Support@albertsons.com
                </a>{" "}
                for assistance:
              </p>
            </p>
            <br />
            <p>- Report Name</p>
            <p>- Report Link</p>
            <p>- Issue Type (data quality, report not refreshed, etc…)</p>
            <p>- Issue Description</p>
            <p>- Issue Example (include screenshot)</p>
            <br />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ReportIssueModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tollbar-Report {
  font-family: "Segoe UI Bold", wf_segoe-ui_bold, helvetica, arial, sans-serif;
  color: rgb(51, 86, 147);
}

.report-Link {
  color: rgb(17, 141, 255);
}

.copy-animation {
  transition: all;
  transform: scale(0.7);
  animation: copyAnimation 0.4s ease-in-out forwards;
}

@keyframes copyAnimation {
  0% {
    transform: scale(0.7);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Report/ToolbarSections/styles.css"],"names":[],"mappings":"AAAA;EACE,4EAA4E;EAC5E,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,kDAAkD;AACpD;;AAEA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".tollbar-Report {\n  font-family: \"Segoe UI Bold\", wf_segoe-ui_bold, helvetica, arial, sans-serif;\n  color: rgb(51, 86, 147);\n}\n\n.report-Link {\n  color: rgb(17, 141, 255);\n}\n\n.copy-animation {\n  transition: all;\n  transform: scale(0.7);\n  animation: copyAnimation 0.4s ease-in-out forwards;\n}\n\n@keyframes copyAnimation {\n  0% {\n    transform: scale(0.7);\n  }\n  70% {\n    transform: scale(1.1);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import axios from "../../lib/axios";
import checkIsLocalhost from "../../utils/checkIsLocalhost";
import { CATEGORY } from "../../types/category.types";
import { BarcodeType } from "../../types/barcode.types";
import { IFilter } from "../../types/filters.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL || "/";
const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;

export const getWarehouseKPIData = (
  filters: IFilter,
  isFar: boolean
): Promise<any> => {
  const isLocalhost = checkIsLocalhost();

  const headers = {
    ...(isLocalhost
      ? {}
      : {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
          "Content-Type": "text/plain",
        }),
  };

  const raw = `{
    getWarehouseKPIValues(ImpactInput: {CIC: "${filters.cic}", Warehouse: ${filters.warehouse}, DCDivision: ${filters.warehouseDivision}, DistributionCenter: ${filters.distribution}, RetailDivision: ${filters.retailDivision}, RetailStore: ${filters.retailStore}, Department: ${filters.department}, RetailSection: ${filters.retailSection}, SMIC: ${filters.smic}, UPC: ${filters.upc}}, FarDataRequired: ${isFar})
    {
      onOrderSellingUnits {unitCount daysOfSOH itemTimeSupply}
      inTransitToDcSellingUnits {unitCount daysOfSOH itemTimeSupply}
      dcInventorySellingUnits {unitCount daysOfSOH itemTimeSupply}

      onOrderShippingUnits {unitCount daysOfSOH itemTimeSupply}
      inTransitToDcShippingUnits {unitCount daysOfSOH itemTimeSupply}
      dcInventoryShippingUnits {unitCount daysOfSOH itemTimeSupply}

      onOrderVendorUnits {unitCount daysOfSOH itemTimeSupply}
      inTransitToDcVendorUnits {unitCount daysOfSOH itemTimeSupply}
      dcInventoryVendorUnits {unitCount daysOfSOH itemTimeSupply}

      fourWeekFrwdDemandSellingUnits
      eightWeekHstrSelnSellingUnits

      fourWeekFrwdDemandShippingUnits
      eightWeekHstrSelnShippingUnits

      fourWeekFrwdDemandVendorUnits
      eightWeekHstrSelnVendorUnits

      errors { errorCode errorDescription }
    }
  }`;
  const url = `${baseUrl}sdiminventory/cross-network-health`;

  return axios.post(
    isLocalhost ? "/api/middleware" : url,
    isLocalhost ? { raw, url } : raw,
    { headers }
  );
};

export const getWarehouseKPIDsdValues = (filters: IFilter): Promise<any> => {
  const isLocalhost = checkIsLocalhost();

  const headers = {
    ...(isLocalhost
      ? {}
      : {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
          "Content-Type": "text/plain",
        }),
  };

  const raw = `{
    getWarehouseKPIDsdValues(ImpactInput: {DSDVendor: ${filters.dsdVendor}, CIC: "${filters.cic}", RetailDivision: ${filters.retailDivision}, RetailStore: ${filters.retailStore}, Department: ${filters.department}, RetailSection: ${filters.retailSection}, SMIC: ${filters.smic}, UPC: ${filters.upc}}, FarDataRequired: true)
    {
      storeOnOrderSellingUnits {unitCount daysOfSOH itemTimeSupply}

      storeOnOrderShippingUnits {unitCount daysOfSOH itemTimeSupply}

      storeOnOrderVendorUnits {unitCount daysOfSOH itemTimeSupply}

      fourWeekFrwdDemandSellingUnits
      eightWeekHstrSelnSellingUnits

      fourWeekFrwdDemandShippingUnits
      eightWeekHstrSelnShippingUnits

      fourWeekFrwdDemandVendorUnits
      eightWeekHstrSelnVendorUnits

      errors { errorCode errorDescription }
    }
  }`;
  const url = `${baseUrl}sdiminventory/cross-network-health`;

  return axios.post(
    isLocalhost ? "/api/middleware" : url,
    isLocalhost ? { raw, url } : raw,
    { headers }
  );
};

/* istanbul ignore next */
export const getStoreKPIData = (
  filters: IFilter,
  isFar: boolean
): Promise<any> => {
  const isLocalhost = checkIsLocalhost();

  const headers = {
    ...(isLocalhost
      ? {}
      : {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
          "Content-Type": "text/plain",
        }),
  };

  const raw = `{
    getStoreKPIValues(ImpactInput: {CIC: "${filters.cic}", Warehouse: ${filters.warehouse}, DCDivision: ${filters.warehouseDivision}, DistributionCenter: ${filters.distribution}, RetailDivision: ${filters.retailDivision}, RetailStore: ${filters.retailStore}, Department: ${filters.department}, RetailSection: ${filters.retailSection}, SMIC: ${filters.smic}, UPC: ${filters.upc}}, FarDataRequired: ${isFar})
    {
      inTransitToStoreSellingUnits {unitCount daysOfSOH itemTimeSupply}
      storeInventorySellingUnits {unitCount daysOfSOH itemTimeSupply}

      inTransitToStoreShippingUnits {unitCount daysOfSOH itemTimeSupply}
      storeInventoryShippingUnits {unitCount daysOfSOH itemTimeSupply}

      inTransitToStoreVendorUnits {unitCount daysOfSOH itemTimeSupply}
      storeInventoryVendorUnits {unitCount daysOfSOH itemTimeSupply}

      fourWeekFrwdDemandSellingUnits
      eightWeekHstrSelnSellingUnits

      fourWeekFrwdDemandShippingUnits
      eightWeekHstrSelnShippingUnits

      fourWeekFrwdDemandVendorUnits
      eightWeekHstrSelnVendorUnits

      errors { errorCode errorDescription }
    }
  }`;
  const url = `${baseUrl}sdiminventory/cross-network-health`;

  return axios.post(
    isLocalhost ? "/api/middleware" : url,
    isLocalhost ? { raw, url } : raw,
    { headers }
  );
};

export const getStoreKPIDsdData = (filters: IFilter): Promise<any> => {
  const isLocalhost = checkIsLocalhost();

  const headers = {
    ...(isLocalhost
      ? {}
      : {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
          "Content-Type": "text/plain",
        }),
  };

  const raw = `{
    getStoreKPIDsdValues(ImpactInput: {DSDVendor: ${filters.dsdVendor}, CIC: "${filters.cic}", RetailDivision: ${filters.retailDivision}, RetailStore: ${filters.retailStore}, Department: ${filters.department}, RetailSection: ${filters.retailSection}, SMIC: ${filters.smic}, UPC: ${filters.upc}}, FarDataRequired: true)
    {
      inTransitToStoreSellingUnits {unitCount daysOfSOH itemTimeSupply}
      storeInventorySellingUnits {unitCount daysOfSOH itemTimeSupply}

      inTransitToStoreShippingUnits {unitCount daysOfSOH itemTimeSupply}
      storeInventoryShippingUnits {unitCount daysOfSOH itemTimeSupply}

      inTransitToStoreVendorUnits {unitCount daysOfSOH itemTimeSupply}
      storeInventoryVendorUnits {unitCount daysOfSOH itemTimeSupply}

      fourWeekFrwdDemandSellingUnits
      eightWeekHstrSelnSellingUnits

      fourWeekFrwdDemandShippingUnits
      eightWeekHstrSelnShippingUnits

      fourWeekFrwdDemandVendorUnits
      eightWeekHstrSelnVendorUnits

	    errors { errorCode errorDescription }
    }
  }`;
  const url = `${baseUrl}sdiminventory/cross-network-health`;

  return axios.post(
    isLocalhost ? "/api/middleware" : url,
    isLocalhost ? { raw, url } : raw,
    { headers }
  );
};

export const getFilters = (
  type: string,
  query: string,
  category: CATEGORY,
  filters: IFilter
) =>
  axios.get(
    `/api/filters?type=${type}&query=${query}&category=${category}&filters=${JSON.stringify(
      filters
    )}`
  );

/* istanbul ignore next */
export const getCICAndUPCDetails = (
  data: string,
  type: BarcodeType,
  category: CATEGORY
) => {
  if (type === "cic") {
    return axios.get(`/api/upc-details?cic=${data}&category=${category}`);
  }
  if (type === "upc") {
    return axios.get(`/api/upc-details?upc=${data}&category=${category}`);
  }
};

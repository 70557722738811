import { Column } from "@albertsons/uds/molecule/Table/Table.types";
import clsx from "clsx";
import SkeletonLoader from "@albertsons/uds/molecule/SkeletonLoader";
import { transformQuantity } from "../../utils/formatNumber";
import { unitTabs } from "../../constants/units";
import {
  IData,
  IUnits,
  IUnitsData,
  IUnitsTotalData,
} from "../../types/data.types";
import { ICard, InvoiceReceiptsResearchCards } from "../../types/cards.types";

export const CARDS: ICard<InvoiceReceiptsResearchCards>[] = [
  {
    key: "lastInvoiceRecDate",
    type: "date",
    label: "Last Invoice",
    label2: "Received Date",
  },
  {
    key: "invoiceNbr",
    label: "Invoice #",
  },
  {
    key: "vendorIdName",
    label: "Vendor ID",
    label2: "& Name",
  },
  {
    key: "invoiceTypeCd",
    label: "Invoice Type",
    label2: "Code",
  },
  {
    key: "uom",
    label: "UOM",
  },
  {
    key: "finalInvoiceQty",
    label: "Final Invoice",
    label2: "QTY",
  },
  {
    key: "finalPackQty",
    label: "Final Pack",
    label2: "QTY",
  },
  {
    key: "receivedUnitsEach",
    label: "Received Units",
    label2: "in Eaches",
  },
  {
    key: "nextReceiptEstimatedDate",
    type: "date",
    label: "Next Receipt",
    label2: "Estimated Date",
    forNotReceivedInvoice: true,
  },
];

export const getTotal = (
  row: IData<number>,
  data?: {
    totalWarehouseData?: IUnitsTotalData;
    totalStoreData?: IUnitsTotalData;
    column: "its" | "doh";
    tab: 0 | 1 | 2;
  }
) => {
  if (
    row.onOrder === undefined ||
    row.inTransitToStore === undefined ||
    row.storeInventory === undefined
  ) {
    return undefined;
  }

  const total = row.onOrder + row.inTransitToStore + row.storeInventory;

  if (data && data.totalWarehouseData && data.totalStoreData) {
    const key = unitTabs[data.tab].label.replace(" ", "");
    if (data.column === "its") {
      const demand =
        ((data.totalWarehouseData[
          `fourWeekFrwdDemand${key}` as keyof IUnitsTotalData
        ] || 0) +
          (data.totalStoreData[
            `fourWeekFrwdDemand${key}` as keyof IUnitsTotalData
          ] || 0)) /
        2;
      return total / demand;
    } else {
      const demand =
        ((data.totalWarehouseData[
          `eightWeekHstrSeln${key}` as keyof IUnitsTotalData
        ] || 0) +
          (data.totalStoreData[
            `eightWeekHstrSeln${key}` as keyof IUnitsTotalData
          ] || 0)) /
        2;
      return total / demand;
    }
  }
  return total;
};

export const getValues = (row: IUnitsData, fieldName: keyof IUnits) => {
  const response: IData<number> = {};
  if (!row) {
    return response;
  }
  Object.entries(row).forEach(([key, value]) => {
    if (value && value[fieldName] !== undefined) {
      response[key] = value[fieldName];
    }
  });
  return response;
};

export const getDesktopTableColumns = (): Column<IData<number>>[] => [
  {
    id: "label",
    label: "",
    value: (row: IData<number>) => (
      <div
        className="px-3 text-sm text-white select-none truncate text-center"
        title={`${row.label}`}
      >
        {row.label}
      </div>
    ),
  },
  {
    id: "onOrder",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate text-center"
        title="DSD Order"
      >
        DSD Order
      </div>
    ),
    value: (row: IData<number>) => {
      if ("onOrder" in row || row.isLoading === 0) {
        return (
          <span className="w-full text-base px-3 flex items-center justify-center">
            {row.onOrder !== undefined
              ? transformQuantity(row.onOrder, 1)
              : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
  {
    id: "inTransitToStore",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate text-center"
        title="In Transit to Store"
      >
        In Transit to Store
      </div>
    ),
    value: (row: IData<number>) => {
      if ("inTransitToStore" in row || row.isLoading === 0) {
        return (
          <span className="w-full text-base px-3 flex items-center justify-center">
            {row.inTransitToStore !== undefined
              ? transformQuantity(row.inTransitToStore, 1)
              : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
  {
    id: "storeInventory",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate text-center"
        title="Store Inventory"
      >
        Store Inventory
      </div>
    ),
    value: (row: IData<number>) => {
      if ("storeInventory" in row || row.isLoading === 0) {
        return (
          <span className="w-full text-base px-3 flex items-center justify-center">
            {row.storeInventory !== undefined
              ? transformQuantity(row.storeInventory, 1)
              : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
  {
    id: "total",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate text-center"
        title="Total"
      >
        Total
      </div>
    ),
    value: (row: IData<number>) => {
      if (row.isLoading === 0) {
        return (
          <span className="w-full text-base font-semibold px-3 flex items-center justify-center">
            {row.total !== undefined ? transformQuantity(row.total, 1) : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
];

export const getMobileTableColumns = (
  label: string,
  isExtraSmall: boolean,
  loading: number
): Column<IData>[] => [
  {
    id: "label",
    label: "",
    value: (row: IData) => (
      <span
        className={clsx(
          "w-full px-2 text-white flex items-center justify-center",
          isExtraSmall ? "text-sm" : "text-base",
          row.className ?? ""
        )}
      >
        {row.label}
      </span>
    ),
  },
  {
    id: "units",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate flex items-center justify-center"
        title={label}
      >
        {label}
      </div>
    ),
    value: (row: IData) => {
      if (row.units || loading === 0) {
        return (
          <span
            className={clsx(
              "px-2 truncate flex items-center justify-center",
              isExtraSmall ? "text-sm" : "text-base",
              row.className ?? ""
            )}
          >
            {row.units !== undefined ? transformQuantity(row.units, 1) : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
  {
    id: "doh",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate flex items-center justify-center"
        title="DOH"
      >
        DOH
      </div>
    ),
    value: (row: IData) => {
      if (row.doh || loading === 0) {
        return (
          <span
            className={clsx(
              "px-2 truncate flex items-center justify-center",
              isExtraSmall ? "text-sm" : "text-base",
              row.className ?? ""
            )}
          >
            {row.doh !== undefined ? transformQuantity(row.doh, 1) : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
  {
    id: "its",
    label: (
      <div
        className="px-3 text-sm text-white font-normal select-none truncate flex items-center justify-center"
        title="ITS"
      >
        ITS
      </div>
    ),
    value: (row: IData) => {
      if (row.its || loading === 0) {
        return (
          <span
            className={clsx(
              "px-2 truncate flex items-center justify-center",
              isExtraSmall ? "text-sm" : "text-base",
              row.className ?? ""
            )}
          >
            {row.its !== undefined ? transformQuantity(row.its, 1) : "NA"}
          </span>
        );
      }
      return (
        <span className="block px-2">
          <SkeletonLoader skeletonTheme baseColor="#dadce2" />
        </span>
      );
    },
  },
];

import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Divider from "@albertsons/uds/molecule/Divider";
import Tooltip from "@albertsons/uds/molecule/Tooltip";
import CategoryToggle from "../../CategoryToggle";
import { impactLogo, inventoryLogo } from "../../../assets";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { formatDateTime } from "../../../utils/date-time-format";
import { IDateTime } from "../../../types/dateTime.types";
import "./styles.css";

const appVersion = process.env.REACT_APP_VERSION || "1.0";

interface ReportHeaderProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  reportName?: string;
  showMviewLabel?: boolean;
  showDateTimeFetched?: boolean;
  showAboutLink?: boolean;
  sectionLabel?: string;
  showCategoryToggle?: boolean;
}

const ReportHeader: FC<ReportHeaderProps> = (props) => {
  const {
    forceScreen,
    reportName,
    showMviewLabel,
    showDateTimeFetched,
    showAboutLink,
    sectionLabel,
    showCategoryToggle,
  } = props;

  const [dateAndTime, setDateAndTime] = useState<IDateTime>();

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  useEffect(() => {
    const formattedDataAndTime = formatDateTime(undefined, "MST");
    setDateAndTime(formattedDataAndTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (screen.large) {
    return (
      <div className="bg-[#223962] flex grid items-start px-2 items-strech">
        <div className="flex w-full">
          <div className="w-auto">
            <img
              src={inventoryLogo}
              alt="albertsons-logo"
              className="h-[40px] mt-5 mb-1 mr-2"
            />
          </div>
          <div className="flex items-center py-2 w-auto">
            <img alt="impact-logo" src={impactLogo} className="h-[40px]" />
          </div>
          {showMviewLabel && (
            <div className="text-white text-center font-bold flex items-center text-sm pb-5 w-auto">
              mVIEW
            </div>
          )}
          <div className="text-white text-center font-bold flex items-center justify-center pb-4 text-lg flex-grow">
            {reportName}
          </div>
          <div className="flex items-center">
            {showDateTimeFetched && (
              <div className="flex flex-col items-center text-[#b7bcc7] text-xs mr-1">
                <span>Date Last refreshed</span>
                <span className="font-bold">{`${dateAndTime?.date} ${dateAndTime?.time} (Mountain Time)`}</span>
              </div>
            )}
            {showAboutLink && showDateTimeFetched && (
              <Divider className="my-3 text-xs" height={43} color="#b7bcc7" />
            )}
            {showAboutLink && (
              <div className="flex flex-col items-center ml-3 mr-1">
                <Tooltip zIndex={20} anchor="left">
                  <Tooltip.Popover>
                    <span className="text-md m-2">
                      Click here to follow link
                    </span>
                  </Tooltip.Popover>
                  <Link
                    to="/about"
                    className="text-[#b7bcc7] text-xs"
                    data-testid="about-link-button"
                  >
                    About
                  </Link>
                </Tooltip>
                <span className="text-[#b7bcc7] text-xs">{`Version ${appVersion}`}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "grid grid-cols-2 mx-1 mt-1",
        (reportName || sectionLabel) && "grid-rows-2"
      )}
    >
      <div className="px-2 h-16 bg-[#203763]">
        <div className="inventory-logo mt-[6px]"></div>
      </div>
      <div>
        <div
          className={clsx(
            "text-[#004181] text-center font-bold text-2xl",
            showMviewLabel ? "" : "invisible"
          )}
        >
          mVIEW
        </div>
        <div className="ml-1 bg-[#203763]">
          <div className="impact-logo"></div>
        </div>
      </div>
      {showCategoryToggle && (
        <div className="col-span-2 py-2.5">
          <CategoryToggle />
        </div>
      )}
      {(reportName || sectionLabel) && (
        <div className="col-span-2 p-1 font-semibold">
          {reportName && <p className="text-[#004181]">{reportName}</p>}
          {sectionLabel && <p className="text-[#007fb6]">{sectionLabel}</p>}
        </div>
      )}
    </div>
  );
};

export default ReportHeader;

import { FC } from "react";
import { Link } from "react-router-dom";
import { CircleArrowLeft } from "lucide-react";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";

interface ReportToolbarProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  reportName: string;
}

const ReportToolbar: FC<ReportToolbarProps> = (props) => {
  const { forceScreen, reportName } = props;

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  return (
    <div className="flex items-center w-auto justify-between h-15 bg-[#004B8B] text-white">
      <div className="flex h-12 relative">
        <Link to="/" className="flex items-center gap-2 p-3 hover:bg-[#013c77]">
          <CircleArrowLeft size={16} />
          Back to Home
        </Link>
      </div>
      {!screen.isMobile ? (
        <div
          className="flex items-center px-3 text-white font-bold text-lg h-12"
          data-testid="report-title"
        >
          {reportName}
        </div>
      ) : null}
    </div>
  );
};

export default ReportToolbar;

import { AnyAction } from "redux";
import { IFilterOptions } from "../../types/filters.types";
import { DCResearchCards } from "../../types/cards.types";

export const GET_DC_RESEARCH_DATA = "GET_DC_RESEARCH_DATA";
export const GET_DC_RESEARCH_DATA_SUCCESS = "GET_DC_RESEARCH_DATA_SUCCESS";
export const GET_DC_RESEARCH_DATA_ERROR = "GET_DC_RESEARCH_DATA_ERROR";

export interface IDCResearchReducer {
  isLoadingDcResearchCards: number;
  dcResearchData: DCResearchCards | null;
}

export const fetchDcResearchData = (filters: IFilterOptions) => ({
  type: GET_DC_RESEARCH_DATA,
  value: {
    filters,
  },
});

export const fetchDcResearchDataSuccess = (
  value: Partial<DCResearchCards>
) => ({
  type: GET_DC_RESEARCH_DATA_SUCCESS,
  value,
});

export const fetchDcResearchDataError = () => ({
  type: GET_DC_RESEARCH_DATA_ERROR,
});

export const initialState: IDCResearchReducer = {
  isLoadingDcResearchCards: 0,
  dcResearchData: null,
};

export const dcResearchReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_DC_RESEARCH_DATA:
      return {
        ...state,
        isLoadingDcResearchCards: 4,
        dcResearchData: null,
      };
    case GET_DC_RESEARCH_DATA_SUCCESS:
      return {
        ...state,
        isLoadingDcResearchCards: state.isLoadingDcResearchCards - 1,
        dcResearchData: {
          ...state.dcResearchData,
          ...action.value,
        },
      };
    case GET_DC_RESEARCH_DATA_ERROR:
      return {
        ...state,
        isLoadingDcResearchCards: state.isLoadingDcResearchCards - 1,
      };
    default:
      return state;
  }
};

export default dcResearchReducer;

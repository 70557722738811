import { AnyAction } from "redux";
import axios from "../../lib/axios";

export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const GET_ACCESS_TOKEN_SUCCESS = "GET_ACCESS_TOKEN_SUCCESS";

export interface IAuthReducer {
  accessToken: string | null;
}

export const getAccessToken = () => ({
  type: GET_ACCESS_TOKEN,
});

export const gotAccessToken = (value: any) => ({
  type: GET_ACCESS_TOKEN_SUCCESS,
  value,
});

export const initialState: IAuthReducer = {
  accessToken: null,
};

export const authReducer = (state = initialState, action: AnyAction) => {
  if (action.type === GET_ACCESS_TOKEN_SUCCESS) {
    const { access_token } = action.value;
    sessionStorage.setItem("access_token", access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    return {
      ...state,
      accessToken: access_token,
    };
  }
  return state;
};

export default authReducer;

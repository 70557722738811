import { FC, ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import { AppDispatch } from "../../../store";
import clsx from "clsx";
import { X as CloseIcon, ChevronLeft, ListFilter } from "lucide-react";
import {
  blockBodyScroll,
  releaseBodyScroll,
} from "../../../utils/blockScrolling";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { filterAccordianOpenAction } from "../../../store/modules/dashboard";
import DesktopToolbar from "../DesktopToolbar";

interface ExpandTablePopupProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  header?: string;
  handleClose: () => void;
  children: ReactNode;
  filterAccordianAction: (action: boolean) => void;
}

const ExpandTablePopup: FC<ExpandTablePopupProps> = (props) => {
  const {
    forceScreen,
    header = "",
    handleClose,
    children,
    filterAccordianAction,
  } = props;

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const closeExpandModal = () => {
    releaseBodyScroll();
    handleClose();
  };

  const openFilter = () => {
    filterAccordianAction(true);
  };

  useEffect(() => {
    blockBodyScroll();
  }, []);

  return (
    <div className="w-full h-full overflow-hidden" data-testid="expand-modal">
      <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-10 outline-none focus:outline-none w-full custom-bg h-full">
        <div className="relative w-full h-full">
          {/* modal content */}
          <div
            className={clsx(
              "border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full",
              screen.large && "rounded-none"
            )}
          >
            {/* Modal header */}
            {screen.large ? (
              <>
                <DesktopToolbar />
                <div className="flex justify-between items-center relative">
                  <div
                    className="flex mt-9 mb-6 ml-6 cursor-pointer"
                    onClick={closeExpandModal}
                    data-testid="back-to-report-button"
                  >
                    <ChevronLeft className="mr-3" />
                    Back to report
                  </div>
                  <button
                    className="flex flex-col items-center justify-center mr-5 py-3.5"
                    data-testid="expand-open-filter-button"
                    onClick={openFilter}
                  >
                    <ListFilter />
                    <span>Filter</span>
                  </button>
                </div>
              </>
            ) : (
              <div
                className={clsx("flex h-12 p-2", header && "bg-neutral-100")}
                data-testid="expand-header-element"
              >
                <div className="flex text-center flex items-center justify-center font-bold w-full">
                  {header}
                </div>
                <span
                  className="ml-auto flex text-center flex items-center justify-center"
                  onClick={closeExpandModal}
                >
                  <CloseIcon
                    data-testid="close-button"
                    className="cursor-pointer"
                  />
                </span>
              </div>
            )}
            {/* modal body */}
            <div className={clsx("relative", screen.small ? "px-2" : "px-5")}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  filterAccordianAction: (value: boolean) =>
    dispatch(filterAccordianOpenAction(value)),
});

export default connect(null, mapDispatchToProps)(ExpandTablePopup);

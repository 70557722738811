import { FC, JSX } from "react";
import Backdrop from "@albertsons/uds/molecule/Backdrop";

interface CustomBackdropProps {
  enabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
}

const CustomBackdrop: FC<CustomBackdropProps> = ({
  enabled,
  isOpen,
  onClose,
  children,
}) => {
  if (enabled) {
    return (
      <Backdrop isOpen={isOpen} onClose={onClose}>
        {children}
      </Backdrop>
    );
  }

  return children;
};

export default CustomBackdrop;

import { formatToTimeZone } from "date-fns-timezone";

export const formatDateTime = (
  date?: string | number,
  timeZone = "UTC",
  format?: { date?: string; time?: string }
) => {
  const value = date ? new Date(date) : new Date();
  const datePattern = format?.date || "MM/DD/YYYY";
  const timePattern = format?.time || "H:mm:ss A";

  return {
    date: formatToTimeZone(value, datePattern, { timeZone }),
    time: formatToTimeZone(value, timePattern, { timeZone }),
  };
};

export const addDays = (date: Date, days: number) => {
  const copiedDate = new Date(date);
  const dateOffset = 24 * 60 * 60 * 1000 * days;
  copiedDate.setTime(copiedDate.getTime() + dateOffset);
  return copiedDate;
};

export const getDateDiff = (date1?: Date, date2?: Date) => {
  if (!date1 || !date2) {
    return 0;
  }
  return Math.ceil(
    Math.abs(date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
  );
};

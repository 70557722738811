import { FC, useState, useMemo } from "react";
import { ChevronRight, ChevronUp } from "lucide-react";
import Input from "@albertsons/uds/molecule/Input";
import Select, { Option } from "@albertsons/uds/molecule/Select";
import Radio from "@albertsons/uds/molecule/Radio";
import { orderQuantitySelectOptions } from "../../../constants/constants";
import Chip from "../../Chip";
import { formatDateTime } from "../../../utils/date-time-format";

interface IRenderFilterSectionProps<T> {
  title: string;
  type?: "date";
  data: string[];
  selectedArray: string[];
  setSelectedArray: (value: string[]) => void;
  filterType: T;
  isExpanded: boolean;
  changeExpandedFilterIdx: (idx: T) => void;
  filterTableData: () => void;
}

interface IQuantitySectionFilterProps<T> {
  title: string;
  handleFirstQuantitySelectChange: (data: any) => void;
  handleSecondQuantitySelectChange: (data: any) => void;
  inputValueFirst?: number;
  setInputValueFirst: (value?: number) => void;
  inputValueSecond?: number;
  setInputValueSecond: (value?: number) => void;
  radioValue: string;
  setRadioValue: (value: string) => void;
  filterType: T;
  quantityObjectFirst: any;
  quantityObjectSecond: any;
  isExpanded: boolean;
  changeExpandedFilterIdx: (idx: T) => void;
  filterTableData: () => void;
}

export const RenderFilterSection = <T,>(
  props: IRenderFilterSectionProps<T>
) => {
  const {
    title,
    type,
    data,
    isExpanded,
    changeExpandedFilterIdx,
    selectedArray,
    setSelectedArray,
    filterType,
    filterTableData,
  } = props;

  const [searchValue, setSearchValue] = useState("");

  const filteredLabels = useMemo(() => {
    if (type === "date") {
      return data.filter(
        (value) =>
          value &&
          formatDateTime(value).date.includes(searchValue.toLowerCase())
      );
    }
    return data.filter(
      (value) =>
        value && value.toLowerCase().includes(searchValue.toLowerCase())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchValue]);

  const isAllSelected = !filteredLabels.some(
    (label) => !selectedArray.includes(label)
  );

  const toggleSelection = (label: string) => {
    if (selectedArray.some((value) => value === label)) {
      setSelectedArray(selectedArray.filter((value) => value !== label));
    } else {
      setSelectedArray([...selectedArray, label]);
    }
  };

  const selectAllLabels = () => {
    if (isAllSelected) {
      setSelectedArray([]);
    } else {
      setSelectedArray(filteredLabels);
    }
  };

  return (
    <>
      <div className="mb-4">
        <div className="p-3 border border-gray-300 rounded">
          <div
            className="flex items-center w-full p-2 cursor-pointer"
            onClick={() => changeExpandedFilterIdx(filterType)}
            data-testid={`${filterType}-section`}
          >
            <div className="font-bold">{title}</div>
            <span className="ml-auto">
              {isExpanded ? <ChevronUp /> : <ChevronRight />}
            </span>
          </div>
          {isExpanded ? (
            <div className="rounded w-full expanded">
              <div>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="p-2 rounded w-full"
                  data-testid={`${filterType}-search`}
                />
              </div>
              <br />
              {!searchValue && filteredLabels.length > 1 && (
                <span className="ml-1">
                  <Chip
                    label="Select all"
                    isSelected={isAllSelected}
                    onSelect={selectAllLabels}
                    role="select-all-chip"
                  />
                </span>
              )}
              {filteredLabels.map((label) => (
                <span className="ml-1" key={label}>
                  <Chip
                    label={type === "date" ? formatDateTime(label).date : label}
                    isSelected={selectedArray.some(
                      (selectedLabel) => selectedLabel === label
                    )}
                    onSelect={() => toggleSelection(label)}
                    role={`${filterType}-chip`}
                  />
                </span>
              ))}
            </div>
          ) : (
            <div className="ml-2">
              {selectedArray.length > 0 ? (
                <div>{`${selectedArray.length} Selected`}</div>
              ) : (
                <div>{`is (All)`}</div>
              )}
            </div>
          )}
        </div>
      </div>
      {isExpanded && <RenderApplyAllButton filterTableData={filterTableData} />}
    </>
  );
};

export const QuantityFilterSection = <T,>(
  props: IQuantitySectionFilterProps<T>
) => {
  const {
    title,
    isExpanded,
    changeExpandedFilterIdx,
    handleFirstQuantitySelectChange,
    handleSecondQuantitySelectChange,
    inputValueFirst,
    setInputValueFirst,
    inputValueSecond,
    setInputValueSecond,
    radioValue,
    setRadioValue,
    filterType,
    quantityObjectFirst,
    quantityObjectSecond,
    filterTableData,
  } = props;
  let selectedDataLength = (
    quantityObjectFirst: any,
    quantityObjectSecond: any
  ) => {
    if (
      quantityObjectFirst.value !== undefined &&
      quantityObjectSecond.value !== undefined
    ) {
      return "2 Selected";
    } else if (
      quantityObjectFirst.value === undefined &&
      quantityObjectSecond.value === undefined
    ) {
      return "is (All)";
    } else if (
      (quantityObjectFirst.value !== undefined &&
        quantityObjectSecond.value === undefined) ||
      (quantityObjectFirst.value === undefined &&
        quantityObjectSecond.value !== undefined)
    ) {
      return "1 Selected";
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="mb-4">
        <div className="p-3 border border-gray-300 rounded">
          <div className="flex items-center">
            <div
              className="p-2 rounded w-full flex"
              onClick={() => changeExpandedFilterIdx(filterType)}
              data-testid={`${filterType}-section`}
            >
              <div className="font-bold">{title}</div>
              <span className="ml-auto">
                {isExpanded ? <ChevronUp /> : <ChevronRight />}
              </span>
            </div>
          </div>
          {isExpanded ? (
            <div className="p-2 rounded w-full expanded">
              <div className="mb-3">Select items when the value</div>
              <Select
                className="w-full mt-[2px] border border-[#e5e7eb] text-sm mb-2"
                items={orderQuantitySelectOptions}
                itemText="label"
                size="md"
                noTags
                id={`${filterType}Select`}
                value={quantityObjectFirst}
                onChange={(item) => handleFirstQuantitySelectChange(item)}
              >
                {orderQuantitySelectOptions.map((item: any, idx: number) => (
                  <Option key={idx} item={item} />
                ))}
              </Select>
              {quantityObjectFirst.value !== undefined && (
                <div>
                  <Input
                    type="number"
                    placeholder="Type here"
                    value={inputValueFirst || ""}
                    onKeyDown={(e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      const parsedValue = value ? Number(value) : undefined;
                      setInputValueFirst(parsedValue);
                    }}
                    className="py-2 rounded w-full"
                    data-testid={`${filterType}input`}
                  />
                </div>
              )}
              {radioValue && (
                <div>
                  <Radio.Group
                    value={radioValue}
                    horizontal
                    onChange={setRadioValue}
                  >
                    <Radio label="And" value="&&" />
                    <Radio label="Or" value="||" />
                  </Radio.Group>
                </div>
              )}

              <Select
                className="w-full mt-[2px] border border-[#e5e7eb] text-sm mb-2"
                items={orderQuantitySelectOptions}
                itemText="label"
                size="md"
                noTags
                id={`#${filterType}Select2`}
                value={quantityObjectSecond}
                onChange={(item) => handleSecondQuantitySelectChange(item)}
              >
                {orderQuantitySelectOptions.map((item: any, idx: number) => (
                  <Option key={idx} item={item} />
                ))}
              </Select>
              {quantityObjectSecond.value !== undefined && (
                <div>
                  <Input
                    type="number"
                    placeholder="Type here"
                    value={inputValueSecond || ""}
                    onKeyDown={(e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      const parsedValue = value ? Number(value) : undefined;
                      setInputValueSecond(parsedValue);
                    }}
                    className="py-2 rounded w-full"
                    data-testid={`${filterType}input2`}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="ml-2">
              {quantityObjectFirst?.value !== undefined ||
              quantityObjectSecond?.value !== undefined ? (
                <div>
                  {selectedDataLength(
                    quantityObjectFirst,
                    quantityObjectSecond
                  )}
                </div>
              ) : (
                <div>{`is (All)`}</div>
              )}
            </div>
          )}
        </div>
      </div>
      {isExpanded && <RenderApplyAllButton filterTableData={filterTableData} />}
    </>
  );
};

interface RenderApplyAllButtonProps {
  filterTableData: () => void;
}

export const RenderApplyAllButton: FC<RenderApplyAllButtonProps> = (props) => {
  const { filterTableData } = props;

  return (
    <div className="flex w-[95%] mx-4">
      <div
        className="ml-auto w-28 h-9 text-black border border-black rounded flex justify-center text-center items-center cursor-pointer"
        onClick={filterTableData}
        data-testid="apply-all"
      >
        Apply all
      </div>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-header hr {
  border-top-width: 1.5px;
  border-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/TabHeader/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".tab-header hr {\n  border-top-width: 1.5px;\n  border-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

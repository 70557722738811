// orderQuantity select options
export const orderQuantitySelectOptions: any = [
  { label: "-Select-", value: undefined },
  { label: "is less than", value: "<" },
  { label: "is less than or equal to", value: "<=" },
  { label: " is greater than", value: ">" },
  {
    label: "is greater than or equal to",
    value: ">=",
  },
  { label: "is", value: "===" },
  { label: "is not", value: "!==" },
  { label: "is blank", value: "" },
  { label: "is not blank", value: "!" },
];

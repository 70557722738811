import { FC, useRef, useEffect, useState } from "react";
import { X as CloseIcon, Users2, Check } from "lucide-react";
import copy from "clipboard-copy";
import Modal from "@albertsons/uds/molecule/Modal";

interface CopyPopupInterface {
  isOpen: boolean;
  closeHandler: () => void;
}

interface BeforeCopyProps {
  copyHandler: () => void;
}

const BeforeCopy: FC<BeforeCopyProps> = ({ copyHandler }) => (
  <>
    <p className="text-sx">mView</p>
    <div className="flex items-center w-full mt-6 mb-2">
      <div className="flex items-center justify-center min-w-[26px] w-[26px] h-[26px] rounded-full bg-red-100 border-red-900 border-[1px] mr-1.5">
        <Users2 color="brown" size={12} />
      </div>
      <p className="text-xs mr-4">
        People with existing access to the app can use the link
      </p>
      <button
        className="flex h-8 items-center justify-center px-2 py-2.5 bg-[#117865] text-white text-sm rounded-md"
        onClick={copyHandler}
        data-testid="copy-link-before-copy-button"
      >
        Copy
      </button>
    </div>
  </>
);

interface AfterCopyProps {
  copyHandler: () => void;
  url: string;
}

const AfterCopy: FC<AfterCopyProps> = ({ copyHandler, url }) => {
  const urlBoxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // if (urlBoxRef.current) {
    urlBoxRef?.current?.select();
    // }
  }, []);

  const redoCopy = () => {
    urlBoxRef?.current?.select();
    copyHandler();
  };

  return (
    <>
      <div className="flex items-center justify-center flex-col">
        <div className="flex items-center justify-center w-[40px] h-[40px] rounded-full bg-[#117865] copy-animation mb-3">
          <Check size={24} color="white" />
        </div>
        <p className="text-base">Link Copied</p>
      </div>
      <div className="flex items-center justify-center flex-row mt-4 mb-3">
        <input
          type="text"
          value={url}
          className="w-full h-8 border-2 border-black text-sm"
          ref={urlBoxRef}
          data-testid="toolbar-url-text-field"
          readOnly
        />
        <button
          className="flex h-8 items-center justify-center px-4 py-2.5 bg-white hover:bg-gray-300 rounded-md border text-sm border-black"
          onClick={redoCopy}
          data-testid="copy-link-after-copy-button"
        >
          Copy
        </button>
      </div>
      <hr />
      <div className="flex items-center w-full flex-row mt-5">
        <div className="flex items-center justify-center min-w-[26px] w-[26px] h-[26px] rounded-full bg-red-100 border-red-900 border-[1px] mr-1.5">
          <Users2 color="brown" size={12} />
        </div>
        <p className="text-xs">
          People with existing access to the app can use the link
        </p>
      </div>
    </>
  );
};

const CopyLinkModal: FC<CopyPopupInterface> = (props) => {
  const { isOpen, closeHandler } = props;

  const [linkCopied, setLinkCopied] = useState(false);

  const url = window.location.href;

  const copyHandler = async () => {
    await copy(url);
    setLinkCopied(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      minHeight={20}
      minWidth={320}
      maxWidth={320}
      className="bg-white overflow-hidden !rounded-lg"
    >
      {({ close }) => (
        <div data-testid="copy-modal" className="p-6">
          <div className="relative">
            {!linkCopied && (
              <div className="flex items-center w-full mb-4">
                <span className="text-xl font-semibold">
                  <p>Copy Link</p>
                </span>
              </div>
            )}
            <span
              className="absolute top-0 right-0 z-10 cursor-pointer"
              data-testid="copy-link-close-button"
              onClick={close}
            >
              <CloseIcon />
            </span>
          </div>
          <div className="relative">
            <input
              type="hidden"
              name="url"
              data-testid="toolbar-url-hidden-field"
              defaultValue={url}
            />
            {linkCopied ? (
              <AfterCopy copyHandler={copyHandler} url={url} />
            ) : (
              <BeforeCopy copyHandler={copyHandler} />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CopyLinkModal;

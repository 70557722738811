import { IOrderDsd } from "../../../types/order.types";
import { transformQuantity } from "../../../utils/formatNumber";
import { formatDateTime } from "../../../utils/date-time-format";
import { TableColumn } from "../../Table/CommonTable.types";

export const orderHistoryColumns: TableColumn<IOrderDsd>[] = [
  {
    id: "orderNumber",
    label: "Invoice # / Order #",
    value: (row) => row.orderNumber || "-",
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
  },
  {
    id: "orderQuantity",
    label: "Ordered Units",
    value: (row) => transformQuantity(row.orderQuantity || 0) || "",
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "right",
    type: "number",
  },
  {
    id: "statusTypeCd",
    label: "STATUS",
    value: (row) => {
      if (row.isLast) {
        return "";
      }
      return row.statusTypeCd || "-";
    },
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
  },
  {
    id: "scheduledDeliveryDate",
    label: "Estimated Arrival Date",
    value: (row) => {
      if (row.isLast) {
        return "";
      }
      return row.scheduledDeliveryDate
        ? formatDateTime(row.scheduledDeliveryDate).date
        : "-";
    },
    getClassName: (row) => (row.isLast ? "font-bold" : ""),
    align: "center",
    type: "date",
  },
];

import { FC, useEffect, useState } from "react";
import Divider from "@albertsons/uds/molecule/Divider";
import { inventoryLogo } from "../../../../assets";
import useWindowBreakpoints from "../../../../hooks/useWindowBreakpoints";
import { formatDateTime } from "../../../../utils/date-time-format";
import { IDateTime } from "../../../../types/dateTime.types";
import "./styles.css";

const appVersion = process.env.REACT_APP_VERSION || "1.0";

interface MonitoringHeaderProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
}

const MonitoringHeader: FC<MonitoringHeaderProps> = (props) => {
  const { forceScreen } = props;

  const [dateAndTime, setDateAndTime] = useState<IDateTime>();

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  useEffect(() => {
    const formattedDataAndTime = formatDateTime(undefined, "MST");
    setDateAndTime(formattedDataAndTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (screen.large) {
    return (
      <div className="bg-[#223962] flex grid items-start px-2 items-strech">
        <div className="flex w-full">
          <div className="w-auto">
            <img
              src={inventoryLogo}
              alt="albertsons-logo"
              className="h-[40px] mt-[22px] mr-2"
            />
          </div>
          <div className="flex items-center">
            <div className="flex flex-col items-center text-[#b7bcc7] text-xs mx-3.5">
              <span>Data Sources:</span>
              <span>mView Usage Metrics</span>
            </div>
            <Divider className="my-3 text-xs" color="#b7bcc7" />
          </div>
          <div className="flex flex-grow flex-col items-center justify-center text-center text-white py-1">
            <div className="font-bold pb-2 text-lg">mView - Usage Metrics</div>
            <div className="font-medium text-sm">Usage Summary</div>
          </div>
          <div className="flex items-center">
            <Divider className="my-3" color="#b7bcc7" />
            <div className="flex flex-col items-center text-[#b7bcc7] text-xs mx-3.5">
              <span>Date Last Refreshed</span>
              <span>{`${dateAndTime?.date} ${dateAndTime?.time}`}</span>
              <span>Mountain</span>
            </div>
            <Divider className="my-3 text-xs" color="#b7bcc7" />
            <span className="text-[#b7bcc7] text-xs mx-3.5">{`Version ${appVersion}`}</span>
            <Divider className="my-3" color="#b7bcc7" />
            <span
              className="text-[#b7bcc7] text-xs mx-3.5"
              data-testid="about-link-button"
            >
              About
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-rows-2 grid-cols-2 ml-1 mr-1 mt-1">
      <div className="px-2 h-16 bg-[#203763]">
        <div className="inventory-logo mt-[6px]"></div>
      </div>
      <div>
        <div className="text-[#004181] text-center font-bold text-2xl">
          mVIEW
        </div>
        <div className="ml-1 bg-[#203763]">
          <div className="impact-logo"></div>
        </div>
      </div>
      <div className="col-span-2 p-1 font-semibold">
        <p className="text-[#004181]">mView - Usage Metrics</p>
        <p className="text-[#007fb6]">Usage Summary</p>
      </div>
    </div>
  );
};

export default MonitoringHeader;

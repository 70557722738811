export const Actions = {
  TEXT: "text",
  PENCIL: "pencil",
  NONE: "none",
};

export const Colors = {
  RED: "red",
  GREEN: "green",
  YELLOW: "yellow",
  BLUE: "blue",
  BLACK: "black",
  WHITE: "white",
  GRAY: "gray",
};

export const Brush = {
  THIN: 2,
  MEDIUM: 4,
  THICK: 8,
};

export const Border = {
  BLACK: "3px solid black",
  GRAY: "3px solid gray",
  NONE: "none",
};

export const Background = {
  BLACK: "black",
  GRAY: "lightgray",
  NONE: "none",
};

import { FC, useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { IUsageDetail } from "../../../../types/monitor.types";
import { LoadingIndicator } from "../../../../components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface UsageTrendsProps {
  usageDetails: IUsageDetail[];
  startDate: Date;
  endDate: Date;
  isLoading?: boolean;
}

const UsageTrends: FC<UsageTrendsProps> = (props) => {
  const { usageDetails, startDate, endDate, isLoading = false } = props;

  const [usageTrendData, setUsageTrendData] = useState<number[]>([]);
  const [usageTrendLabel, setUsageTrendLabel] = useState<string[]>([]);

  const formatDate = (date: any) => {
    const options = { month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  function formatDateToMMDDYYYY(date: Date) {
    const month = String(date?.getMonth() + 1).padStart(2, "0");
    const day = String(date?.getDate()).padStart(2, "0");
    const year = date?.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const calculateDailyUsageTrend = () => {
    const dateKeys: { [date: string]: number } = {};
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      const formattedDate = formatDate(currentDate);
      dateKeys[formattedDate] = 0;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    usageDetails.forEach((usage) => {
      const eventDate = new Date(usage.created_at);
      const formattedDate = formatDate(eventDate);
      if (dateKeys.hasOwnProperty(formattedDate)) {
        dateKeys[formattedDate]++;
      }
    });

    const label = Object.keys(dateKeys);
    const data = Object.values(dateKeys);
    setUsageTrendLabel(label);
    setUsageTrendData(data);
  };

  useEffect(() => {
    calculateDailyUsageTrend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageDetails, startDate, endDate]);

  const options = {
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `Usage Trend from ${formatDateToMMDDYYYY(
          startDate
        )} to ${formatDateToMMDDYYYY(endDate)}`,
        color: "#000",
        font: {
          size: 20,
        },
      },
    },
  };
  const data = {
    labels: usageTrendLabel,
    datasets: [
      {
        data: usageTrendData,
        borderColor: "#6AB9A0",
        backgroundColor: "#6AB9A0",
      },
    ],
  };

  return (
    <>
      {isLoading ? (
        <div className="relative" data-testid="loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <Line options={options} data={data} />
      )}
    </>
  );
};

export default UsageTrends;

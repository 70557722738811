import { FC } from "react";
// @ts-ignore
import PowerBI from "@albertsons/aci-powerbi";
import ReportToolbar from "../../components/Report/ReportToolbar";

const appEnv: "dev" | "qa" | "prod" =
  (process.env.env as "dev" | "qa" | "prod") || "dev";

const REPORTS = {
  impact: {
    reportId: {
      dev: "e75187c7-881e-4690-8143-62ac9dbc4a7b",
      qa: "e75187c7-881e-4690-8143-62ac9dbc4a7b",
      prod: "795a512d-f082-401d-a8ef-859ea1013c09",
    },
    name: "IMPACT Dashboard",
  },
  mview: {
    reportId: {
      prod: "9c32202f-91ef-4b86-a013-c8a8f10fbdc5",
    },
    name: "IMPACT mVIEW Dashboard",
  },
};

const APP_ID = {
  dev: "166b8269-8bc0-4feb-b981-4013abe21d91",
  qa: "166b8269-8bc0-4feb-b981-4013abe21d91",
  prod: "91452c88-55c3-4529-b0e2-60ce04216afa",
};
const CONFIG_TOKEN =
  "eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ";

interface ReportProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
}

const Report: FC<ReportProps> = (props) => {
  const { forceScreen } = props;

  const accessToken = localStorage.getItem("msal_access_token") ?? "";

  return (
    <>
      <ReportToolbar
        reportName={REPORTS.impact.name}
        forceScreen={forceScreen}
      />
      <div className="[&>div>iframe]:!h-[calc(100vh-48px)]">
        <PowerBI
          reportid={REPORTS.impact.reportId[appEnv]}
          embedurl={`https://app.powerbi.com/reportEmbed?reportId=${REPORTS.impact.reportId[appEnv]}&appId=${APP_ID[appEnv]}&config=${CONFIG_TOKEN}`}
          accesstoken={accessToken}
          powerBiType="report"
          type="report"
          filterPaneEnabled
          settings={[
            {
              name: "actionBar",
              value: "true",
            },
          ]}
        />
      </div>
    </>
  );
};

export default Report;

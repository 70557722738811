import { AnyAction } from "redux";
import { IOrderDsd } from "../../types/order.types";
import { IFilterOptions } from "../../types/filters.types";

export const GET_ORDER_HISTORY_DSD_DATA = "GET_ORDER_HISTORY_DSD_DATA";
export const GET_ORDER_HISTORY_DSD_DATA_SUCCESS =
  "GET_ORDER_HISTORY_DSD_DATA_SUCCESS";
export const ORDER_HISTORY_FILTERED_DATA = "ORDER_HISTORY_FILTERED_DATA";
export const GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY =
  "GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY";
export const GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY_SUCCESS =
  "GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY_SUCCESS";

export interface IDSDOrderHistoryReducer {
  isLoadedOrderHistoryData: boolean;
  orderHistoryData: IOrderDsd[];
  orderHistoryFilteredData: IOrderDsd[];
  totalOrderHistoryQuantity: number;
}

export const fetchOrderHistoryDsdData = (filters: IFilterOptions) => ({
  type: GET_ORDER_HISTORY_DSD_DATA,
  value: {
    filters,
  },
});

export const fetchOrderHistoryDsdDataSuccess = (data: IOrderDsd[]) => ({
  type: GET_ORDER_HISTORY_DSD_DATA_SUCCESS,
  value: {
    data,
  },
});

export const setOrderHistoryFilteredValues = (value: IOrderDsd[]) => ({
  type: ORDER_HISTORY_FILTERED_DATA,
  value,
});

export const fetchOrderHistoryDsdTotalQuantity = (filters: IFilterOptions) => ({
  type: GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY,
  value: {
    filters,
  },
});

export const fetchOrderHistoryDsdTotalQuantitySuccess = (
  orderHistoryTotalQty: number
) => ({
  type: GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY_SUCCESS,
  value: {
    orderHistoryTotalQty,
  },
});

export const initialState: IDSDOrderHistoryReducer = {
  isLoadedOrderHistoryData: false,
  orderHistoryData: [],
  orderHistoryFilteredData: [],
  totalOrderHistoryQuantity: 0,
};

export const orderHistoryReducer = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case GET_ORDER_HISTORY_DSD_DATA:
      return {
        ...state,
        isLoadedOrderHistoryData: false,
        orderHistoryData: [],
        orderHistoryFilteredData: [],
      };
    case GET_ORDER_HISTORY_DSD_DATA_SUCCESS:
      return {
        ...state,
        isLoadedOrderHistoryData: true,
        orderHistoryData: action.value.data,
        orderHistoryFilteredData: action.value.data,
      };
    case ORDER_HISTORY_FILTERED_DATA:
      return {
        ...state,
        orderHistoryFilteredData: action.value,
      };
    case GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY:
      return {
        ...state,
        totalOrderHistoryQuantity: 0,
      };
    case GET_ORDER_HISTORY_DSD_TOTAL_QUANTITY_SUCCESS:
      return {
        ...state,
        totalOrderHistoryQuantity: action.value.orderHistoryTotalQty,
      };
    default:
      return state;
  }
};

export default orderHistoryReducer;

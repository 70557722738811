import { AnyAction } from "redux";
import {
  IBarcodeUsage,
  IMonitorViewReport,
  IUniqueUsage,
  IUsageDetail,
} from "../../types/monitor.types";

export const GET_USAGE_DETAILS = "GET_USAGE_DETAILS";
export const GET_USAGE_DETAILS_SUCCESS = "GET_USAGE_DETAILS_SUCCESS";
export const GET_USAGE_DETAILS_ERROR = "GET_USAGE_DETAILS_ERROR";
export const GET_MONTHLY_USAGE_COUNT = "GET_MONTHLY_USAGE_COUNT";
export const GET_MONTHLY_USAGE_COUNT_SUCCESS =
  "GET_MONTHLY_USAGE_COUNT_SUCCESS";
export const GET_MONTHLY_USAGE_COUNT_ERROR = "GET_MONTHLY_USAGE_COUNT_ERROR";
export const GET_UNIQUE_USER_COUNT = "GET_UNIQUE_USER_COUNT";
export const GET_UNIQUE_USER_COUNT_SUCCESS = "GET_UNIQUE_USER_COUNT_SUCCESS";
export const GET_UNIQUE_USER_COUNT_ERROR = "GET_UNIQUE_USER_COUNT_ERROR";
export const MONITOR_VIEW_REPORT = "MONITOR_VIEW_REPORT";
export const MONITOR_BARCODE_USAGE = "MONITOR_BARCODE_USAGE";
export const GET_UNIQUE_SCAN_USER_COUNT = "GET_UNIQUE_SCAN_USER_COUNT";
export const GET_UNIQUE_SCAN_USER_COUNT_SUCCESS =
  "GET_UNIQUE_SCAN_USER_COUNT_SUCCESS";
export const GET_UNIQUE_SCAN_USER_COUNT_ERROR =
  "GET_UNIQUE_SCAN_USER_COUNT_ERROR";

export interface IMonitorAppReducer {
  isLoadingUsageDetails: boolean;
  usageDetails: IUsageDetail[];
  isLoadingMonthlyUsageCount: boolean;
  monthlyUsageCount: number;
  isLoadingUniqueUsers: boolean;
  uniqueUsers: IUniqueUsage[];
  isTrackedViewReport: boolean;
  uniqueScanUsers: IUniqueUsage[];
  isLoadingUniqueScanUsers: boolean;
}

export const fetchUsageDetails = (start: string, end: string) => ({
  type: GET_USAGE_DETAILS,
  value: {
    start,
    end,
  },
});

export const fetchUsageDetailsSuccess = (value: IUsageDetail[]) => ({
  type: GET_USAGE_DETAILS_SUCCESS,
  value,
});

export const fetchUsageDetailsError = () => ({
  type: GET_USAGE_DETAILS_ERROR,
});

export const fetchMonthlyUsageCount = (start: string, end: string) => ({
  type: GET_MONTHLY_USAGE_COUNT,
  value: {
    start,
    end,
  },
});

export const fetchMonthlyUsageCountSuccess = (value: number) => ({
  type: GET_MONTHLY_USAGE_COUNT_SUCCESS,
  value,
});

export const fetchMonthlyUsageCountError = () => ({
  type: GET_MONTHLY_USAGE_COUNT_ERROR,
});

export const fetchUniqueScanUserCount = (start: string, end: string) => ({
  type: GET_UNIQUE_SCAN_USER_COUNT,
  value: {
    start,
    end,
  },
});

export const fetchUniqueUserCountSuccess = (value: IUniqueUsage[]) => ({
  type: GET_UNIQUE_USER_COUNT_SUCCESS,
  value,
});

export const fetchUniqueUserCountError = () => ({
  type: GET_UNIQUE_USER_COUNT_ERROR,
});

export const fetchUniqueUserCount = (start: string, end: string) => ({
  type: GET_UNIQUE_USER_COUNT,
  value: {
    start,
    end,
  },
});

export const fetchUniqueScanUserCountSuccess = (value: IUniqueUsage[]) => ({
  type: GET_UNIQUE_SCAN_USER_COUNT_SUCCESS,
  value,
});

export const fetchUniqueScanUserCountError = () => ({
  type: GET_UNIQUE_SCAN_USER_COUNT_ERROR,
});

export const monitorViewReport = (data: IMonitorViewReport) => ({
  type: MONITOR_VIEW_REPORT,
  value: {
    data,
  },
});

export const monitorBarcodeScanUsage = (data: IBarcodeUsage) => ({
  type: MONITOR_BARCODE_USAGE,
  value: {
    data,
  },
});

export const initialState: IMonitorAppReducer = {
  isLoadingUsageDetails: true,
  usageDetails: [],
  isLoadingMonthlyUsageCount: true,
  monthlyUsageCount: 0,
  isLoadingUniqueUsers: true,
  uniqueUsers: [],
  isTrackedViewReport: false,
  uniqueScanUsers: [],
  isLoadingUniqueScanUsers: false,
};

export const monitorAppReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_USAGE_DETAILS:
      return {
        ...state,
        isLoadingUsageDetails: true,
      };
    case GET_USAGE_DETAILS_SUCCESS:
      return {
        ...state,
        usageDetails: action.value,
        isLoadingUsageDetails: false,
      };
    case GET_USAGE_DETAILS_ERROR:
      return {
        ...state,
        usageDetails: [],
        isLoadingUsageDetails: false,
      };
    case GET_MONTHLY_USAGE_COUNT:
      return {
        ...state,
        isLoadingMonthlyUsageCount: true,
      };
    case GET_MONTHLY_USAGE_COUNT_SUCCESS:
      return {
        ...state,
        monthlyUsageCount: action.value,
        isLoadingMonthlyUsageCount: false,
      };
    case GET_MONTHLY_USAGE_COUNT_ERROR:
      return {
        ...state,
        monthlyUsageCount: 0,
        isLoadingMonthlyUsageCount: false,
      };
    case GET_UNIQUE_USER_COUNT:
      return {
        ...state,
        isLoadingUniqueUsers: true,
      };
    case GET_UNIQUE_USER_COUNT_SUCCESS:
      return {
        ...state,
        uniqueUsers: action.value,
        isLoadingUniqueUsers: false,
      };
    case GET_UNIQUE_USER_COUNT_ERROR:
      return {
        ...state,
        uniqueUsers: [],
        isLoadingUniqueUsers: false,
      };
    case GET_UNIQUE_SCAN_USER_COUNT:
      return {
        ...state,
        isLoadingUniqueScanUsers: true,
      };
    case GET_UNIQUE_SCAN_USER_COUNT_SUCCESS:
      return {
        ...state,
        uniqueScanUsers: action.value,
        isLoadingUniqueScanUsers: false,
      };
    case GET_UNIQUE_SCAN_USER_COUNT_ERROR:
      return {
        ...state,
        uniqueScanUsers: [],
        isLoadingUniqueScanUsers: false,
      };
    case MONITOR_VIEW_REPORT:
      return {
        ...state,
        isTrackedViewReport: true,
      };
    default:
      return state;
  }
};

export default monitorAppReducer;

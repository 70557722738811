import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { rootSaga } from "./sagas";
import authReducer, { IAuthReducer } from "./modules/auth";
import dashboardReducer, { IDashboardReducer } from "./modules/dashboard";
import orderHistoryReducer, {
  IOrderHistoryReducer,
} from "./modules/orderHistory";
import dsdOrderHistoryReducer, {
  IDSDOrderHistoryReducer,
} from "./modules/dsdOrderHistory";
import dcResearchReducer, { IDCResearchReducer } from "./modules/dcResearch";
import invoiceReceiptsResearchReducer, {
  IInvoiceReceiptsResearchReducer,
} from "./modules/invoiceReceipts";
import monitorAppReducer, { IMonitorAppReducer } from "./modules/monitor";

export interface IReduxState {
  auth: IAuthReducer;
  dashboard: IDashboardReducer;
  orderHistory: IOrderHistoryReducer;
  dsdOrderHistory: IDSDOrderHistoryReducer;
  dcResearch: IDCResearchReducer;
  invoiceReceipts: IInvoiceReceiptsResearchReducer;
  monitor: IMonitorAppReducer;
}

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    orderHistory: orderHistoryReducer,
    dsdOrderHistory: dsdOrderHistoryReducer,
    dcResearch: dcResearchReducer,
    invoiceReceipts: invoiceReceiptsResearchReducer,
    monitor: monitorAppReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

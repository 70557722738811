import { FC, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import clsx from "clsx";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import Table from "@albertsons/uds/molecule/Table";
import {
  fetchUnitsData,
  initDashboardState,
} from "../../store/modules/dashboard";
import { fetchDcResearchData } from "../../store/modules/dcResearch";
import {
  fetchDcStoreData,
  fetchStoreOrderData,
  fetchOrderHistoryTotalQuantity,
  fetchBuyerCommentsData,
} from "../../store/modules/orderHistory";
import { monitorViewReport } from "../../store/modules/monitor";
import { monitorBarcodeScanUsage } from "../../store/modules/monitor";
import { TabHeader, ButtonGroup } from "../../components";
import useWindowBreakpoints from "../../hooks/useWindowBreakpoints";
import DesktopToolbar from "../../components/Report/DesktopToolbar";
import ReportHeader from "../../components/Report/ReportHeader";
import Filters from "../../components/Report/Filters";
import Cards from "../../components/Report/Cards";
import WarehouseOrderHistory from "../../components/Report/WarehouseOrderHistory";
import FloatingMenu from "../../components/Report/FloatingMenu";
import BarcodeScanner from "../../components/Report/Scanner";
import Annotate from "../../components/Report/Annotate";
import CategoryToggle from "../../components/CategoryToggle";
import {
  getTotal,
  getValues,
  getDesktopTableColumns,
  getMobileTableColumns,
  CARDS,
} from "./utils";
import { unitTabs } from "../../constants/units";
import { blockBodyScroll, releaseBodyScroll } from "../../utils/blockScrolling";
import { getBrowser, isMobile } from "../../utils/isMobile";
import { canMonitor } from "../../utils/app-env";
import { clipboardCheck } from "../../assets";
import { IMonitorViewReport, IBarcodeUsage } from "../../types/monitor.types";
import { DCResearchCards } from "../../types/cards.types";
import { IUnitsData, IUnitsTotalData } from "../../types/data.types";
import { IFilter, IFilterOptions } from "../../types/filters.types";
import { BarcodeValue } from "../../types/barcode.types";
import { CATEGORY } from "../../types/category.types";
import "./styles.css";

const currrentTime = performance.now();

interface WarehouseFARReportProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  canScanBarcode?: boolean;
  isInitialized: boolean;
  selectedFilters: IFilter;
  selectedFilterOptions: IFilterOptions;
  sellingUnitsData: IUnitsData;
  shippingUnitsData: IUnitsData;
  vendorUnitsData: IUnitsData;
  totalWarehouseData: IUnitsTotalData;
  totalStoreData: IUnitsTotalData;
  dcResearchData: DCResearchCards | null;
  selectedHistoryTab: number;
  refreshDataKey: number;
  isUnitsDataLoading: number;
  isLoadingDcResearchCards: number;
  isLoadedDcStoreData: boolean;
  isTrackedViewReport: boolean;
  initDashboardState: () => void;
  fetchUnitsData: (filters: IFilterOptions) => void;
  fetchDcStoreData: (filters: IFilterOptions) => void;
  fetchStoreOrderData: (filters: IFilterOptions) => void;
  fetchOrderHistoryTotalQuantity: (filters: IFilterOptions) => void;
  fetchBuyerCommentsData: (filters: IFilterOptions) => void;
  fetchDCResearchCards: (filters: IFilterOptions) => void;
  monitorViewReport: (data: IMonitorViewReport) => void;
  monitorBarcodeScanUsage: (data: IBarcodeUsage) => void;
}

const WarehouseFARReport: FC<WarehouseFARReportProps> = (props) => {
  const {
    forceScreen,
    canScanBarcode = true,
    isInitialized,
    selectedFilters,
    selectedFilterOptions,
    isUnitsDataLoading,
    sellingUnitsData,
    shippingUnitsData,
    vendorUnitsData,
    totalWarehouseData,
    totalStoreData,
    dcResearchData,
    selectedHistoryTab,
    refreshDataKey,
    isLoadingDcResearchCards,
    isLoadedDcStoreData,
    isTrackedViewReport,
    initDashboardState,
    fetchUnitsData,
    fetchDcStoreData,
    fetchStoreOrderData,
    fetchOrderHistoryTotalQuantity,
    fetchBuyerCommentsData,
    fetchDCResearchCards,
    monitorViewReport,
    monitorBarcodeScanUsage,
  } = props;

  const [selectedUnitTab, setSelectedUnitTab] = useState<0 | 1 | 2>(0);
  const [showAnnotateModal, setShowAnnotateModal] = useState(false);
  const [showBarcodeModal, setShowBarcodeMadal] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState<BarcodeValue | null>(null);
  const [isFar, setIsFar] = useState(false);

  const prevFiltersRef = useRef("");
  const prevPathnameRef = useRef("");
  const mainScreenContentRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const { accounts } = useMsal();
  const isActualMobile = isMobile();
  const browser = getBrowser();

  const isLoading =
    !isInitialized ||
    isUnitsDataLoading > 0 ||
    isLoadingDcResearchCards > 0 ||
    !isLoadedDcStoreData;

  const reportName = "Current Inventory Health Summary";

  const unitsData = useMemo(() => {
    switch (selectedUnitTab) {
      case 0:
        return sellingUnitsData;
      case 1:
        return shippingUnitsData;
      case 2:
        return vendorUnitsData;
    }
  }, [selectedUnitTab, sellingUnitsData, shippingUnitsData, vendorUnitsData]);

  const openAnnotateModal = () => {
    setShowAnnotateModal(true);
    blockBodyScroll();
  };

  const closeAnnotateModal = () => {
    setShowAnnotateModal(false);
    releaseBodyScroll();
  };

  const resetBarcodeValue = () => {
    setBarcodeValue(null);
  };

  const openBarcodeModal = () => {
    setShowBarcodeMadal(true);
    blockBodyScroll();
  };

  const closeBarcodeModal = () => {
    setShowBarcodeMadal(false);
    releaseBodyScroll();
  };

  const updateBarcodeValue = (barcode: BarcodeValue) => {
    const { name, username } = accounts[0];
    monitorBarcodeScanUsage({
      name: name ?? "",
      email: username,
      type: barcode.type,
      scannedvalue: barcode.data,
      deviceid: localStorage.getItem("scandit-device-id") ?? "",
      monitorid: sessionStorage.getItem("monitorId") ?? "",
    });
    setBarcodeValue(barcode);
    closeBarcodeModal();
  };

  const fetchOrderHistoryData = () => {
    switch (selectedHistoryTab) {
      case 0:
        fetchDcStoreData(selectedFilterOptions);
        fetchOrderHistoryTotalQuantity(selectedFilterOptions);
        break;
      case 1:
        fetchStoreOrderData(selectedFilterOptions);
        fetchOrderHistoryTotalQuantity(selectedFilterOptions);
        break;
      case 2:
        fetchBuyerCommentsData(selectedFilterOptions);
        break;
    }
  };

  const applyFilter = () => {
    fetchUnitsData(selectedFilterOptions);
    fetchOrderHistoryData();
    fetchDCResearchCards(selectedFilterOptions);
  };

  useEffect(() => {
    if (isInitialized) {
      const newFilters = JSON.stringify(selectedFilters);
      if (
        newFilters !== prevFiltersRef.current ||
        pathname !== prevPathnameRef.current
      ) {
        applyFilter();
        prevFiltersRef.current = newFilters;
        prevPathnameRef.current = pathname;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, selectedUnitTab, isInitialized, pathname]);

  useEffect(() => {
    if (canMonitor && !isTrackedViewReport && !isLoading) {
      const { name, username } = accounts[0];
      const loadingTime = performance.now() - currrentTime;
      monitorViewReport({
        name: name ?? "",
        email: username,
        loading_time: Math.round(loadingTime / 1000),
        platform: isActualMobile ? "Mobile" : "Desktop",
        browser,
        report: "IMPACT mView Dashboard",
        page: reportName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackedViewReport, isLoading]);

  useEffect(() => {
    if (refreshDataKey) {
      applyFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataKey]);

  useEffect(() => {
    if (isInitialized) {
      setIsFar(pathname === CATEGORY.WAREHOUSE_FAR);
    }
  }, [isInitialized, pathname]);

  useEffect(() => {
    initDashboardState();
    document.title = `${reportName} | IMPACT mVIEW Dashboard`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableRows = useCallback((): any => {
    if (screen.small) {
      return [
        {
          id: 0,
          label: "On Order",
          units: unitsData.onOrder?.unitCount,
          doh: unitsData.onOrder?.daysOfSOH,
          its: unitsData.onOrder?.itemTimeSupply,
        },
        {
          id: 1,
          label: "In Transit to DC",
          units: unitsData.inTransitToDC?.unitCount,
          doh: unitsData.inTransitToDC?.daysOfSOH,
          its: unitsData.inTransitToDC?.itemTimeSupply,
        },
        {
          id: 2,
          label: "DC Inventory",
          units: unitsData.dcInventory?.unitCount,
          doh: unitsData.dcInventory?.daysOfSOH,
          its: unitsData.dcInventory?.itemTimeSupply,
        },
        {
          id: 3,
          label: "In Transit to Store",
          units: unitsData.inTransitToStore?.unitCount,
          doh: unitsData.inTransitToStore?.daysOfSOH,
          its: unitsData.inTransitToStore?.itemTimeSupply,
        },
        {
          id: 4,
          label: "Store Inventory",
          units: isFar ? unitsData.storeInventory?.unitCount : undefined,
          doh: isFar ? unitsData.storeInventory?.daysOfSOH : undefined,
          its: isFar ? unitsData.storeInventory?.itemTimeSupply : undefined,
        },
        {
          id: 5,
          label: "Total",
          units: getTotal(getValues(unitsData, "unitCount", isFar), isFar),
          doh: getTotal(getValues(unitsData, "daysOfSOH", isFar), isFar, {
            totalWarehouseData,
            totalStoreData,
            column: "doh",
            tab: selectedUnitTab,
          }),
          its: getTotal(getValues(unitsData, "itemTimeSupply", isFar), isFar, {
            totalWarehouseData,
            totalStoreData,
            column: "its",
            tab: selectedUnitTab,
          }),
          className: "font-semibold",
        },
      ];
    }
    return [
      {
        id: 0,
        label: unitTabs[selectedUnitTab].label,
        ...getValues(unitsData, "unitCount", isFar),
        total: getTotal(getValues(unitsData, "unitCount", isFar), isFar),
        isLoading: isUnitsDataLoading,
      },
      {
        id: 1,
        label: "Day of Sales on Hand",
        ...getValues(unitsData, "daysOfSOH", isFar),
        total: getTotal(getValues(unitsData, "daysOfSOH", isFar), isFar, {
          totalWarehouseData,
          totalStoreData,
          column: "doh",
          tab: selectedUnitTab,
        }),
        isLoading: isUnitsDataLoading,
      },
      {
        id: 2,
        label: "Item Time Supply",
        ...getValues(unitsData, "itemTimeSupply", isFar),
        total: getTotal(getValues(unitsData, "itemTimeSupply", isFar), isFar, {
          totalWarehouseData,
          totalStoreData,
          column: "its",
          tab: selectedUnitTab,
        }),
        isLoading: isUnitsDataLoading,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUnitsDataLoading,
    unitsData,
    totalWarehouseData,
    totalStoreData,
    screen,
    selectedUnitTab,
    isFar,
  ]);

  const getTableColumns = useCallback(() => {
    if (screen.small)
      return getMobileTableColumns(
        unitTabs[selectedUnitTab].label,
        screen.extraSmall,
        isUnitsDataLoading
      );
    return getDesktopTableColumns();
  }, [isUnitsDataLoading, screen, selectedUnitTab]);

  return (
    <div className="relative">
      <div className="row-col-reverse" />
      <div ref={mainScreenContentRef}>
        {screen.large && <DesktopToolbar />}
        <div
          className={clsx(screen.small && !showAnnotateModal && "mb-[80px]")}
        >
          <ReportHeader
            forceScreen={forceScreen}
            reportName={reportName}
            showMviewLabel
            showDateTimeFetched
            showAboutLink
            showCategoryToggle
            sectionLabel="Cross Network Health"
          />
          <div className={clsx("flex", screen.small && "flex-col")}>
            <div className={clsx(screen.small && "px-2 pb-3")}>
              <Filters
                showWarehouseDivision
                showDistributionCenter
                showWarehouse
                forceScreen={forceScreen}
                barcodeValue={barcodeValue}
                resetBarcodeValue={resetBarcodeValue}
              />
            </div>
            <div className={clsx(screen.large ? "px-4" : "px-2 pb-6")}>
              {screen.large ? (
                <div className="flex pr-5">
                  <img
                    src={clipboardCheck}
                    alt="clipboard-icon"
                    className="mt-2.5 h-[28px]"
                  />
                  <span className="text-[#004181] font-bold text-lg flex items-center font-Arial pl-2">
                    {reportName}
                  </span>
                  <div className="ml-auto mt-1.5 pr-2">
                    <CategoryToggle />
                  </div>
                  <ButtonGroup
                    title="Unit Toggle:"
                    buttons={unitTabs}
                    value={selectedUnitTab}
                    setValue={(value) => setSelectedUnitTab(value as 0 | 1 | 2)}
                  />
                </div>
              ) : (
                <ButtonGroup
                  title="Unit Toggle:"
                  buttons={unitTabs}
                  value={selectedUnitTab}
                  setValue={(value) => setSelectedUnitTab(value as 0 | 1 | 2)}
                />
              )}
              {screen.large && <TabHeader title="Cross Network Health" />}
              <Table
                id="units-table"
                itemKey="id"
                items={getTableRows()}
                columns={getTableColumns()}
                noHeader
                noPagination
                dividers
                className={clsx(
                  "units-table",
                  screen.small ? "small-table" : "large-table"
                )}
              />
              {!isFar ? (
                <>
                  <p className="text-sm pt-2">
                    <span className="text-red-500">*</span>
                    No Store Inventory data available for non-FaR items at this
                    time
                  </p>
                  <p className="text-sm pt-2">
                    <span className="text-red-500">*</span>
                    ITS is available only for items that have DA forecast
                  </p>
                </>
              ) : null}
              <Cards<DCResearchCards>
                forceScreen={forceScreen}
                sectionLabel="DC Research"
                cards={CARDS}
                isLoading={isLoadingDcResearchCards > 0}
                researchData={dcResearchData}
              />
              <WarehouseOrderHistory />
            </div>
          </div>
        </div>
        {screen.small && (
          <FloatingMenu
            forceScreen={forceScreen}
            canScanBarcode={canScanBarcode}
            isAnnotating={showAnnotateModal}
            openAnnotateModal={openAnnotateModal}
            openBarcodeModal={openBarcodeModal}
          />
        )}
        {canScanBarcode && isInitialized && (
          <BarcodeScanner
            isOn={showBarcodeModal}
            updateBarcodeValue={updateBarcodeValue}
            closeBarcodeModal={closeBarcodeModal}
          />
        )}
      </div>
      <Annotate
        showModal={showAnnotateModal}
        onClose={closeAnnotateModal}
        contentRef={mainScreenContentRef}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isInitialized: state.dashboard.isStateInitialized,
  selectedFilters: state.dashboard.selectedFilters,
  selectedFilterOptions: state.dashboard.selectedFilterOptions,
  sellingUnitsData: state.dashboard.sellingUnitsData,
  shippingUnitsData: state.dashboard.shippingUnitsData,
  vendorUnitsData: state.dashboard.vendorUnitsData,
  totalWarehouseData: state.dashboard.totalWarehouseData,
  totalStoreData: state.dashboard.totalStoreData,
  selectedHistoryTab: state.orderHistory.selectedHistoryTab,
  refreshDataKey: state.dashboard.refreshDataKey,
  isUnitsDataLoading: state.dashboard.isUnitsDataLoading,
  isLoadingDcResearchCards: state.dcResearch.isLoadingDcResearchCards,
  dcResearchData: state.dcResearch.dcResearchData,
  isLoadedDcStoreData: state.orderHistory.isLoadedDcStoreData,
  isTrackedViewReport: state.monitor.isTrackedViewReport,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  initDashboardState: () => dispatch(initDashboardState()),
  fetchUnitsData: (filters: IFilterOptions) =>
    dispatch(fetchUnitsData(filters)),
  fetchDcStoreData: (filters: IFilterOptions) =>
    dispatch(fetchDcStoreData(filters)),
  fetchStoreOrderData: (filters: IFilterOptions) =>
    dispatch(fetchStoreOrderData(filters)),
  fetchOrderHistoryTotalQuantity: (filters: IFilterOptions) =>
    dispatch(fetchOrderHistoryTotalQuantity(filters)),
  fetchBuyerCommentsData: (filters: IFilterOptions) =>
    dispatch(fetchBuyerCommentsData(filters)),
  fetchDCResearchCards: (filters: IFilterOptions) =>
    dispatch(fetchDcResearchData(filters)),
  monitorViewReport: (data: IMonitorViewReport) =>
    dispatch(monitorViewReport(data)),
  monitorBarcodeScanUsage: (data: IBarcodeUsage) =>
    dispatch(monitorBarcodeScanUsage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseFARReport);

import { FC } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { IUniqueUsage } from "../../../../types/monitor.types";
import { LoadingIndicator } from "../../../../components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface TopUserProps {
  uniqueUsers: IUniqueUsage[];
  isLoading?: boolean;
}

const TopUser: FC<TopUserProps> = (props) => {
  const { uniqueUsers, isLoading = false } = props;

  const top10UsersList: IUniqueUsage[] = JSON.parse(
    JSON.stringify(uniqueUsers)
  );

  const top10UserSortedList: IUniqueUsage[] = top10UsersList
    .sort((a, b) => b.count - a.count)
    .slice(0, 10);

  const chartData = {
    labels: top10UserSortedList.map((item) => item.email),
    datasets: [
      {
        label: "Count",
        data: top10UserSortedList.map((item) => item.count),
        borderColor: "#6AB9A0",
        backgroundColor: "#6AB9A0",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    aspectRatio: 2.5,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `Top Users`,
        color: "#000",
        font: {
          size: 20,
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="relative" data-testid="loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <Bar options={options} data={chartData} data-testid="top-users" />
      )}
    </>
  );
};

export default TopUser;

import { call, put } from "redux-saga/effects";

import {
  fetchDcStoreData,
  fetchDcStoreDataSuccess,
  fetchStoreOrderData,
  fetchStoreOrderDataSuccess,
  fetchOrderHistoryTotalQuantity,
  fetchOrderHistoryTotalQuantitySuccess,
  fetchBuyerCommentsData,
  fetchBuyerCommentsDataSuccess,
  fetchDcStoreDataError,
  fetchStoreOrderDataError,
  fetchBuyerCommentsDataError,
} from "../modules/orderHistory";
import {
  getDCOrderHistoryData,
  getStoreOrderHistoryData,
  getOrderHistoryTotalQuantity,
  getBuyerCommentsData,
} from "../end-points/orderHistory";
import { transformFilterOptions } from "../../utils/formatFilterOptions";
import { ISuccessResponse } from "../../types/response.types";
import { IBuyerComments, IDCStore, IStoreOrder } from "../../types/order.types";
import { CATEGORY } from "../../types/category.types";

interface IDCStoreData {
  getOrderHistory: {
    dcOrderHistory: IDCStore[];
  };
}

interface IDCStoreTotalQuantity {
  getOrderHistoryTotal: {
    dcOrderTotalQty: number;
    storeOrderTotalQty: number;
  };
}

interface IStoreOrderData {
  getOrderHistory: {
    storeOrderHistory: IStoreOrder[];
  };
}

interface IBuyerCommentsData {
  getBuyerComments: IBuyerComments;
}

export function* getDcStoreDataSaga(
  action: ReturnType<typeof fetchDcStoreData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const response: ISuccessResponse<IDCStoreData> = yield call(
      getDCOrderHistoryData,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    const data = response.data.getOrderHistory?.dcOrderHistory || [];
    yield put(fetchDcStoreDataSuccess(data));
  } catch (error) {
    console.log("getDcStoreDataSaga Error:", error);
    yield put(fetchDcStoreDataError());
  }
}

export function* getStoreOrderDataSaga(
  action: ReturnType<typeof fetchStoreOrderData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const response: ISuccessResponse<IStoreOrderData> = yield call(
      getStoreOrderHistoryData,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    const data = response.data.getOrderHistory?.storeOrderHistory || [];
    yield put(fetchStoreOrderDataSuccess(data));
  } catch (error) {
    console.log("getStoreOrderDataSaga Error:", error);
    yield put(fetchStoreOrderDataError());
  }
}

export function* getOrderHistoryTotalQuantitySaga(
  action: ReturnType<typeof fetchOrderHistoryTotalQuantity>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const response: ISuccessResponse<IDCStoreTotalQuantity> = yield call(
      getOrderHistoryTotalQuantity,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    const dcOrderTotalQty =
      response.data.getOrderHistoryTotal?.dcOrderTotalQty || 0;
    const storeOrderTotalQty =
      response.data.getOrderHistoryTotal?.storeOrderTotalQty || 0;
    yield put(
      fetchOrderHistoryTotalQuantitySuccess(dcOrderTotalQty, storeOrderTotalQty)
    );
  } catch (error) {
    console.log("getDcStoreTotalQuantitySaga Error:", error);
  }
}

export function* getBuyerCommentsDataSaga(
  action: ReturnType<typeof fetchBuyerCommentsData>
) {
  const { filters } = action.value;
  const category = window.location.pathname;
  try {
    const response: ISuccessResponse<IBuyerCommentsData> = yield call(
      getBuyerCommentsData,
      transformFilterOptions(filters),
      category === CATEGORY.WAREHOUSE_FAR
    );
    yield put(fetchBuyerCommentsDataSuccess(response.data.getBuyerComments));
  } catch (error: any) {
    console.log("getBuyerCommentsDataSaga Error:", error);
    yield put(fetchBuyerCommentsDataError());
  }
}
